.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: 50;
  @include transitionRe;
  // color: $color-white;
  height: var(--size-hd);
  background-color: $color-white;
  border-bottom: .1rem solid $color-white;
  &.sticky {
    box-shadow: $shadow;

    @include min(1200px) {
      transform: translateY(calc(-1 * var(--hd-top)));
      .header-search-bar{
        top: calc(100vh - var(--size-hd));
        transform: translate(-50%, calc(-50vh - ( -1 * var(--size-hd)) ));
  
      }
      .header-search-close{
      top: calc(var(--hd-top) + 5rem);
      }
      .header-search-overlay{
      top: var(--hd-top);
      height: 100vh;
      }
    }
    
    .mobile-overlay,
    .mobile {}
  }

  &-top {
    height: var(--hd-top);
    background-color: $color-pri;

    @include max(1200px) {
      display: none;
    }

    .footer-social {
      &-list {
        gap: 1.2rem;
      }

      &-link {
        width: 2rem;
        height: 2rem;
      }
    }

    &-list {
      @include flex;
      gap: 3.2rem;
    }

    &-in {
      height: 100%;
      @include flex;
      justify-content: space-between;
      align-items: center;
      gap: 1.2rem;
    }

    &-it {
      @include flex;
      align-items: center;

      .link {
        gap: .8rem;
        @include flex;
        align-items: center;
        color: $color-white;
        font-weight: normal;
      }

      .icon {
        --wicon: 2rem;
        width: var(--wicon);
        height: var(--wicon);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
        }
      }
    }

    &-action {
      @include flex;
      gap: 3.2rem;
      height: 100%;
      .header-lg {
        @include max(1200px) {
          display: none;
        }

        >.header-lg-it {
          >.text {
            color: $color-white !important;
          }

          >i {
            color: $color-white !important;
          }
        }

        &-it {
          @include flex;
          align-items: center;
          justify-content: space-between;
          gap: .2rem;
          position: relative;
          width: 100%;
          height: 100%;
          line-height: normal;

          .ic {
            --wh: 1.6rem;
            @include mid-flex;
            margin-top: .2rem;
            gap: .4rem;
            flex-shrink: 0;
            @include flex;
            width: var(--wh);
            height: var(--wh);
            border-radius: $radius-9;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .text {
            @include fz-14;
            font-weight: 300;
            font-family: var(--font-fur);
            color: $color-text;
            font-weight: normal;
          }

          i {
            @include transitionRe;
          }
        }

        &-drop {
          width: 100%;
          position: absolute;
          top: 100%;
          @include flex;
          flex-direction: column;
          gap: .5rem;
          right: 0;
          @include transitionRe;
          border: .1rem solid $color-white;
          background-color: $color-white;
          box-shadow: $shadow;
          border-radius: $radius-1;
          opacity: 0;
          transform: translateY(-10px);
          visibility: hidden;

          a {
            span.ic {
              margin-right: 0 !important;
              flex-shrink: 0;
            }

            .ic {
              flex-shrink: 0;
            }
          }

          .header-lg-it {
            position: relative;
            width: fit-content;
            padding: .5rem;
            width: 100%;
            justify-content: flex-start;

            .text {
              @include transitionRe;
              font-weight: 300;
              white-space: nowrap;
            }

            &::before {
              position: absolute;
              content: '';
              top: calc(100% + .2rem);
              left: 0;
              width: 0;
              height: .1rem;
              background-color: $color-pri;
              transition: .3s ease;
            }
          }
        }
      }
    }
  }

  &-flex {
    height: var(--hd-bot);
    @include flex;
    justify-content: space-between;
    margin: auto;
    @include transitionRe;

    @include min(1201px) {
      .header-lg {
        display: none;
      }
    }

    @include max(1200px) {
      height: 100%;
    }

    &-r {
      @include flex;
      gap: 2rem;
    }

    &-in {
      @include flex;
      justify-content: space-between;
      margin: auto;
      @include transitionRe;
      height: 100%;
    }
  }

  &-wrap {
    height: 100%;
  }

  &-nav {
    @include max(1199px) {
      display: none;
    }
  }

  &-logo {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: relative;

    &-link {}

    a,
    &-link {
      display: flex;
      @include transitionRe;
      width: 10rem;
      max-height: 100%;
      @include max(1200px){
        width: 8rem;
      }
      @include max(600px){
        width: 7rem;
      }
      img {
        width: 100%;
        object-fit: contain;
        max-width: 100%;
        height: auto;
        @include transitionRe;
      }
    }
  }

  &-action {
    @include flex;
    align-items: center;
    gap: 1rem;
    @include max(600px) {
      gap: .6rem;
    }

  }

  &-lg {
    position: relative;
    z-index: 20;
    @include mid-flex;
    cursor: pointer;
    width: 5rem;
    flex-shrink: 0;
    height: 100%;
    &-it {
      @include flex;
      align-items: center;
      justify-content: space-between;
      gap: .2rem;
      position: relative;
      width: 100%;
      height: 100%;
      line-height: normal;

      .ic {
        --wh: 1.6rem;
        @include mid-flex;
        margin-top: .2rem;
        gap: .4rem;
        flex-shrink: 0;
        @include flex;
        width: var(--wh);
        height: var(--wh);
        border-radius: $radius-9;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text {
        @include fz-14;
        font-weight: 300;
        font-family: var(--font-fur);
        color: $color-text;
        font-weight: normal;
      }

      i {
        @include transitionRe;
      }
    }

    &-drop {
      width: 100%;
      position: absolute;
      top: 100%;
      @include flex;
      flex-direction: column;
      gap: .5rem;
      right: 0;
      @include transitionRe;
      border: .1rem solid $color-white;
      background-color: $color-white;
      box-shadow: $shadow;
      border-radius: $radius-1;
      opacity: 0;
      transform: translateY(-10px);
      visibility: hidden;

      a {
        span.ic {
          margin-right: 0 !important;
          flex-shrink: 0;
        }

        .ic {
          flex-shrink: 0;
        }
      }

      .header-lg-it {
        position: relative;
        width: fit-content;
        padding: .5rem;
        width: 100%;
        justify-content: flex-start;

        .text {
          @include transitionRe;
          font-weight: 300;
          white-space: nowrap;
        }

        &::before {
          position: absolute;
          content: '';
          top: calc(100% + .2rem);
          left: 0;
          width: 0;
          height: .1rem;
          background-color: $color-pri;
          transition: .3s ease;
        }

        &:hover {
          &::before {
            width: 100%;
            transition: .3s ease;
          }
        }
      }
    }

    &:hover {
      i {
        transform: rotate(180deg);
      }

      .header-lg-drop {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
        transition: .3s ease;
      }
    }
  }

  &-search {
    position: relative;
    height: 100%;
    --wh: 4.8rem;
    flex-shrink: 0;
    @include flex;
    align-items: center;
    @include max(1200px){
      --wh: 3.8rem;
    }
    // &::after {
    //   @include pseudo;
    //   width: var(--wh);
    //   height: var(--wh);
    //   background-image: url(../assets/images/circle.svg);
    //   border-radius: 1rem;
    //   transition: all 0.4s;
    //   left: 50%;
    //   opacity: 0;
    //   visibility: hidden;
    //   bottom: calc(var(--wh)/2 * -1);
    //   transform: translateX(-50%);
    // }

    &:hover {
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }

    .active {
      .header-search-close {
        opacity: 1;
        visibility: visible;
      }
    }

    &-icon {
      width: var(--wh);
      height: var(--wh);
      @include mid-flex;
      color: $color-text;
      background-color: $color-bg-pri;
      border-radius: $radius-9;
      cursor: pointer;
      img{
        width: calc(var(--wh)/3);
        height: calc(var(--wh)/3);
        object-fit: contain;
        display: block;
      }
    }

    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 0;
      z-index: 150;
      backdrop-filter: blur(0.7rem);
      background-color: $color-border-05;
      visibility: hidden;
      opacity: 0;
      transition: .3s ease;

      &.active {
        width: 100%;
        right: 0;
        visibility: visible;
        opacity: 1;
        transition: .3s ease;
      }
    }

    &-bar {
      position: fixed;
      left: 50%;
      top: 50%;
      // bottom: 0;
      // right: 0;
      // height: 100%;
      // width: 100%;
      transform: translate(-50%, -50%);
      // transform: translateY(calc(-1 * (var(--hd-top) + var(--hd-bot))));
      z-index: 150;
      @include mid-flex;
      // opacity: 0;
      // visibility: hidden;
      @include transitionRe;
      clip-path: inset(0 100% 0 0);

      &.active {
        // opacity: 1;
        // visibility: visible;
        transition: .3s ease;
        // transform: translateY(0);
        clip-path: inset(0 0 0 0);
        @include transitionRe;

        .link,
        .input {
          transition-delay: 1s;
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }

        .header-search-bar-input {
          &::before {
            width: 100%;
            transition-delay: .5s;
          }

        }

      }

      &-input {
        // width: 60%;
        width: 60vw;
        margin: auto;
        position: relative;
        background-color: transparent;
        height: 4rem;
        overflow: hidden;
        @include mid-flex;
        font-weight: normal;
        font-weight: 500;

        @include max(500px) {
          width: 85vw;
        }

        #searchform {
          @include mid-flex;
          width: 100%;
        }

        &::before {
          position: absolute;
          content: "";
          left: 0;
          bottom: 0;
          width: 0;
          height: 0.1rem;
          background-color: $color-white;
          transition: 1s
        }

        .icon {
          @include mid-flex;
        }

        .input {
          padding: 0 1.5rem 0 0;
          transform: translateY(2rem);
          opacity: 0;
          visibility: hidden;
          transition: 1.5s;
          font-family: var(--font-pri);
          font-weight: normal;
          color: $color-white;
          background-color: transparent;
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          @include fz-16;
          font-weight: 500;

          &::placeholder {
            color: $color-white;
          }

          @include max(500px) {
            @include fz-14;
          }
        }

        .link {
          cursor: pointer;
          --wh: 4rem;
          width: var(--wh);
          height: var(--wh) !important;
          flex-shrink: 0;
          padding: 0;
          height: 100%;
          @include mid-flex;
          color: $color-white;
          background-color: transparent;
          border: none;
          transition: 2s;
          transform: translateX(1rem);
          opacity: 0;
          visibility: hidden;

          .icon {
            @include mid-flex;
          }

          &::before {
            display: none;
          }

          &:hover {
            i {
              color: $color-white;

            }
          }
        }
      }
    }

    &-close {
      --w: 3.4rem;
      width: var(--w) !important;
      height: var(--w);
      @include mid-flex;
      // opacity: 0;
      // visibility: hidden;
      z-index: 150;
      position: fixed !important;
      color: $color-white;
      right: 20%;
      top: 5rem;
      @include fz-30;
      border-radius: 100rem !important;
      cursor: pointer;
      @include transitionRe;
      opacity: 0;
      visibility: hidden;
      display: none;

      @include max(600px) {
        right: 2rem;
        top: 2rem;
      }

      &.active {
        opacity: 1;
        visibility: visible;
        @include mid-flex;

      }

      &:hover {
        i {
          transform: rotate(360deg);
        }
      }

      i {
        transition: all .3s ease;
      }
    }
  }

  .menu-icon {
    @include mid-flex;

    @include min(1200px) {
      display: none;
    }

  }

}