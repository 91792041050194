
.job {
    &-cv {
        padding: 4rem 0;
        position: relative;

        .wpcf7-form {
            position: relative;

            .wpcf7-response-output {
                font-size: 1.6rem !important;
                // position: absolute;
                // bottom: -.5rem;
                // left: 2rem;
                // @include max(900px){
                //     left: 1rem;
                // }
            }
        }

        &-col {
            @include max(900px) {
                width: 100%;

            }
        }

        &-frm {
            background-color: $color-white;
            width: 100%;
            border-radius: $radius-2;
        }

        &-top {
            padding: 2rem;
        }

        &-main {
            background-color: $color-white;

            .title {
                padding: 1.2rem 2rem;
                background-color: $color-bg;

                &-second {
                    @include fz-24;
                }
            }

            .wpcf7-not-valid-tip {
                position: absolute;
            }

            .wpcf7-field-group-remove,
            .wpcf7-field-group-add {
                border: .1rem solid $color-pri;
                width: 100%;
                @include mid-flex;
                background-color: $color-bg-pri;
                padding: 1.2rem 2.4rem;
                margin-bottom: 2rem;
                border-radius: $radius-1;
                cursor: pointer;

                .txt {
                    @include fz-14;
                    color: $color-pri;
                    font-weight: 500;
                }
            }

        }

        &-lb {
            margin-bottom: .6rem;
            font-family: var(--font-pri);
            font-weight: bold;
            text-transform: capitalize;

            &.speci {
                position: relative;

                &::after {
                    content: '*';
                    color: $color-pri;
                }
            }

        }

        &-input {
            border: .1rem solid $color-border;
            width: 100%;
            @include flex;
            border-radius: $radius-2;

            &.input-sec {
                .txt {
                    width: 6.8rem;
                    border-left: .1rem solid $color-border;
                    @include mid-flex;
                    flex-shrink: 0;
                    background-color: $color-bg;
                }
            }

            .ip {
                border-radius: $radius-2;
                padding: 1rem 1.4rem;
                font-family: var(--font-pri);
                @include fz-16;
                font-weight: 500;
                width: 100%;
                border: none;
                @include flex;
                outline: none;

                &::placeholder {
                    @include fz-16;
                    color: $color-text;
                    font-family: var(--font-pri);
                }
            }
        }

        &-check {
            @include flex;
            align-items: center;
            gap: 1rem;
            @include transitionRe;
            cursor: pointer;

            &.active {
                border-color: $color-pri;
            }

            input:checked~.ic-check img {
                opacity: 1;
            }

            input:checked~.ic-check {
                border-color: $color-pri;
            }

            .ic-check {
                position: relative;
                --wh: 2rem;
                width: var(--wh);
                height: var(--wh);
                flex-shrink: 0;
                border-radius: $radius-2;
                border: .1rem solid $color-border;
                @include mid-flex;

                img {
                    width: 80%;
                    object-fit: contain;
                    display: block;
                    opacity: 0;
                    @include transitionRe;
                }
            }

            .check-txt {
                @include fz-16;
                font-family: var(--font-sec);
                color: $color-text;
                font-weight: 500;
                line-height: 100%;
            }

            .ip {
                align-items: center;
                gap: 1rem;
            }

            input {
                display: none;
            }


        }

        &-inner {
            padding: 2rem;

            @include max(900px) {
                padding: 2rem 1rem;
            }

            .lbel {
                margin-bottom: .6rem;

                .lb-sup {
                    font-style: italic;
                }
            }

            .block {
                background-color: $color-bg;
                border-radius: $radius-2;
                padding: 1.2rem .8rem;

                &:not(:last-child) {
                    margin-bottom: 2rem;
                }

                &.more-exe {
                    position: relative;
                }

                .close-exe {
                    --wh: 2.4rem;
                    position: absolute;
                    // right: calc(-1 * var(--wh)/2);
                    // top: calc(-1 * var(--wh)/2);
                    top: 0;
                    right: 0;
                    width: var(--wh);
                    height: var(--wh);
                    color: $color-pri;
                    // border-radius: $radius-9;
                    // border: .1rem solid $color-pri;
                    // background-color: $color-bg-pri;
                    @include mid-flex;
                    @include fz-18;
                    color: $color-pri;
                    font-family: var(--font-sec);

                    &:hover {
                        i {
                            transform: rotate(180deg);
                        }
                    }

                    i {
                        @include transitionRe;
                    }
                }
            }

            .gr-btn {
                @include flex;
                flex-direction: column;
                gap: 1rem;
            }

            .btn-frm {
                @include mid-flex;
                width: 100%;
                border: .1rem solid $color-pri;
                color: $color-pri;
                background-color: $color-bg-pri;
                padding: 1.2rem 2.4rem;
                @include fz-14;
                font-family: var(--font-pri);
                font-weight: 600;
                border-radius: $radius-2;
            }
        }

        &-list {
            .cv-btrn {
                width: 100%;
            }
        }

        &-item {

            .wpcf7-form-control-wrap {
                width: 100%;
                display: block;
            }

            @include max(600px) {
                width: 100%;
            }

            &.sec {
                @include max(1230px) {
                    width: 100%;
                }
            }

            .check-item {
                @include max(600px) {
                    width: 100%;
                }
            }

            .inner {
                // padding: 2rem;
            }

            .job-cvfile {
                position: relative;
                border: .1rem dashed $color-text;
                border-radius: $radius-2;

                .file-lb {
                    cursor: pointer;
                }

                .inner {
                    @include flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 1.2rem;
                    padding: 1.2rem;
                }

                input {
                    display: none;
                }

                .txt {
                    font-weight: 500;
                    font-family: var(--font-pri);
                    text-align: center;

                    @include max(600px) {
                        @include fz-12;
                    }

                    &.bold {
                        font-weight: 600;
                    }
                }
            }

            .dateTimeItem {
                position: relative;
            }

            .icon {
                display: none;
                position: absolute;
                right: 1.4rem;
                top: 0;
                bottom: 0;
                // @include mid-flex;
                margin: auto;
                width: 2rem;
                height: 2rem;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    display: block;
                }
            }

            .dateTimeText {
                position: absolute;
                left: 1.4rem;
                top: 0;
                bottom: 0;
                margin: auto;
                @include fz-16;
                @include mid-flex;
                color: $color-text;
                font-family: var(--font-pri);

                &.disable {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        &-it {
            .wpcf7-list-item {
                position: relative;
                width: 50%;
                cursor: pointer;
                padding: 0 calc(var(--cg) / 2);
                margin: 0 0 var(--rg) !important;

                @include max(700px) {
                    width: 100%;
                }

                input {
                    display: none;
                }

                >label {
                    border-radius: $radius-2;
                    border: 0.1rem solid $color-border;
                    padding: 1rem 1.4rem;
                    display: block;
                    width: 100%;
                    cursor: pointer;
                }

                input:checked {
                    ~.wpcf7-list-item-label::before {
                        border-color: $color-pri;
                    }

                    ~.wpcf7-list-item-label::after {
                        opacity: 1;
                    }
                }

                .wpcf7-list-item-label {
                    position: relative;
                    padding-left: 2.4rem;

                    &::before {
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        // background-color: red;
                        border: 0.1rem solid $color-border;
                        border-radius: $radius-1;
                        width: 1.8rem;
                        height: 1.8rem;
                    }

                    &::after {
                        position: absolute;
                        content: '';
                        display: block;
                        top: 4px;
                        left: 8px;
                        width: 4px;
                        height: 10px;
                        border: solid $color-pri;
                        border-width: 0 2px 2px 0;
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                        opacity: 0;
                        @include transitionRe;
                    }
                }
            }

            .check-item {
                width: 100%;
            }

            .check-item {
                .wpcf7-form-control {
                    @include flex;
                    --cg: 2.4rem;
                    --rg: 2.4rem;
                    flex-wrap: wrap;
                    margin: 0 calc(var(--cg) / 2 * -1) calc(var(--rg) * -1);
                }
            }

        }

        &-btn {
            padding: 1.2rem 0 2.8rem;
            margin: 0 2rem;
            border-top: .1rem solid $color-border;
            @include mid-flex;

            @include max(900px) {
                margin: 0 1rem;
            }

            .btn-pri {
                width: 100%;
            }

            input {
                display: none;
            }

            .wpcf7-spinner {
                position: absolute;
            }
        }
    }

    &-if {
        background-color: $color-white;
        width: 100%;
        border-radius: $radius-2;

        &-inner {
            @include flex;
            flex-direction: column;
            padding: 2.4rem;
            margin-bottom: 1.2rem;

            .title-second {
                margin-bottom: 2.8rem;
            }

            .des {
                @include flex;
                flex-direction: column;
                gap: .4rem;
            }
        }

        &-bdy {
            .item {
                @include flex;
                align-items: center;
                gap: .8rem;
            }

            .icon {
                --wh: 1.6rem;
                width: var(--wh);
                height: var(--wh);
                @include mid-flex;
                flex-shrink: 0;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .txt {
                font-family: var(--font-pri);

                .bold {
                    font-weight: 600;
                }
            }

            .des {
                padding: 2rem 0;
                border-top: .1rem dashed $color-text;
            }

            .btn-pri {
                width: 100%;
            }
        }
    }

    &-post {
        &-top {
            .title-second {
                margin-bottom: 2.4rem;
            }
        }

        &-inner {
            background-color: $color-white;
            padding: 2rem 1.6rem;

            .btn-view {
                width: fit-content;
                margin: 2.2rem auto 0;
                padding: .8rem 0;
                @include mid-flex;
                gap: .8rem;
                color: $color-text-8;
                font-family: var(--font-sec);
                font-weight: 700;
                position: relative;
                &:hover{
                    &::before{
                        width: 100%;
                    }
                }
                &::before{
                    position: absolute;
                    content: '';
                    background-color: $color-text-8;
                    height: .1rem;
                    width: 2em;
                    left: 0;
                    bottom: .4rem;
                    @include transitionRe;
                }
            }

        }

        &-list {
            @include flex;
            flex-direction: column;
            gap: 2.4rem;

            @include max(900px) {
                --cg: 2.4rem;
                --rg: 2.4rem;
                @include flex-wr;
                margin: 0 calc(var(--cg) / 2 * -1) calc(var(--rg) * -1);
                gap: 0;
                flex-direction: row;
            }
        }

        &-item {
            @include max(900px) {
                width: 50%;
                padding: 0 calc(var(--cg) / 2);
                margin-bottom: var(--rg);
            }

            @include max(600px) {
                width: 100%;
            }

            &:hover {
                .inner {
                    border-color: $color-border;
                    box-shadow: 0 .4rem 3rem rgba(0, 0, 0, 0.1);
                }
            }

            .inner {
                position: relative;
                padding: 2rem;
                border-radius: $radius-2;
                background-color: $color-white;
                @include transitionRe;
                height: 100%;
                @include max(800px){
                    padding: 1rem;
                }
            }

            .top {
                margin-bottom: 1.6rem;
                position: relative;

                .tt {
                    color: $color-text;
                    font-weight: bold;
                    @include transitionRe;
                    font-family: var(--font-pri);
                    margin-right: 4rem;

                    &:hover {
                        color: $color-pri;
                    }
                }

                .tag {
                    position: absolute;
                    right: -2.7rem;
                    top: 0;
                    padding: .6rem 1.6rem;
                    line-height: 100%;
                    color: $color-white;
                    font-family: var(--font-pri);
                    border-radius: .2rem;
                    border-bottom-right-radius: unset;
                    @include fz-12;
                    font-weight: bold;
                    text-transform: capitalize;
                    border-radius: 2px 4px 0px 2px;
                    z-index: 1;
                    @include max(800px){
                        right: -1.7rem;
                    }
                    &::after{
                        position: absolute;
                        content: '';
                        top: .3rem;
                        right: .3rem;
                        height: 100%;
                        width: 100%;
                        z-index: -1;
                        background-color: $color-four;
                    border-radius: 2px 4px 0px 2px;
                    }
                    &::before {
                        position: absolute;
                        content: '';
                        top: 100%;
                        right: 0;
                        border-top: 0rem solid transparent;
                        border-left: .7rem solid #293477;
                        border-right: 0rem solid transparent;
                        border-bottom: .7rem solid transparent;
                    }

                    &.sec {
                        background-color: $color-pri;
                        &::after{
                        background-color: $color-pri;
                        }
                    }

                    &.pri {
                        background-color: $color-four;
                        &::after{
                            background-color: $color-four;
                            }
                    }

                }
            }

            .bdy {
                // @include flex-wr;
                // justify-content: space-between;
                // align-items: flex-end;
                // gap: .5rem;
                @include flex;
                flex-direction: column;
                gap: .6rem;
            }

            .more {
                display: flex;
                justify-content: flex-end;
                margin-top: -2.2rem;
                @include max(900px){
                    margin-top: 1rem;
                }

                .btn-more {
                    color: $color-pri;
                    font-family: var(--font-pri);
                    font-weight: 400;
                    @include fz-14;
                    width: fit-content;
                    position: relative;
                    flex-shrink: 0;

                    &:hover {
                        &::before {
                            width: 100%;
                            left: 0;
                        }
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        right: 0;
                        bottom: 0;
                        height: .1rem;
                        width: 0;
                        background-color: $color-pri;
                        @include transitionRe;
                    }
                }
            }

            .des {
                @include flex;
                flex-direction: column;
                gap: .6rem;

            }

            .item {
                width: 100%;
                @include flex-wr;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;

                &:last-child {
                    padding-right: 7.5rem;
                    @include max(900px){
                        padding-right: 0
                    }
                }
            }

            .in {
                @include flex;
                // align-items: center;
                gap: .8rem;
            }

            .txt {
                @include fz-15;
                font-weight: 500;
            }

            .icon {
                width: 2rem;
                height: 2rem;
                flex-shrink: 0;
                margin-top: .1rem;

                img {
                    height: 100%;
                    width: 100%;
                    display: block;
                    object-fit: contain;
                }
            }
        }
    }

    &-thumb {
        margin-top: 2.4rem;
        width: 100%;

        @include max(900px) {
            width: 70%;
            margin: 2.4rem auto 0;
        }

        .inner {
            @include imgPd(616, 405);
            border-radius: $radius-2;
            width: 100%;

            img {
                @include imgCover;
            }
        }
    }
    &-category {
        padding: 2rem 3.2rem;
        background-color: $color-white;
        border-radius: $radius-1;

        .title-second {
            @include fz-32;
            font-family: var(--font-pri);
            font-weight: bold;
            color: $color-text;
        }

        .list {}

        .item {
            --wh: 1.6rem;
            padding-right: 2rem;
            padding-top: 1.2rem;
            position: relative;

            &:not(:last-child) {
                padding: 1.2rem 0;
                border-bottom: .1rem solid $color-border-2;
            }

            &.active,
            &:hover {
                color: $color-pri;
                text-decoration: underline;

                .icon {
                    opacity: 1;
                    transform: translate(0, -50%);
                }
            }
        }

        .icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(-.5rem, -50%);
            width: var(--wh);
            height: var(--wh);
            opacity: 0;
            @include transitionRe;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
            }
        }
    }
}

.avatar-wrapper {
    position: relative;
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    overflow: hidden;
    border: .1rem dashed $color-border;
    @include transitionRe;
    margin: .8rem 0;

    input {
        display: none !important;
    }

    &:hover {
        transform: scale(1.05);
        cursor: pointer;
    }

    &:hover .profile-pic {
        opacity: .5;
    }

    .profile-pic {
        position: relative;
        z-index: 1;
        height: 100%;
        width: 100%;
        margin: auto;
        @include mid-flex;
        border: none;
        border-radius: 100%;
        outline: none;
        overflow: hidden;
        @include transitionRe;
    }

    .icon {
        display: block !important;
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 1;
        // z-index: -1;
        @include transitionRe;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
        }
    }

    .upload-button {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        @include mid-flex;
        z-index: 1;
    }
}