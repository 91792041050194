
.swiper{
    &-slide{
        height: auto;
    }
    &-pagination {
        position: static;
        margin-top: 2rem;
    
        &-bullet {
            --circle: -0.6rem;
            background-color: $color-text-7;
            width: 1.2rem;
            height: 1.2rem;
            opacity: .6;
            position: relative;
            margin: 0 0.3rem !important;
            transition: 0.4s;
            border-radius: $radius-9;
    
            &::before {
                content: '';
                position: absolute;
                top: var(--circle);
                right: var(--circle);
                bottom: var(--circle);
                left: var(--circle);
                border: 1px solid $color-pri;
                border-radius: 100rem;
                opacity: 0;
                transition: 0.1s ease-in-out;
                display: none;
            }
    
            &-active {
                background-color: $color-pri;
                width: 4rem;
                opacity: 1;
                &::before {
                    opacity: 1;
                }
            }
        }
    }
    &-next{
        right: calc(-1 * var(--wh)*1.5);
        &::after{
            left: 0;
        }
        &:hover{
            &::after{
              transform: translateX(100%);
            }
        }
    }
    &-prev{
        left: calc(-1 * var(--wh)*1.5);
        &::after{
            right: 0;
        }
        &:hover{
            &::after{
                transform: translateX(-100%)
            }
        }
    }
    &-next,
    &-prev{
        @include mid-flex;
        --wh: 4.8rem;
        width: var(--wh);
        height: var(--wh);
        flex-shrink: 0;
        border-radius: $radius-9;
        position: absolute;
        background-color: $color-bg-1;
        overflow: hidden;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        color: $color-text-7;
        z-index: 1;
        @include transitionRe;
        &:hover{
            color: $color-second;
            &::before{
                border-color: $color-second;
            }
        }
        &::before{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            border: .2rem solid $color-text-7;
        border-radius: $radius-9;
        @include transitionRe;

        }
        &::after{
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            width: 50%;
            background-color: $color-bg-1;
            @include transitionRe;
        }
        i{
            font-size: 2rem;
            position: relative;
            z-index: 2;
        }
    }
}