@charset "UTF-8";
@import url("../assets/library/fontawesome/css/all.css");
@import url("../assets/library/fontawesome/fontawesome/fontawesome.css");
@import url("../assets/library/aos/aos.css");
@import url("../assets/library/swiper/swiper-bundle.min.css");
@import url("../assets/library/select2/select2.min.css");
@import url("../assets/library/fancybox/fancybox.css");
@import url("../assets/library/jquery/jquery.js");
@import url("../assets/font/Mulish/stylesheet.css");
@import url("../assets/font/Open_Sans/stylesheet.css");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.mona-content strong,
.mona-content b {
  font-weight: bold; }

.mona-content em,
.mona-content i {
  font-style: italic; }

.mona-content h1,
.mona-content h2,
.mona-content h3,
.mona-content h4,
.mona-content h5,
.mona-content h6 {
  line-height: 1.3;
  margin-bottom: 0.5em;
  margin-top: 0.6em; }

.mona-content h1 {
  font-size: 1.7em; }

.mona-content h2 {
  font-size: 1.6em; }

.mona-content h3 {
  font-size: 1.25em; }

.mona-content h4 {
  font-size: 1.125em; }

.mona-content h5 {
  font-size: 1em; }

.mona-content h6 {
  font-size: 0.85em; }

.mona-content p {
  margin: 1em 0; }

.mona-content ul,
.mona-content ol {
  margin: 1em 0;
  list-style-position: inside; }

.mona-content ul ul,
.mona-content ul ol,
.mona-content ol ul,
.mona-content ol ol {
  margin-left: 1em; }

.mona-content ul {
  list-style-type: disc; }

.mona-content ol {
  list-style-type: decimal; }

.mona-content ul ul,
.mona-content ol ul {
  list-style-type: circle; }

.mona-content ol ol,
.mona-content ul ol {
  list-style-type: lower-latin; }

.mona-content img,
.mona-content iframe {
  max-width: 100% !important;
  height: auto; }

.mona-content blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1em 0;
  padding: 1em;
  quotes: "“" "”" "‘" "’"; }
  .mona-content blockquote::before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em; }
  .mona-content blockquote p {
    display: inline; }

.mona-content table {
  border-collapse: collapse;
  max-width: 100%;
  margin: 1em 0;
  border: 1px solid #e1e1e1; }
  .mona-content table th,
  .mona-content table td {
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    padding: 5px 10px;
    vertical-align: middle; }

.mona-content .mona-youtube-wrap {
  position: relative;
  height: 0;
  padding-top: 56.25%; }
  .mona-content .mona-youtube-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.mona-content .mona-table-wrap {
  overflow: auto; }

:root {
  margin-top: 0 !important;
  --size-hd: 13rem;
  --size-sc: 7rem;
  --hd-top: 4rem;
  --hd-bot: 9rem;
  --remainder: calc(100vw - 123rem);
  --halfremainder: calc(var(--remainder)/2);
  --seremainder: calc(var(--halfremainder)/3);
  --halfseremainder: calc(var(--halfremainder)/2);
  --font-pri: 'Mulish';
  --font-sec: 'Open Sans';
  --pad-pri: 6rem;
  --pad-sec: 4rem; }
  @media (max-width: 1200px) {
    :root {
      --size-hd: 7rem;
      --size-sc: 7rem; } }
  @media (max-width: 600px) {
    :root {
      --size-hd: 6rem;
      --size-sc: 6rem; } }

* {
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  scroll-behavior: smooth; }
  @media screen and (min-width: 2000px) {
    html {
      font-size: 100%; } }

body {
  font-size: 16px;
  line-height: 1.55;
  font-weight: normal;
  font-family: var(--font-pri);
  color: #191919;
  background-color: #F5F6F7;
  overflow: hidden auto; }
  body::-webkit-scrollbar {
    width: .5rem; }
  body::-webkit-scrollbar-thumb {
    background-image: linear-gradient(#2E3192, #2E3192);
    border-radius: 50px; }
  @media screen and (min-width: 2000px) {
    body {
      font-size: 20px; } }
  @media screen and (min-width: 1201px) {
    body.home .header {
      background-color: transparent; }
      body.home .header .header-logo-link img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(140deg) brightness(101%) contrast(101%); }
      body.home .header .header-user-link,
      body.home .header .header-phone-link {
        color: #ffffff; }
        body.home .header .header-user-link img,
        body.home .header .header-phone-link img {
          filter: brightness(100%) invert(1); }
      body.home .header .header-nav .menu-nav > .menu-list > .menu-item > .menu-link {
        color: #ffffff; }
        body.home .header .header-nav .menu-nav > .menu-list > .menu-item > .menu-link::after {
          background-color: #ffffff; }
        body.home .header .header-nav .menu-nav > .menu-list > .menu-item > .menu-link i {
          color: #ffffff; }
      body.home .header.sticky {
        background-color: #ffffff; }
        body.home .header.sticky .header-logo-link img {
          filter: none; }
        body.home .header.sticky .header-nav .menu-nav > .menu-list > .menu-item > .menu-link {
          color: #101112; }
          body.home .header.sticky .header-nav .menu-nav > .menu-list > .menu-item > .menu-link::after {
            background-color: #101112; }
          body.home .header.sticky .header-nav .menu-nav > .menu-list > .menu-item > .menu-link i {
            color: #101112; }
        body.home .header.sticky .header-search-icon img {
          filter: unset; }
        body.home .header.sticky .header-lg > .header-lg-it {
          color: #101112; }
          body.home .header.sticky .header-lg > .header-lg-it .text {
            color: #101112; }
        body.home .header.sticky .header-user-link,
        body.home .header.sticky .header-phone-link {
          color: #101112; }
          body.home .header.sticky .header-user-link img,
          body.home .header.sticky .header-phone-link img {
            filter: unset; }
    body.home .main {
      padding-top: 0; } }

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  text-decoration: none !important; }

img {
  max-width: 100%;
  display: block; }

.h1hidden {
  height: 0;
  width: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 0; }

a {
  text-decoration: none;
  color: inherit; }

textarea,
input {
  resize: none;
  outline: none;
  font-family: "SF Pro Display", sans-serif;
  font-size: 14px; }

button,
select {
  border: none;
  outline: none;
  background-color: transparent; }

.main {
  min-height: 40vh;
  padding-top: var(--size-hd); }
  @media screen and (max-width: 1200px) {
    .main {
      min-height: 20vh; } }

.container {
  width: 100%;
  max-width: 123rem;
  height: 100%;
  margin: 0 auto;
  padding: 0 15px; }

.over {
  overflow-x: auto;
  scroll-snap-type: x mandatory; }
  .over::-webkit-scrollbar {
    display: none; }

.no-scroll {
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none; }

iframe {
  vertical-align: middle; }

.effectShine {
  position: relative;
  overflow: hidden; }

.effectShine::before {
  content: "";
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg); }

.wow {
  animation-duration: 1s; }

.load-item {
  display: none; }

.show-hide-toggle {
  cursor: pointer; }

.dnor {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
  flex-wrap: wrap;
  margin-bottom: -3rem; }
  .dnor-item {
    padding: 0 1.5rem;
    margin-bottom: 3rem; }

.dsmall {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-align-items: stretch;
  flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1.2rem;
  margin-bottom: -2.4rem; }
  .dsmall-item {
    padding: 0 1.2rem;
    margin-bottom: 2.4rem; }

.dmini {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-align-items: stretch;
  flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
  margin-bottom: -1rem; }
  .dmini-item {
    padding: 0 0.5rem;
    margin-bottom: 1rem; }

.dside {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: stretch;
  align-items: stretch;
  -webkit-align-items: stretch;
  flex-wrap: wrap;
  flex-wrap: wrap; }

.row {
  --cg: 2.4rem;
  --rg: 2.4rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(var(--cg) / 2 * -1) calc(var(--rg) * -1); }

.rows {
  --cg: 2.4rem;
  --rg: 2.4rem;
  margin: 0 calc(var(--cg) / 2 * -1); }

.col {
  width: 100%;
  padding: 0 calc(var(--cg) / 2);
  margin-bottom: var(--rg); }
  .col-11 {
    width: calc(11/12 * 100%); }
  .col-10 {
    width: calc(10/12 * 100%); }
  .col-9 {
    width: calc(9/12 * 100%); }
  .col-8 {
    width: calc(8/12 * 100%); }
  .col-7 {
    width: calc(7/12 * 100%); }
  .col-6 {
    width: 50%; }
  .col-5 {
    width: calc(5/12 * 100%); }
  .col-4 {
    width: calc(4/12 * 100%); }
  .col-3 {
    width: calc(3/12 * 100%); }
  .col-2 {
    width: calc(2/12 * 100%); }
  .col-1 {
    width: calc(1/12 * 100%); }
  .col-5i {
    width: calc(100% / 5); }
  .col-4i {
    width: calc(100% / 4); }

@keyframes cloudMove {
  0% {
    left: 0; }
  100% {
    left: 100%; } }

@keyframes movel_wave {
  0% {
    transform: translateX(30%) translateZ(0); }
  50% {
    transform: translateX(0) translateZ(0); }
  100% {
    transform: translateX(-30%) translateZ(0); } }

@keyframes mover_wave {
  0% {
    transform: translateX(-30%) translateZ(0); }
  50% {
    transform: translateX(0%) translateZ(0); }
  100% {
    transform: translateX(30%) translateZ(0); } }

@keyframes cloudFade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes show {
  0% {
    opacity: 0;
    transform: translateY(100%); }
  100% {
    opacity: 1;
    transform: translateY(0%); } }

@keyframes show {
  0% {
    opacity: 0;
    transform: translateY(10%); }
  100% {
    opacity: 1;
    transform: translateY(0%); } }

@-webkit-keyframes flip {
  30% {
    transform: rotateY(45deg); }
  50% {
    transform: rotateY(-45deg); }
  100% {
    transform: rotateY(0); } }

@keyframes flip {
  30% {
    transform: rotateY(45deg); }
  50% {
    transform: rotateY(-45deg); }
  100% {
    transform: rotateY(0); } }

@-webkit-keyframes pin {
  0% {
    opacity: 0.6;
    transform: scale(0.5); }
  100% {
    opacity: 0;
    transform: scale(1.2); } }

@-webkit-keyframes phone-fn {
  0% {
    transform: rotate(0deg) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  100%, 50% {
    transform: rotate(0deg) scale(1) skew(1deg); } }

@keyframes phone-fn {
  0% {
    transform: rotate(0deg) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  100%, 50% {
    transform: rotate(0deg) scale(1) skew(1deg); } }

@keyframes moveRight {
  100% {
    background-position: 100% 0; } }

@-webkit-keyframes moveRight {
  100% {
    background-position: 100% 0; } }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

@-moz-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-webkit-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@-o-keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg); }
  40% {
    transform: rotate(25deg) scale(1) skew(1deg); }
  50% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@keyframes phoneRing {
  0% {
    transform: rotate(0) scale(1) skew(1deg); }
  10% {
    transform: rotate(-35deg) scale(1) skew(1deg); }
  20% {
    transform: rotate(35deg) scale(1) skew(1deg); }
  30% {
    transform: rotate(-35deg) scale(1.1) skew(1deg); }
  40% {
    transform: rotate(35deg) scale(1.1) skew(1deg); }
  50% {
    transform: rotate(-35%) scale(1) skew(1deg); }
  60% {
    transform: rotate(35%) scale(1) skew(1deg); }
  70% {
    transform: rotate(0) scale(1) skew(1deg); }
  100% {
    transform: rotate(0) scale(1) skew(1deg); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

@-webkit-keyframes ZoomIn {
  0% {
    transform: scale(0.95);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes ZoomIn {
  0% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes Spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes BTT {
  0% {
    opacity: 0; }
  33% {
    opacity: 1; }
  66% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes halo {
  from {
    transform: translate(-50%, -50%) scale(0); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

@keyframes mirror {
  100% {
    transform: translate3d(2000%, 0, 0) rotate(35deg); } }

@keyframes DownUp {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

@keyframes jelly {
  25% {
    transform: scale(0.9, 1.1); }
  50% {
    transform: scale(1.1, 0.9); }
  75% {
    transform: scale(0.95, 1.05); } }

@keyframes jellyTranForm {
  25% {
    transform: translate(-50%, -50%) scale(0.9, 1.1); }
  50% {
    transform: translate(-50%, -50%) scale(1.1, 0.9); }
  75% {
    transform: translate(-50%, -50%) scale(0.95, 1.05); } }

@keyframes jittery {
  5%,
  50% {
    transform: scale(1); }
  10% {
    transform: scale(0.9); }
  15% {
    transform: scale(1.05); }
  20% {
    transform: scale(1.05) rotate(-5deg); }
  25% {
    transform: scale(1.05) rotate(5deg); }
  30% {
    transform: scale(1.05) rotate(-3deg); }
  35% {
    transform: scale(1.05) rotate(2deg); }
  40% {
    transform: scale(1.05) rotate(0); } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

@keyframes grow {
  from {
    transform: scale(0, 0);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@keyframes grows {
  from {
    transform: scale(0.9, 0.9);
    opacity: 1; }
  to {
    transform: scale(1.5, 1.5);
    opacity: 0; } }

@keyframes zoomInSlow {
  from {
    transform: translate(-50%, -50%) scale(1.2); }
  to {
    transform: translate(-50%, -50%) scale(1); } }

@keyframes zoomOutSlow {
  from {
    transform: translate(-50%, -50%) scale(1); }
  to {
    transform: translate(-50%, -50%) scale(1.2); } }

@keyframes zoomIns {
  from {
    transform: scale(1.2); }
  to {
    transform: scale(1); } }

@keyframes fadeUp {
  from {
    transform: translateY(10rem);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 0.4; } }

@keyframes zoomOutSlow {
  from {
    transform: translate(-50%, -50%) scale(1); }
  to {
    transform: translate(-50%, -50%) scale(1.2); } }

@keyframes DownUp2 {
  0% {
    transform: translate(-50%, 0); }
  50% {
    transform: translate(-50%, -10px); }
  100% {
    transform: translate(-50%, 0); } }

@keyframes fadeLeftLong {
  from {
    transform: translateX(10rem);
    opacity: 0; }
  to {
    transform: translateX(0);
    opacity: 1; } }

@keyframes imgLoad {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-in-right {
  0% {
    transform: translate3D(-100%, 0, 0); }
  100% {
    transform: translate3D(0, 0, 0); } }

@keyframes slide-out-right {
  0% {
    transform: translate3D(0, 0, 0); }
  100% {
    transform: translate3D(100%, 0, 0); } }

@keyframes slide-in-right-50 {
  0% {
    transform: translate3D(-50%, 0, 0); }
  100% {
    transform: translate3D(0, 0, 0); } }

@keyframes slide-in-left {
  0% {
    transform: translate3D(100%, 0, 0); }
  100% {
    transform: translate3D(0, 0, 0); } }

@keyframes slide-out-left {
  0% {
    transform: translate3D(0, 0, 0); }
  100% {
    transform: translate3D(-100%, 0, 0); } }

@keyframes slide-in-top {
  0% {
    transform: translate3D(0, -100%, 0); }
  100% {
    transform: translate3D(0, 0, 0); } }

@keyframes slide-out-left-50 {
  0% {
    transform: translate3D(0, 0, 0); }
  100% {
    transform: translate3D(-50%, 0, 0); } }

@keyframes slide-in-left-50 {
  0% {
    transform: translate3D(50%, 0, 0); }
  100% {
    transform: translate3D(0, 0, 0); } }

@keyframes revealRTL {
  0% {
    width: 0;
    right: 0; }
  65% {
    width: 100%;
    right: 0; }
  100% {
    width: 0;
    right: 100%; } }

@keyframes shine2 {
  0% {
    left: -50%;
    opacity: 0.5; }
  50% {
    left: 25%;
    opacity: 0.25; }
  100% {
    left: 100%;
    opacity: 0; } }

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes tracking-in-expand {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@keyframes tracking-in-expand {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0; }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@keyframes toTop {
  0% {
    bottom: 0%;
    opacity: 0; }
  20% {
    bottom: 0%;
    opacity: 1; }
  100% {
    bottom: 100%;
    opacity: 0; } }

@-webkit-keyframes tilt-in-fwd-bl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
    transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
    opacity: 0; }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1; } }

@keyframes tilt-in-fwd-bl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
    transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
    opacity: 0; }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1; } }

@-webkit-keyframes tilt-in-fwd-br {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
    transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
    opacity: 0; }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1; } }

@keyframes tilt-in-fwd-br {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
    transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
    opacity: 0; }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1; } }

@keyframes tabUp {
  from {
    transform: translateY(100%) scale(0.9);
    opacity: 0; }
  to {
    transform: translateY(0) scale(1);
    opacity: 1; } }

@keyframes tabDown {
  from {
    transform: translateY(0) scale(1);
    opacity: 1; }
  to {
    transform: translateY(-100%) scale(0.9);
    opacity: 0; } }

@keyframes tabRight {
  from {
    transform: translateX(100%) scale(0.9);
    opacity: 0; }
  to {
    transform: translateX(0) scale(1);
    opacity: 1; } }

@keyframes tabLeft {
  from {
    transform: translateX(0) scale(1);
    opacity: 1; }
  to {
    transform: translateX(-100%) scale(0.9);
    opacity: 0; } }

@keyframes beat {
  0% {
    transform: scale(1); }
  14% {
    transform: scale(1.1); }
  96% {
    transform: scale(1.05); }
  100% {
    transform: scale(1); } }

.mobile {
  position: fixed;
  z-index: 140;
  top: var(--size-hd);
  left: 0;
  bottom: 0;
  min-width: 50rem;
  width: 50rem;
  background-color: #ffffff;
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -ms-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s;
  transform: translateX(-110%); }
  @media screen and (max-width: 500px) {
    .mobile {
      width: 90%;
      min-width: initial; } }
  .mobile.open {
    transform: translateX(0); }
  @media screen and (min-width: 1201px) {
    .mobile {
      display: none; } }
  .mobile-con {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-top: 2rem; }
  .mobile-heading {
    font-size: 1.8rem;
    line-height: 1.5;
    color: #191919;
    font-weight: 700;
    margin-bottom: 2rem;
    text-transform: uppercase; }
  .mobile-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    transition: 0.6s ease;
    -webkit-transition: 0.6s ease;
    -moz-transition: 0.6s ease;
    -ms-transition: 0.6s ease;
    -o-transition: 0.6s ease;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-110%);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    top: var(--size-hd); }
    .mobile-overlay.open {
      opacity: 1;
      visibility: visible;
      transform: translateX(0); }
    @media screen and (min-width: 1201px) {
      .mobile-overlay {
        display: none; } }
  .mobile-wr {
    padding-bottom: 6rem;
    position: relative; }
  .mobile-list {
    margin-bottom: 2rem; }
  .mobile-content {
    padding: 2.4rem 1.6rem; }
    .mobile-content .title {
      font-weight: 600;
      font-size: 1.4rem;
      position: relative;
      padding-left: 2rem;
      color: #191919; }
      .mobile-content .title::after {
        content: '';
        position: absolute;
        width: 1.4rem;
        height: 1.4rem;
        left: 0;
        position: absolute;
        font-family: "Font Awesome 6 Pro"; }
    .mobile-content .mobile-fl.add .title::after {
      content: '\f3c5'; }
    .mobile-content .mobile-fl.ph .title::after {
      content: '\f2a0'; }
    .mobile-content .mobile-fl.mail .title::after {
      content: '\f0e0'; }
  .mobile-title {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1.6rem; }
  .mobile-mxh {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    border-top: 1px solid #191919;
    background-color: #ffffff; }
    .mobile-mxh-link {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      flex: 1;
      height: 100%;
      cursor: pointer;
      transition: .3s ease; }
      @media (hover: hover) {
        .mobile-mxh-link:hover i,
        .mobile-mxh-link:hover svg,
        .mobile-mxh-link:hover img {
          transform: translateY(-0.4rem);
          transition: .3s ease; } }
      .mobile-mxh-link:not(:first-child) {
        border-left: 1px solid #191919; }
      .mobile-mxh-link img {
        --w: 3rem;
        width: var(--w);
        height: var(--w);
        transition: .3s ease; }
  .mobile-text {
    font-size: 1.4rem;
    display: block;
    color: #191919;
    word-break: break-word;
    font-weight: 500; }
    .mobile-text:not(:last-child) {
      margin-bottom: 0.8rem; }
  .mobile-account {
    position: absolute;
    top: 1rem;
    left: 1rem; }
  .mobile-re {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center; }
    .mobile-re-link {
      font-size: 1.6rem;
      color: #ffffff;
      text-decoration: underline; }
      .mobile-re-link:not(:first-child) {
        margin-left: 16px; }
  .mobile-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 3.5rem;
    height: 3.5rem;
    border: 1px solid #2E3192;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    background-color: white; }
    .mobile-close:hover .icon {
      transform: rotate(360deg); }
    .mobile-close .icon {
      transition: 0.6s ease-in-out;
      font-weight: 500;
      font-size: 1.8rem;
      color: #2E3192; }
  .mobile-fl .fw-6 {
    margin-bottom: 0.8rem;
    margin-bottom: 0.8rem;
    display: inline;
    width: fit-content;
    font-size: 1.4rem; }
  .mobile-fl .mobile-text {
    display: inline;
    font-size: 1.4rem; }
  .mobile-fl .dot {
    display: none; }

.header-nav .menu-nav {
  height: 100%; }
  .header-nav .menu-nav > .menu-list {
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    gap: 2.4rem; }
    .header-nav .menu-nav > .menu-list > .menu-item {
      height: 100%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center; }
      .header-nav .menu-nav > .menu-list > .menu-item.dropdown .menu-item.current-menu-item .menu-link {
        color: #F1B650; }
      .header-nav .menu-nav > .menu-list > .menu-item > .menu-link {
        width: 100%;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        color: #000000;
        font-weight: 600;
        white-space: nowrap;
        position: relative; }
        .header-nav .menu-nav > .menu-list > .menu-item > .menu-link:hover {
          color: #F1B650 !important; }
          .header-nav .menu-nav > .menu-list > .menu-item > .menu-link:hover i {
            color: #F1B650;
            transform: rotate(180deg); }
          .header-nav .menu-nav > .menu-list > .menu-item > .menu-link:hover::after {
            transform: translateX(-50%);
            width: 1.5rem; }
      .header-nav .menu-nav > .menu-list > .menu-item:hover > .menu-link, .header-nav .menu-nav > .menu-list > .menu-item.current-menu-parent > .menu-link, .header-nav .menu-nav > .menu-list > .menu-item.current-menu-item > .menu-link {
        color: #F1B650; }
        .header-nav .menu-nav > .menu-list > .menu-item:hover > .menu-link i, .header-nav .menu-nav > .menu-list > .menu-item.current-menu-parent > .menu-link i, .header-nav .menu-nav > .menu-list > .menu-item.current-menu-item > .menu-link i {
          color: #F1B650; }
      .header-nav .menu-nav > .menu-list > .menu-item > .menu-list {
        top: 100%; }

.header-nav .menu-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center; }

.header-nav .menu-item {
  position: relative;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -o-transition-delay: 0s; }
  .header-nav .menu-item:hover > .menu-list {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0); }
    .header-nav .menu-item:hover > .menu-list > .menu-item.current-menu-item > a {
      color: #ffffff; }
  .header-nav .menu-item:hover .menu-link,
  .header-nav .menu-item:hover a {
    transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s; }
  .header-nav .menu-item .menu-link,
  .header-nav .menu-item a {
    transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s; }
    .header-nav .menu-item .menu-link i,
    .header-nav .menu-item a i {
      transition: 0.2s ease-in-out;
      -webkit-transition: 0.2s ease-in-out;
      -moz-transition: 0.2s ease-in-out;
      -ms-transition: 0.2s ease-in-out;
      -o-transition: 0.2s ease-in-out;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s; }
  .header-nav .menu-item > .menu-link,
  .header-nav .menu-item > a {
    display: block;
    font-size: 1.6rem;
    font-weight: 500; }
    .header-nav .menu-item > .menu-link > i,
    .header-nav .menu-item > a > i {
      display: none;
      font-weight: 500;
      margin-left: 0.7rem;
      font-size: 1.2rem;
      color: #191919; }
  .header-nav .menu-item.dropdown > a > i {
    display: inline-block; }
  .header-nav .menu-item > .menu-list {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 25rem;
    max-width: 28rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #ffffff;
    box-shadow: 0 0.4rem 0.6rem rgba(16, 24, 40, 0.1);
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    transform: translate(0, 10px); }
    .header-nav .menu-item > .menu-list > .menu-item {
      margin-left: 0;
      width: 100%; }
      .header-nav .menu-item > .menu-list > .menu-item.current-menu-item > .menu-link,
      .header-nav .menu-item > .menu-list > .menu-item.current-menu-item > a, .header-nav .menu-item > .menu-list > .menu-item:hover > .menu-link,
      .header-nav .menu-item > .menu-list > .menu-item:hover > a {
        color: #ffffff;
        background-color: #F1B650; }
        .header-nav .menu-item > .menu-list > .menu-item.current-menu-item > .menu-link > i,
        .header-nav .menu-item > .menu-list > .menu-item.current-menu-item > a > i, .header-nav .menu-item > .menu-list > .menu-item:hover > .menu-link > i,
        .header-nav .menu-item > .menu-list > .menu-item:hover > a > i {
          color: #ffffff; }
      .header-nav .menu-item > .menu-list > .menu-item > .menu-link,
      .header-nav .menu-item > .menu-list > .menu-item > a {
        padding: 1rem 1.4rem;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 2; }
        .header-nav .menu-item > .menu-list > .menu-item > .menu-link > i,
        .header-nav .menu-item > .menu-list > .menu-item > a > i {
          transform: rotate(-90deg) translate(5px, 0);
          margin-top: 0.8rem;
          font-size: 1rem;
          color: #ffffff; }
      .header-nav .menu-item > .menu-list > .menu-item > .menu-list {
        left: 100%; }

.mobile-nav .menu-nav > .menu-list > .menu-item:hover > a, .mobile-nav .menu-nav > .menu-list > .menu-item.current_page_item > a, .mobile-nav .menu-nav > .menu-list > .menu-item.current-menu-item > a {
  color: #F1B650;
  font-weight: 700; }

.mobile-nav .menu-nav > .menu-list > .menu-item.active > a {
  color: #F1B650;
  font-weight: 700; }

.mobile-nav .menu-nav > .menu-list > .menu-item:has(.current-menu-item) > a {
  color: #191919; }

.mobile-nav .menu-nav > .menu-list > .menu-item > a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #191919;
  font-size: 1.6rem;
  font-weight: 700;
  border-bottom: 1px solid #191919; }
  @media screen and (max-width: 400px) {
    .mobile-nav .menu-nav > .menu-list > .menu-item > a {
      font-size: 1.4rem; } }

.mobile-nav .menu-item:hover > a {
  color: #F1B650; }

.mobile-nav .menu-item.dropdown > a > i {
  display: flex; }

.mobile-nav .menu-item.active > a > i {
  transform: rotate(180deg); }

.mobile-nav .menu-item a {
  position: relative; }
  .mobile-nav .menu-item a i {
    color: #191919;
    width: 2rem;
    height: 2rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    display: none;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }

.mobile-nav .menu-item > a {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem;
  font-size: 1.6rem;
  color: #191919;
  font-weight: 500; }
  @media screen and (max-width: 400px) {
    .mobile-nav .menu-item > a {
      font-size: 1.4rem; } }

.mobile-nav .menu-item > .menu-list {
  display: none;
  margin-left: 1.6rem;
  position: relative; }
  .mobile-nav .menu-item > .menu-list > .menu-item.current_page_item > a, .mobile-nav .menu-item > .menu-list > .menu-item.current-menu-item > a {
    color: #F1B650; }
  .mobile-nav .menu-item > .menu-list > .menu-item:has(.current-menu-item) > a {
    color: #F1B650; }

.title-pri {
  font-size: 4rem;
  text-transform: capitalize;
  font-weight: 600; }
  @media only screen and (max-width: 1200px) {
    .title-pri {
      font-size: 3.5rem; } }
  @media only screen and (max-width: 800px) {
    .title-pri {
      font-size: 2.7rem; } }

.title-second {
  font-size: 3.6rem;
  text-transform: capitalize;
  font-weight: bold;
  color: #191919;
  line-height: 130%;
  font-family: var(--font-pri);
  text-wrap: pretty; }
  @media only screen and (max-width: 1200px) {
    .title-second {
      font-size: 3.1rem; } }
  @media only screen and (max-width: 800px) {
    .title-second {
      font-size: 2.6rem; } }
  @media screen and (max-width: 450px) {
    .title-second {
      font-size: 2rem; } }
  .title-second .txtpri {
    color: #2E3192; }
  .title-second .txtsec {
    color: #F1B650; }
  .title-second.font-sec {
    font-size: 2rem; }
    @media only screen and (max-width: 1200px) {
      .title-second.font-sec {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .title-second.font-sec {
        font-size: 1.6rem; } }
  .title-second.font-pri {
    font-size: 2.4rem; }
    @media only screen and (max-width: 1200px) {
      .title-second.font-pri {
        font-size: 2rem; } }
    @media only screen and (max-width: 800px) {
      .title-second.font-pri {
        font-size: 1.7rem; } }
  .title-second.font-thr {
    font-size: 4.8rem; }
    @media only screen and (max-width: 1200px) {
      .title-second.font-thr {
        font-size: 4.3rem; } }
    @media only screen and (max-width: 800px) {
      .title-second.font-thr {
        font-size: 3.3rem; } }
    @media screen and (max-width: 450px) {
      .title-second.font-thr {
        font-size: 2.4rem; } }
  .title-second.font-fur {
    font-size: 4rem; }
    @media only screen and (max-width: 1200px) {
      .title-second.font-fur {
        font-size: 3.5rem; } }
    @media only screen and (max-width: 800px) {
      .title-second.font-fur {
        font-size: 2.7rem; } }
  .title-second.font-fiv {
    font-size: 5.2rem; }
    @media only screen and (max-width: 1200px) {
      .title-second.font-fiv {
        font-size: 4.5rem; } }
    @media only screen and (max-width: 800px) {
      .title-second.font-fiv {
        font-size: 3.4rem; } }
  .title-second.firwhite .text {
    color: #ffffff; }

.title-sub {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: var(--font-pri);
  color: #191919; }

.title-third {
  font-size: 2rem;
  font-family: var(--font-pri);
  font-weight: 600;
  color: #000000; }
  @media only screen and (max-width: 1200px) {
    .title-third {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 800px) {
    .title-third {
      font-size: 1.6rem; } }

.t-white {
  color: #ffffff; }

.t-colpri {
  color: #2E3192; }

.t-colsec {
  color: #F1B650; }

.t-upper {
  text-transform: uppercase; }

.t-capi {
  text-transform: capitalize; }

.t-bold {
  font-weight: bold; }

.t-center {
  text-align: center; }

.h1-hidden {
  height: 0;
  width: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 0; }

::-webkit-scrollbar {
  width: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: transparent; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2E3192;
  border-radius: 100rem; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2E3192; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: 50;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  height: var(--size-hd);
  background-color: #ffffff;
  border-bottom: 0.1rem solid #ffffff; }
  .header.sticky {
    box-shadow: 0 0.4rem 0.6rem rgba(16, 24, 40, 0.1); }
    @media screen and (min-width: 1200px) {
      .header.sticky {
        transform: translateY(calc(-1 * var(--hd-top))); }
        .header.sticky .header-search-bar {
          top: calc(100vh - var(--size-hd));
          transform: translate(-50%, calc(-50vh - ( -1 * var(--size-hd)))); }
        .header.sticky .header-search-close {
          top: calc(var(--hd-top) + 5rem); }
        .header.sticky .header-search-overlay {
          top: var(--hd-top);
          height: 100vh; } }
  .header-top {
    height: var(--hd-top);
    background-color: #2E3192; }
    @media screen and (max-width: 1200px) {
      .header-top {
        display: none; } }
    .header-top .footer-social-list {
      gap: 1.2rem; }
    .header-top .footer-social-link {
      width: 2rem;
      height: 2rem; }
    .header-top-list {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      gap: 3.2rem; }
    .header-top-in {
      height: 100%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1.2rem; }
    .header-top-it {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center; }
      .header-top-it .link {
        gap: .8rem;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        color: #ffffff;
        font-weight: normal; }
      .header-top-it .icon {
        --wicon: 2rem;
        width: var(--wicon);
        height: var(--wicon); }
        .header-top-it .icon img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block; }
    .header-top-action {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      gap: 3.2rem;
      height: 100%; }
      @media screen and (max-width: 1200px) {
        .header-top-action .header-lg {
          display: none; } }
      .header-top-action .header-lg > .header-lg-it > .text {
        color: #ffffff !important; }
      .header-top-action .header-lg > .header-lg-it > i {
        color: #ffffff !important; }
      .header-top-action .header-lg-it {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: .2rem;
        position: relative;
        width: 100%;
        height: 100%;
        line-height: normal; }
        .header-top-action .header-lg-it .ic {
          --wh: 1.6rem;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          -ms-align-items: center;
          align-items: center;
          -webkit-align-items: center;
          justify-content: center;
          -webkit-justify-content: center;
          margin-top: .2rem;
          gap: .4rem;
          flex-shrink: 0;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          width: var(--wh);
          height: var(--wh);
          border-radius: 100rem;
          overflow: hidden; }
          .header-top-action .header-lg-it .ic img {
            width: 100%;
            height: 100%;
            object-fit: cover; }
        .header-top-action .header-lg-it .text {
          font-size: 1.4rem;
          font-weight: 300;
          font-family: var(--font-fur);
          color: #191919;
          font-weight: normal; }
        .header-top-action .header-lg-it i {
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out; }
      .header-top-action .header-lg-drop {
        width: 100%;
        position: absolute;
        top: 100%;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        right: 0;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        border: 0.1rem solid #ffffff;
        background-color: #ffffff;
        box-shadow: 0 0.4rem 0.6rem rgba(16, 24, 40, 0.1);
        border-radius: 0.4rem;
        opacity: 0;
        transform: translateY(-10px);
        visibility: hidden; }
        .header-top-action .header-lg-drop a span.ic {
          margin-right: 0 !important;
          flex-shrink: 0; }
        .header-top-action .header-lg-drop a .ic {
          flex-shrink: 0; }
        .header-top-action .header-lg-drop .header-lg-it {
          position: relative;
          width: fit-content;
          padding: .5rem;
          width: 100%;
          justify-content: flex-start; }
          .header-top-action .header-lg-drop .header-lg-it .text {
            transition: 0.3s ease-in-out;
            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            -ms-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out;
            font-weight: 300;
            white-space: nowrap; }
          .header-top-action .header-lg-drop .header-lg-it::before {
            position: absolute;
            content: '';
            top: calc(100% + .2rem);
            left: 0;
            width: 0;
            height: .1rem;
            background-color: #2E3192;
            transition: .3s ease; }
  .header-flex {
    height: var(--hd-bot);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    margin: auto;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
    @media screen and (min-width: 1201px) {
      .header-flex .header-lg {
        display: none; } }
    @media screen and (max-width: 1200px) {
      .header-flex {
        height: 100%; } }
    .header-flex-r {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      gap: 2rem; }
    .header-flex-in {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      margin: auto;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      height: 100%; }
  .header-wrap {
    height: 100%; }
  @media screen and (max-width: 1199px) {
    .header-nav {
      display: none; } }
  .header-logo {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: relative; }
    .header-logo a, .header-logo-link {
      display: flex;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      width: 10rem;
      max-height: 100%; }
      @media screen and (max-width: 1200px) {
        .header-logo a, .header-logo-link {
          width: 8rem; } }
      @media screen and (max-width: 600px) {
        .header-logo a, .header-logo-link {
          width: 7rem; } }
      .header-logo a img, .header-logo-link img {
        width: 100%;
        object-fit: contain;
        max-width: 100%;
        height: auto;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out; }
  .header-action {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    gap: 1rem; }
    @media screen and (max-width: 600px) {
      .header-action {
        gap: .6rem; } }
  .header-lg {
    position: relative;
    z-index: 20;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    width: 5rem;
    flex-shrink: 0;
    height: 100%; }
    .header-lg-it {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: .2rem;
      position: relative;
      width: 100%;
      height: 100%;
      line-height: normal; }
      .header-lg-it .ic {
        --wh: 1.6rem;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        margin-top: .2rem;
        gap: .4rem;
        flex-shrink: 0;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        width: var(--wh);
        height: var(--wh);
        border-radius: 100rem;
        overflow: hidden; }
        .header-lg-it .ic img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .header-lg-it .text {
        font-size: 1.4rem;
        font-weight: 300;
        font-family: var(--font-fur);
        color: #191919;
        font-weight: normal; }
      .header-lg-it i {
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out; }
    .header-lg-drop {
      width: 100%;
      position: absolute;
      top: 100%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      gap: .5rem;
      right: 0;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      border: 0.1rem solid #ffffff;
      background-color: #ffffff;
      box-shadow: 0 0.4rem 0.6rem rgba(16, 24, 40, 0.1);
      border-radius: 0.4rem;
      opacity: 0;
      transform: translateY(-10px);
      visibility: hidden; }
      .header-lg-drop a span.ic {
        margin-right: 0 !important;
        flex-shrink: 0; }
      .header-lg-drop a .ic {
        flex-shrink: 0; }
      .header-lg-drop .header-lg-it {
        position: relative;
        width: fit-content;
        padding: .5rem;
        width: 100%;
        justify-content: flex-start; }
        .header-lg-drop .header-lg-it .text {
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;
          font-weight: 300;
          white-space: nowrap; }
        .header-lg-drop .header-lg-it::before {
          position: absolute;
          content: '';
          top: calc(100% + .2rem);
          left: 0;
          width: 0;
          height: .1rem;
          background-color: #2E3192;
          transition: .3s ease; }
        .header-lg-drop .header-lg-it:hover::before {
          width: 100%;
          transition: .3s ease; }
    .header-lg:hover i {
      transform: rotate(180deg); }
    .header-lg:hover .header-lg-drop {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
      transition: .3s ease; }
  .header-search {
    position: relative;
    height: 100%;
    --wh: 4.8rem;
    flex-shrink: 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 1200px) {
      .header-search {
        --wh: 3.8rem; } }
    .header-search:hover::after {
      opacity: 1;
      visibility: visible; }
    .header-search .active .header-search-close {
      opacity: 1;
      visibility: visible; }
    .header-search-icon {
      width: var(--wh);
      height: var(--wh);
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      color: #191919;
      background-color: #EEEDED;
      border-radius: 100rem;
      cursor: pointer; }
      .header-search-icon img {
        width: calc(var(--wh)/3);
        height: calc(var(--wh)/3);
        object-fit: contain;
        display: block; }
    .header-search-overlay {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 0;
      z-index: 150;
      backdrop-filter: blur(0.7rem);
      background-color: rgba(0, 0, 0, 0.5);
      visibility: hidden;
      opacity: 0;
      transition: .3s ease; }
      .header-search-overlay.active {
        width: 100%;
        right: 0;
        visibility: visible;
        opacity: 1;
        transition: .3s ease; }
    .header-search-bar {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 150;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      clip-path: inset(0 100% 0 0); }
      .header-search-bar.active {
        transition: .3s ease;
        clip-path: inset(0 0 0 0);
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out; }
        .header-search-bar.active .link,
        .header-search-bar.active .input {
          transition-delay: 1s;
          transform: translateY(0);
          opacity: 1;
          visibility: visible; }
        .header-search-bar.active .header-search-bar-input::before {
          width: 100%;
          transition-delay: .5s; }
      .header-search-bar-input {
        width: 60vw;
        margin: auto;
        position: relative;
        background-color: transparent;
        height: 4rem;
        overflow: hidden;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        font-weight: normal;
        font-weight: 500; }
        @media screen and (max-width: 500px) {
          .header-search-bar-input {
            width: 85vw; } }
        .header-search-bar-input #searchform {
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          -ms-align-items: center;
          align-items: center;
          -webkit-align-items: center;
          justify-content: center;
          -webkit-justify-content: center;
          width: 100%; }
        .header-search-bar-input::before {
          position: absolute;
          content: "";
          left: 0;
          bottom: 0;
          width: 0;
          height: 0.1rem;
          background-color: #ffffff;
          transition: 1s; }
        .header-search-bar-input .icon {
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          -ms-align-items: center;
          align-items: center;
          -webkit-align-items: center;
          justify-content: center;
          -webkit-justify-content: center; }
        .header-search-bar-input .input {
          padding: 0 1.5rem 0 0;
          transform: translateY(2rem);
          opacity: 0;
          visibility: hidden;
          transition: 1.5s;
          font-family: var(--font-pri);
          font-weight: normal;
          color: #ffffff;
          background-color: transparent;
          height: 100%;
          width: 100%;
          border: none;
          outline: none;
          font-size: 1.6rem;
          font-weight: 500; }
          .header-search-bar-input .input::placeholder {
            color: #ffffff; }
          @media screen and (max-width: 500px) {
            .header-search-bar-input .input {
              font-size: 1.4rem; } }
        .header-search-bar-input .link {
          cursor: pointer;
          --wh: 4rem;
          width: var(--wh);
          height: var(--wh) !important;
          flex-shrink: 0;
          padding: 0;
          height: 100%;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          -ms-align-items: center;
          align-items: center;
          -webkit-align-items: center;
          justify-content: center;
          -webkit-justify-content: center;
          color: #ffffff;
          background-color: transparent;
          border: none;
          transition: 2s;
          transform: translateX(1rem);
          opacity: 0;
          visibility: hidden; }
          .header-search-bar-input .link .icon {
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            -ms-align-items: center;
            align-items: center;
            -webkit-align-items: center;
            justify-content: center;
            -webkit-justify-content: center; }
          .header-search-bar-input .link::before {
            display: none; }
          .header-search-bar-input .link:hover i {
            color: #ffffff; }
    .header-search-close {
      --w: 3.4rem;
      width: var(--w) !important;
      height: var(--w);
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      z-index: 150;
      position: fixed !important;
      color: #ffffff;
      right: 20%;
      top: 5rem;
      font-size: 3rem;
      border-radius: 100rem !important;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      opacity: 0;
      visibility: hidden;
      display: none; }
      @media only screen and (max-width: 1200px) {
        .header-search-close {
          font-size: 2.7rem; } }
      @media only screen and (max-width: 800px) {
        .header-search-close {
          font-size: 2.4rem; } }
      @media screen and (max-width: 600px) {
        .header-search-close {
          right: 2rem;
          top: 2rem; } }
      .header-search-close.active {
        opacity: 1;
        visibility: visible;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center; }
      .header-search-close:hover i {
        transform: rotate(360deg); }
      .header-search-close i {
        transition: all .3s ease; }
  .header .menu-icon {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    @media screen and (min-width: 1200px) {
      .header .menu-icon {
        display: none; } }

.footer {
  position: relative;
  overflow: hidden;
  padding: 3.2rem 0; }
  @media screen and (max-width: 1200px) {
    .footer {
      padding: 1.6rem 0;
      margin-top: 1.6rem; } }
  .footer-col {
    width: 20%; }
    @media screen and (max-width: 1000px) {
      .footer-col {
        width: calc(4/12 * 100%); } }
    @media screen and (max-width: 700px) {
      .footer-col {
        width: calc(6/12 * 100%); } }
    @media screen and (max-width: 400px) {
      .footer-col {
        width: 100%; } }
    .footer-col .item {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: flex-start;
      gap: .4rem;
      font-size: 1.6rem;
      font-weight: 400;
      color: #6F6D6D; }
      @media screen and (max-width: 800px) {
        .footer-col .item {
          font-size: 1.4rem; } }
      .footer-col .item .icon {
        width: 2rem;
        height: 2rem;
        flex-shrink: 0;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        margin-top: .2rem; }
        .footer-col .item .icon img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block; }
  .footer-top {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    margin-bottom: 6.4rem; }
    @media screen and (max-width: 1200px) {
      .footer-top {
        margin-bottom: 3.2rem; } }
  .footer-main {
    border-bottom: 0.1rem solid #E5E7EB;
    padding-bottom: 6.4rem; }
    @media screen and (max-width: 1200px) {
      .footer-main {
        padding-bottom: 3.2rem; } }
  .footer-author .list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
  .footer-author .item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    gap: 1rem;
    font-size: 1.4rem;
    font-family: "Mulish";
    font-weight: normal;
    color: #6F6D6D; }
    .footer-author .item img {
      height: 1.1rem; }
  .footer-logo {
    width: 15rem; }
    .footer-logo-link {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      width: 100%; }
      .footer-logo-link img {
        width: 100%;
        object-fit: contain;
        display: block; }
  .footer-title {
    font-size: 1.6rem;
    color: #2E3192;
    text-wrap: pretty; }
    @media screen and (max-width: 500px) {
      .footer-title {
        margin-bottom: 1rem !important;
        font-size: 1.4rem; } }
  .footer-social {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    margin-bottom: 3.2rem; }
    @media screen and (max-width: 1200px) {
      .footer-social {
        margin-bottom: 1.6rem; } }
    .footer-social-list {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      gap: 1.6rem; }
    .footer-social-item {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      flex-shrink: 0;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }
      .footer-social-item:hover {
        transform: translateY(-0.3rem); }
    .footer-social-link {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 0.4rem;
      overflow: hidden;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center; }
      @media screen and (max-width: 600px) {
        .footer-social-link {
          width: 2.8rem;
          height: 2.8rem; } }
      .footer-social-link img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block; }

.hd-burger {
  padding: 1rem 0;
  cursor: pointer; }
  @media screen and (min-width: 1201px) {
    .hd-burger {
      display: none; } }
  .hd-burger.active .line:nth-child(1) {
    -webkit-transform: translate3d(0, 0.3rem, 0) rotate(45deg) !important;
    transform: translate3d(0, 0.3rem, 0) rotate(45deg) !important; }
  .hd-burger.active .line:nth-child(2) {
    -webkit-transform: translate3d(0, -0.4rem, 0) rotate(135deg) !important;
    transform: translate3d(0, -0.4rem, 0) rotate(135deg) !important;
    width: 2.4rem; }

.burger {
  position: relative; }

.hamburger .line {
  width: 2.4rem;
  height: 0.2rem;
  border-radius: .4rem;
  background: #191919;
  display: block;
  margin: 0.5rem 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }
  .hamburger .line:nth-child(2) {
    width: 1.8rem;
    margin-left: auto; }

.select2-container {
  width: 100% !important; }
  .select2-container--default .select2-results__option--selected {
    background-color: #ffffff;
    color: #ffffff; }
  .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #2E3192;
    color: #ffffff; }

.select2-selection {
  height: 100% !important; }
  .select2-selection--single {
    border: 0.1rem solid #E5E7EB !important; }
    .select2-selection--single .select2-selection__rendered {
      line-height: 100% !important;
      padding: 1rem 1.4rem; }
    .select2-selection--single .select2-selection__placeholder {
      color: #191919 !important;
      font-family: var(--font-fiv);
      font-size: 1.6rem;
      font-weight: 500; }
  .select2-selection .select2-selection__arrow {
    height: 100% !important; }

.daterangepicker {
  z-index: 10 !important; }

.thumb {
  position: relative;
  overflow: hidden; }
  .thumb-wr {
    position: relative;
    z-index: 1; }
    .thumb-wr .gr-btn {
      width: fit-content; }
  .thumb-cloud {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
    .thumb-cloud .cloud {
      position: absolute;
      width: 20%;
      animation-duration: 10s;
      animation-name: cloudMove, cloudFade;
      animation-direction: normal;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: both; }
      .thumb-cloud .cloud:nth-child(1) {
        top: 5%; }
      .thumb-cloud .cloud:nth-child(2) {
        top: 10%;
        animation-duration: 10.5s;
        animation-delay: 2.5s;
        transform: scaleX(0.5) scaleY(0.5); }
      .thumb-cloud .cloud:nth-child(3) {
        top: 7%;
        animation-duration: 8s;
        animation-delay: 3.5s;
        transform: scaleX(0.8) scaleY(0.8); }
      .thumb-cloud .cloud:nth-child(4) {
        display: none; }
      .thumb-cloud .cloud:nth-child(5) {
        display: none; }
  .thumb-bg {
    position: absolute;
    z-index: -2;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0; }
    .thumb-bg::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 1;
      background: linear-gradient(0deg, rgba(232, 232, 232, 0.2) 0%, rgba(232, 232, 232, 0.2) 100%), linear-gradient(96deg, rgba(211, 211, 211, 0.2) 5.05%, rgba(255, 255, 255, 0.2) 97.32%); }
    .thumb-bg img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block; }
  .thumb-des {
    padding: 20rem 0;
    width: 50%; }
    @media screen and (max-width: 1200px) {
      .thumb-des {
        padding: 15rem 0; } }
    @media screen and (max-width: 900px) {
      .thumb-des {
        padding: 10rem 0;
        width: 70%; } }
    @media screen and (max-width: 700px) {
      .thumb-des {
        padding: 5rem 0; } }
    @media screen and (max-width: 400px) {
      .thumb-des {
        width: 100%; } }
    .thumb-des .des {
      padding: 1rem 1rem 1rem 1.6rem;
      border-radius: 0 0.6rem 0.6rem 0;
      backdrop-filter: blur(0.2rem);
      background-color: rgba(255, 255, 255, 0.1); }
    .thumb-des .title-second {
      margin-bottom: 1.2rem; }
    .thumb-des .title-sub {
      text-align: left;
      margin-bottom: 3.2rem; }
      @media screen and (max-width: 1200px) {
        .thumb-des .title-sub {
          margin-bottom: 1rem; } }

.hover_xam {
  display: block;
  position: relative;
  overflow: hidden; }
  .hover_xam::before {
    content: "";
    background: rgba(255, 255, 255, 0.5);
    bottom: 0;
    left: 0;
    position: absolute;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -khtml-opacity: 1;
    -o-opacity: 1;
    -ms-opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    width: 0;
    height: 0;
    z-index: 1;
    pointer-events: none; }
  .hover_xam::after {
    content: "";
    background: rgba(255, 255, 255, 0.5);
    top: 0;
    right: 0;
    position: absolute;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    -khtml-opacity: 1;
    -o-opacity: 1;
    -ms-opacity: 1;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    width: 0;
    height: 0;
    z-index: 2;
    pointer-events: none; }
  .hover_xam:hover::before {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transition-duration: 1.3s;
    -moz-transition-duration: 1.3s;
    -ms-transition-duration: 1.3s;
    -o-transition-duration: 1.3s;
    transition-duration: 1.3s;
    -khtml-opacity: 0;
    -o-opacity: 0;
    -ms-opacity: 0;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    height: 100%;
    width: 100%; }
  .hover_xam:hover::after {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transition-duration: 1.3s;
    -moz-transition-duration: 1.3s;
    -ms-transition-duration: 1.3s;
    -o-transition-duration: 1.3s;
    transition-duration: 1.3s;
    -khtml-opacity: 0;
    -o-opacity: 0;
    -ms-opacity: 0;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    height: 100%;
    width: 100%; }

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  visibility: hidden;
  pointer-events: none; }
  .popup.open {
    visibility: visible;
    pointer-events: visible; }
    .popup.open .popup-overlay {
      opacity: 1;
      visibility: visible;
      height: 100%; }
    .popup.open .popup-main {
      opacity: 1;
      visibility: visible;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      transition-delay: 0.1s;
      -webkit-transition-delay: 0.1s;
      -moz-transition-delay: 0.1s;
      -ms-transition-delay: 0.1s;
      -o-transition-delay: 0.1s;
      clip-path: polygon();
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -moz-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -ms-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      -o-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }
  .popup-overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
  .popup-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 70rem;
    max-width: 95%;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    clip-path: polygon();
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    -moz-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    -ms-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    -o-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0); }
    .popup-main-wrapper {
      overflow: hidden;
      border-radius: 6px; }
  .popup-over {
    overflow: hidden auto;
    min-height: 50vh;
    max-height: 80vh; }
  .popup-wrapper {
    background-color: white;
    width: 100%;
    min-height: 60vh; }
  .popup-title {
    font-size: 2.4rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.6rem; }
    @media only screen and (max-width: 1200px) {
      .popup-title {
        font-size: 2rem; } }
    @media only screen and (max-width: 800px) {
      .popup-title {
        font-size: 1.7rem; } }
  .popup-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
    transition: 0.6s ease-in-out;
    width: 3rem;
    height: 3rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    z-index: 3; }
    .popup-close:hover .icon {
      transform: rotate(360deg); }
    .popup-close .icon {
      color: #191919;
      font-weight: 300;
      font-size: 2.4rem;
      transition: 0.6s ease-in-out;
      -webkit-transition: 0.6s ease-in-out;
      -moz-transition: 0.6s ease-in-out;
      -ms-transition: 0.6s ease-in-out;
      -o-transition: 0.6s ease-in-out;
      transition-delay: 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -o-transition-delay: 0s; }

.Pd-body {
  border-radius: 0.8rem;
  border: 0.1rem solid #E2E8F0;
  background: #ffffff;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1), 0px 4px 6px -1px rgba(16, 24, 40, 0.1); }

.Pd-inner {
  padding: 2.8rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  gap: 2rem; }
  @media screen and (max-width: 800px) {
    .Pd-inner {
      gap: 1rem; } }
  .Pd-inner .gr-btn {
    width: 100%; }
    .Pd-inner .gr-btn .btn-sec {
      width: 100% !important;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center; }

.Pd-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  gap: 2rem; }
  @media screen and (max-width: 800px) {
    .Pd-list {
      gap: 1rem; } }

.banner {
  position: relative; }
  .banner .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1; }
    .banner .bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .banner-ctn {
    min-height: calc(100vw*(200/1728));
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding: 4rem 0; }
  .banner .title-pri {
    line-height: 150%;
    text-transform: capitalize; }
  .banner .breadcrumb .container {
    padding: 0; }
  .banner .breadcrumb-item {
    color: white; }
    .banner .breadcrumb-item:last-child {
      color: white; }

.back-to-top {
  --wh: 5rem;
  width: var(--wh);
  height: var(--wh);
  border-radius: 100rem;
  background-color: #ffffff;
  border: 0.1rem solid #2E3192;
  position: fixed;
  bottom: 6rem;
  right: 3rem;
  z-index: 7;
  transform: translateX(3rem);
  opacity: 0;
  transition: 0.5s linear;
  cursor: pointer;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; }
  @media screen and (max-width: 1200px) {
    .back-to-top {
      --wh: 4rem; } }
  .back-to-top:hover {
    background-color: #2E3192;
    color: #ffffff; }
  .back-to-top i {
    font-size: 2rem; }
    @media only screen and (max-width: 1200px) {
      .back-to-top i {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .back-to-top i {
        font-size: 1.6rem; } }
  @media screen and (max-width: 768px) {
    .back-to-top {
      right: 1.5rem; } }
  .back-to-top.active {
    visibility: visible;
    transform: translateX(0);
    opacity: 1; }
  .back-to-top .triangle {
    display: block;
    width: 0;
    height: 0;
    z-index: 2;
    border: 8px solid transparent;
    border-bottom: 6px solid #fff;
    margin-top: -6px;
    border-radius: 5px; }
    .back-to-top .triangle:nth-child(1) {
      animation: BTT 1.5s linear 0.6s infinite; }
    .back-to-top .triangle:nth-child(2) {
      animation: BTT 1.5s linear 0.3s infinite; }
    .back-to-top .triangle:nth-child(3) {
      animation: BTT 1.5s linear 0s infinite; }
  @media only screen and (min-width: 901px) {
    .back-to-top:hover {
      visibility: visible;
      opacity: 1;
      transform: translateX(0); } }

.btn-fct {
  position: fixed;
  bottom: 13rem;
  right: 3rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  z-index: 10;
  width: 5rem; }
  @media screen and (max-width: 1200px) {
    .btn-fct {
      width: 4rem;
      gap: 2.5rem;
      bottom: 12rem; } }
  @media screen and (max-width: 768px) {
    .btn-fct {
      right: 1.5rem; } }
  .btn-fct-icon {
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    .btn-fct-icon .icon {
      border-radius: 100%;
      width: 3rem;
      height: 3rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      animation: phone-fn 2s infinite ease-in-out; }
    .btn-fct-icon img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block; }
    .btn-fct-icon:nth-child(1) .icon {
      background-color: #F1B650; }
    .btn-fct-icon:nth-child(1)::before {
      background-color: #F1B650; }
    .btn-fct-icon:nth-child(1)::after {
      background-color: #F1B650; }
    .btn-fct-icon:nth-child(2) .icon, .btn-fct-icon:nth-child(3) .icon {
      background-color: #ffffff; }
    .btn-fct-icon:nth-child(2)::before, .btn-fct-icon:nth-child(3)::before {
      background-color: #2E3192;
      opacity: .1; }
    .btn-fct-icon:nth-child(2)::after, .btn-fct-icon:nth-child(3)::after {
      background-color: #2E3192;
      opacity: .2; }
    .btn-fct-icon::before {
      content: '';
      position: absolute;
      border-radius: 100rem;
      position: absolute;
      left: -.5rem;
      bottom: -.5rem;
      right: -.5rem;
      top: -.5rem;
      animation: pin 4s linear 0.4s infinite normal;
      z-index: -1; }
    .btn-fct-icon::after {
      content: '';
      position: absolute;
      border-radius: 100rem;
      position: absolute;
      left: -1rem;
      bottom: -1rem;
      right: -1rem;
      top: -1rem;
      animation: pin 4s linear 0.4s infinite normal;
      z-index: -1; }

@media screen and (min-width: 1200px) {
  .breadcrumb-wrapper {
    width: fit-content; } }

.breadcrumb-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  gap: .8rem; }

.breadcrumb-item {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  gap: .8rem;
  font-weight: 400;
  font-family: var(--font-pri);
  color: #ffffff;
  text-transform: capitalize; }
  .breadcrumb-item:not(:first-child)::before {
    display: inline-block;
    content: '/';
    vertical-align: middle;
    font-weight: 400; }
  .breadcrumb-item:last-child {
    pointer-events: none; }
  @media (hover: hover) {
    .breadcrumb-item:hover {
      color: #ffffff; } }

.breadcrumb-link {
  color: inherit;
  transition: .2s ease-in-out; }

.btn-pri {
  position: relative;
  background-color: #2E3192;
  border: 0.1rem solid #2E3192;
  color: #ffffff;
  border-radius: 0.4rem;
  overflow: hidden;
  transition: all .3s ease;
  z-index: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  gap: 1.2rem;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  font-family: var(--font-pri);
  font-weight: 600;
  font-size: 1.6rem; }
  @media screen and (max-width: 1000px) {
    .btn-pri {
      gap: .6rem; } }
  @media screen and (max-width: 600px) {
    .btn-pri {
      font-size: 1.4rem; } }
  .btn-pri::before {
    position: absolute;
    content: '';
    right: 0;
    width: 0;
    top: 0;
    bottom: 0;
    background-color: #ffffff;
    transition: all .3s ease;
    z-index: -1; }
  .btn-pri .icon {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 100rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    position: relative; }
    .btn-pri .icon i {
      transform: translateX(-0.5rem);
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }
    .btn-pri .icon::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-width: .3rem;
      border-style: solid;
      border-left: #ffffff;
      border-top: #ffffff;
      border-bottom: #ffffff;
      border-radius: 100rem;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }
  .btn-pri img {
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
  .btn-pri:hover {
    color: #2E3192;
    border: 0.1rem solid #2E3192; }
    .btn-pri:hover .icon i {
      transform: translate(0.5rem); }
    .btn-pri:hover .icon::before {
      transform: rotate(180deg); }
    .btn-pri:hover img {
      filter: brightness(0) saturate(100%) invert(22%) sepia(40%) saturate(3648%) hue-rotate(339deg) brightness(87%) contrast(114%); }
    .btn-pri:hover::before {
      left: 0;
      width: 100%;
      background-color: #ffffff; }

.btn-sec {
  position: relative;
  background-color: #F1B650;
  border: 0.1rem solid #F1B650;
  color: #191919;
  border-radius: 0.4rem;
  overflow: hidden;
  transition: all .3s ease;
  z-index: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  gap: 1.2rem;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  font-family: var(--font-pri);
  font-weight: 600;
  font-size: 1.6rem; }
  @media screen and (max-width: 1000px) {
    .btn-sec {
      gap: .6rem; } }
  @media screen and (max-width: 600px) {
    .btn-sec {
      font-size: 1.4rem; } }
  .btn-sec::before {
    position: absolute;
    content: '';
    right: 0;
    width: 0;
    top: 0;
    bottom: 0;
    background-color: #ffffff;
    transition: all .3s ease;
    z-index: -1; }
  .btn-sec .icon {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 100rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    position: relative; }
    .btn-sec .icon i {
      transform: translateX(-0.5rem);
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }
    .btn-sec .icon::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-width: .3rem;
      border-style: solid;
      border-left: #ffffff;
      border-top: #ffffff;
      border-bottom: #ffffff;
      border-radius: 100rem;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }
  .btn-sec img {
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
  .btn-sec:hover {
    color: #F1B650;
    border: 0.1rem solid #F1B650; }
    .btn-sec:hover .icon i {
      transform: translate(0.5rem); }
    .btn-sec:hover .icon::before {
      transform: rotate(180deg); }
    .btn-sec:hover img {
      filter: brightness(0) saturate(100%) invert(25%) sepia(80%) saturate(1381%) hue-rotate(190deg) brightness(97%) contrast(92%); }
    .btn-sec:hover::before {
      left: 0;
      width: 100%;
      background-color: #ffffff; }

.btn-white {
  position: relative;
  background-color: #ffffff;
  border: 0.1rem solid #2E3192;
  color: #2E3192;
  border-radius: 0.4rem;
  overflow: hidden;
  transition: all .3s ease;
  z-index: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  gap: 1.2rem;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  font-family: var(--font-pri);
  font-weight: 600;
  font-size: 1.6rem; }
  @media screen and (max-width: 1000px) {
    .btn-white {
      gap: .6rem; } }
  @media screen and (max-width: 600px) {
    .btn-white {
      font-size: 1.4rem; } }
  .btn-white::before {
    position: absolute;
    content: '';
    right: 0;
    width: 0;
    top: 0;
    bottom: 0;
    background-color: #2E3192;
    transition: all .3s ease;
    z-index: -1; }
  .btn-white .icon {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 100rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    position: relative; }
    .btn-white .icon i {
      transform: translateX(-0.5rem);
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }
    .btn-white .icon::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-width: .3rem;
      border-style: solid;
      border-left: #ffffff;
      border-top: #ffffff;
      border-bottom: #ffffff;
      border-radius: 100rem;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }
  .btn-white img {
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
  .btn-white:hover {
    color: #ffffff; }
    .btn-white:hover .icon i {
      transform: translate(0.5rem); }
    .btn-white:hover .icon::before {
      transform: rotate(180deg); }
    .btn-white:hover img {
      filter: brightness(0) saturate(100%) invert(25%) sepia(80%) saturate(1381%) hue-rotate(190deg) brightness(97%) contrast(92%); }
    .btn-white:hover::before {
      left: 0;
      width: 100%; }

.btn-pad-pri {
  padding: 1.2rem 2.4rem; }
  @media screen and (max-width: 800px) {
    .btn-pad-pri {
      padding: .9rem 1.8rem; } }

.btn-rad-1 {
  border-radius: 0.4rem; }

.btn-rad-2 {
  border-radius: 0.6rem; }

.btn-rad-3 {
  border-radius: 0.8rem; }

.btn-rad-4 {
  border-radius: 1rem; }

.btn-rad-5 {
  border-radius: 1.2rem; }

.btn-rad-6 {
  border-radius: 1.6rem; }

.btn-rad-7 {
  border-radius: 2.4rem; }

.btn-rad-8 {
  border-radius: 3.2rem; }

.btn-rad-9 {
  border-radius: 100rem; }

.btn-rowR {
  flex-direction: row-reverse; }
  .btn-rowR .icon i {
    transform: translateX(0);
    transition: all 0s ease; }
  .btn-rowR:hover .icon i {
    transform: translateX(0); }

.btn-nonBf .icon::before {
  display: none; }

.btn-center {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center; }

.page-numbers {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  margin: 1.6rem 0; }
  .page-numbers > li:not(:first-child) {
    margin-left: .2rem; }
  .page-numbers > li > .page-numbers {
    --wh: 4rem;
    width: var(--wh);
    height: var(--wh);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    color: #191919;
    font-size: 1.4rem;
    font-weight: 500;
    font-family: var(--font-sec);
    transition: all 0.4s ease;
    border-radius: 0.6rem; }
    .page-numbers > li > .page-numbers::after {
      position: absolute;
      content: "";
      width: 0;
      height: 100%;
      top: 0;
      right: 0;
      background: #2E3192;
      transition: all 0.4s ease;
      z-index: 1;
      pointer-events: none; }
    .page-numbers > li > .page-numbers:after {
      z-index: -1; }
    @media screen and (max-width: 1200px) {
      .page-numbers > li > .page-numbers {
        --wh: 3rem;
        font-size: 1.4rem; } }
    @media (hover: hover) {
      .page-numbers > li > .page-numbers:hover:not(.dot) {
        color: #ffffff;
        background-color: #2E3192; }
        .page-numbers > li > .page-numbers:hover:not(.dot)::after {
          left: 0;
          width: 100%; } }
    .page-numbers > li > .page-numbers.current {
      color: #ffffff;
      background-color: #2E3192; }
      .page-numbers > li > .page-numbers.current::after {
        left: 0;
        width: 100%; }
    .page-numbers > li > .page-numbers.dot {
      background-color: transparent;
      border: transparent !important; }
    .page-numbers > li > .page-numbers.next {
      margin-left: 1rem; }
      @media screen and (max-width: 800px) {
        .page-numbers > li > .page-numbers.next {
          margin-left: .5rem; } }
    .page-numbers > li > .page-numbers.prev {
      margin-right: 1rem; }
      @media screen and (max-width: 800px) {
        .page-numbers > li > .page-numbers.prev {
          margin-right: .5rem; } }
    .page-numbers > li > .page-numbers.next, .page-numbers > li > .page-numbers.prev {
      border: none;
      border-radius: 0.4rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      position: relative;
      color: #191919; }
    .page-numbers > li > .page-numbers i {
      font-weight: 400; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-2 {
  margin-bottom: 2px; }

.mb-4 {
  margin-bottom: 4px; }

.mb-6 {
  margin-bottom: 6px; }

.mb-8 {
  margin-bottom: 8px; }

.mb-10 {
  margin-bottom: 10px; }

.mb-10s:not(:last-child) {
  margin-bottom: 10px; }

.mb-12 {
  margin-bottom: 12px; }

.mb-12s:not(:last-child) {
  margin-bottom: 12px; }

.mb-14 {
  margin-bottom: 14px; }

.mb-14s:not(:last-child) {
  margin-bottom: 14px; }

.mb-15 {
  margin-bottom: 15px; }

.mb-15s:not(:last-child) {
  margin-bottom: 15px; }

.mb-16 {
  margin-bottom: 16px; }

.mb-16s:not(:last-child) {
  margin-bottom: 16px; }

.mb-18 {
  margin-bottom: 18px; }

.mb-18s:not(:last-child) {
  margin-bottom: 18px; }

.mb-20 {
  margin-bottom: 20px; }
  .mb-20 .mb-30 {
    margin-bottom: 30px; }
  .mb-20 .mb-30s:not(:last-child) {
    margin-bottom: 30px; }

.mb-20s:not(:last-child) {
  margin-bottom: 20px; }

.mb-24 {
  margin-bottom: 24px; }

.mb-24s:not(:last-child) {
  margin-bottom: 24px; }

.mb-25 {
  margin-bottom: 25px; }

.mb-25s:not(:last-child) {
  margin-bottom: 25px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-30s:not(:last-child) {
  margin-bottom: 30px; }

.mb-32 {
  margin-bottom: 32px; }
  @media screen and (max-width: 1200px) {
    .mb-32 {
      margin-bottom: 1.6rem; } }

.mb-32s:not(:last-child) {
  margin-bottom: 32px; }
  @media screen and (max-width: 1200px) {
    .mb-32s:not(:last-child) {
      margin-bottom: 1.6rem; } }

.mb-40 {
  margin-bottom: 40px; }
  @media screen and (max-width: 1200px) {
    .mb-40 {
      margin-bottom: 2rem; } }

.mb-40s:not(:last-child) {
  margin-bottom: 40px; }
  @media screen and (max-width: 1200px) {
    .mb-40s:not(:last-child) {
      margin-bottom: 2rem; } }

.mb-48 {
  margin-bottom: 48px; }
  @media screen and (max-width: 1200px) {
    .mb-48 {
      margin-bottom: 2.4rem; } }

.mb-48s:not(:last-child) {
  margin-bottom: 48px; }

.mb-56 {
  margin-bottom: 56px; }
  @media screen and (max-width: 1200px) {
    .mb-56 {
      margin-bottom: 2.8rem; } }

.mb-56s:not(:last-child) {
  margin-bottom: 56px; }
  @media screen and (max-width: 1200px) {
    .mb-56s:not(:last-child) {
      margin-bottom: 2.8rem; } }

.mb-60 {
  margin-bottom: 6rem; }
  @media screen and (max-width: 1200px) {
    .mb-60 {
      margin-bottom: 3rem; } }

.mb-64 {
  margin-bottom: 64px; }
  @media screen and (max-width: 1200px) {
    .mb-64 {
      margin-bottom: 3.2rem; } }

.mb-64s:not(:last-child) {
  margin-bottom: 64px; }
  @media screen and (max-width: 1200px) {
    .mb-64s:not(:last-child) {
      margin-bottom: 3.2rem; } }

.mb-72 {
  margin-bottom: 72px; }
  @media screen and (max-width: 1200px) {
    .mb-72 {
      margin-bottom: 3.6rem; } }

.mb-72s:not(:last-child) {
  margin-bottom: 72px; }
  @media screen and (max-width: 1200px) {
    .mb-72s:not(:last-child) {
      margin-bottom: 3.6rem; } }

.mb-80 {
  margin-bottom: 80px; }
  @media screen and (max-width: 1200px) {
    .mb-80 {
      margin-bottom: 4rem; } }

.mb-80s:not(:last-child) {
  margin-bottom: 80px; }
  @media screen and (max-width: 1200px) {
    .mb-80s:not(:last-child) {
      margin-bottom: 4rem; } }

.ss-mg {
  margin: 80px 0; }
  @media screen and (max-width: 1200px) {
    .ss-mg {
      margin: 40px 0; } }

.ss-mg-t {
  margin-top: 80px; }
  @media screen and (max-width: 1200px) {
    .ss-mg-t {
      margin-top: 40px; } }

.ss-mg-t-40 {
  margin-top: 40px; }
  @media screen and (max-width: 1200px) {
    .ss-mg-t-40 {
      margin-top: 20px; } }

.ss-mg-b {
  margin-bottom: 80px; }
  @media screen and (max-width: 1200px) {
    .ss-mg-b {
      margin-bottom: 40px; } }

.pb-20 {
  padding-bottom: 2rem; }

.mg-40 {
  margin: 4rem 0; }

.mg-20 {
  margin: 2rem 0; }

.gl-tag {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; }
  .gl-tag .txt {
    width: fit-content;
    padding: .4rem .8rem;
    font-size: 1.4rem;
    font-family: var(--font-pri);
    font-weight: normal;
    background-color: #EAEAF4; }
  .gl-tag.pri .txt {
    color: #2E3192; }
  .gl-tag.sec .txt {
    color: #4D4D4D; }

.gl-btn {
  position: relative;
  cursor: pointer;
  background: transparent;
  width: max-content;
  height: auto;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  z-index: 1; }
  .gl-btn::before {
    position: absolute;
    content: '';
    background: #2E3192;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2.4rem;
    height: 100%;
    flex-shrink: 0;
    border-radius: 100rem;
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    z-index: -1; }
  .gl-btn:hover::before {
    width: 100%; }
  .gl-btn:hover .circle .arrow {
    background: #ffffff;
    transform: translate(1rem, 0); }
  .gl-btn:hover .text {
    color: #ffffff; }
  .gl-btn .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-shrink: 0;
    margin: 0;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 100rem; }
  .gl-btn .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #ffffff; }
    .gl-btn .icon.arrow {
      transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
      left: .4rem;
      width: 1.125rem;
      height: 0.2rem;
      background: none; }
      .gl-btn .icon.arrow::before {
        position: absolute;
        content: "";
        top: -0.29rem;
        right: 0.0625rem;
        width: 0.625rem;
        height: 0.625rem;
        border-top: 0.2rem solid #ffffff;
        border-right: 0.2rem solid #ffffff;
        transform: rotate(45deg); }
  .gl-btn .text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    color: #191919;
    font-weight: 500;
    font-family: var(--font-pri);
    font-size: 1.4rem;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    padding: 0 .8rem; }

.gr-tag {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  gap: 1.6rem; }
  .gr-tag .text {
    font-size: 1.6rem;
    color: #191919;
    font-weight: 400; }
  .gr-tag .tag-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    gap: 1.2rem; }

.gr-btn {
  width: 100%; }
  .gr-btn.center {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    width: fit-content;
    margin: auto; }
  .gr-btn .btn-sec {
    width: 100%; }

.swiper-slide {
  height: auto; }

.swiper-pagination {
  position: static;
  margin-top: 2rem; }
  .swiper-pagination-bullet {
    --circle: -0.6rem;
    background-color: #9A9999;
    width: 1.2rem;
    height: 1.2rem;
    opacity: .6;
    position: relative;
    margin: 0 0.3rem !important;
    transition: 0.4s;
    border-radius: 100rem; }
    .swiper-pagination-bullet::before {
      content: '';
      position: absolute;
      top: var(--circle);
      right: var(--circle);
      bottom: var(--circle);
      left: var(--circle);
      border: 1px solid #2E3192;
      border-radius: 100rem;
      opacity: 0;
      transition: 0.1s ease-in-out;
      display: none; }
    .swiper-pagination-bullet-active {
      background-color: #2E3192;
      width: 4rem;
      opacity: 1; }
      .swiper-pagination-bullet-active::before {
        opacity: 1; }

.swiper-next {
  right: calc(-1 * var(--wh)*1.5); }
  .swiper-next::after {
    left: 0; }
  .swiper-next:hover::after {
    transform: translateX(100%); }

.swiper-prev {
  left: calc(-1 * var(--wh)*1.5); }
  .swiper-prev::after {
    right: 0; }
  .swiper-prev:hover::after {
    transform: translateX(-100%); }

.swiper-next, .swiper-prev {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  --wh: 4.8rem;
  width: var(--wh);
  height: var(--wh);
  flex-shrink: 0;
  border-radius: 100rem;
  position: absolute;
  background-color: #F5F6F7;
  overflow: hidden;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  color: #9A9999;
  z-index: 1;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out; }
  .swiper-next:hover, .swiper-prev:hover {
    color: #F1B650; }
    .swiper-next:hover::before, .swiper-prev:hover::before {
      border-color: #F1B650; }
  .swiper-next::before, .swiper-prev::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border: 0.2rem solid #9A9999;
    border-radius: 100rem;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
  .swiper-next::after, .swiper-prev::after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    width: 50%;
    background-color: #F5F6F7;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
  .swiper-next i, .swiper-prev i {
    font-size: 2rem;
    position: relative;
    z-index: 2; }

.itemIp {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  gap: .6rem;
  width: 100%; }
  .itemIp-lb {
    font-size: 1.6rem;
    font-weight: bold;
    font-family: var(--font-pri);
    color: #191919; }
  .itemIp-input {
    padding: 1.2rem 1.6rem;
    font-size: 1.6rem;
    font-weight: 600;
    font-family: var(--font-pri);
    color: #191919;
    width: 100%;
    border-radius: 0.8rem;
    border: 0.1rem solid #D0D5DD;
    background-color: #ffffff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); }
    .itemIp-input::placeholder {
      font-size: 1.6rem;
      font-weight: 600;
      font-family: var(--font-pri);
      color: #94A3B8; }

.home-bn {
  position: relative;
  overflow: hidden; }
  .home-bn-slider {
    position: relative; }
  .home-bn .swiper-pagination {
    position: absolute; }
    @media screen and (max-width: 1300px) {
      .home-bn .swiper-pagination {
        bottom: 8rem; } }
    @media screen and (max-width: 1000px) {
      .home-bn .swiper-pagination {
        bottom: 5rem; } }
    @media screen and (max-width: 600px) {
      .home-bn .swiper-pagination {
        bottom: 3rem; } }
  .home-bn .swiper-container {
    overflow: hidden; }
  .home-bn .swiper-slide .btn-sec {
    transition-delay: .1s; }
  .home-bn .swiper-slide-active .home-bn-content .title-second {
    transition-delay: .3s;
    transform: translateY(0);
    opacity: 1; }
  .home-bn .swiper-slide-active .home-bn-content .txt {
    transition-delay: .6s;
    transform: translateY(0);
    opacity: 1; }
  .home-bn .swiper-slide-active .home-bn-content .btn-sec {
    transition-delay: 1s;
    transform: translateY(0);
    opacity: 1; }
  .home-bn-it {
    height: 100%;
    position: relative;
    min-height: calc(814/1728 * 100vw); }
    .home-bn-it .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .home-bn-it .bg::before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%); }
      .home-bn-it .bg img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block; }
  .home-bn-content {
    position: relative;
    z-index: 2;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    height: 100%; }
    .home-bn-content .inner {
      width: 55rem;
      margin: auto;
      max-width: 100%; }
      @media screen and (max-width: 1000px) {
        .home-bn-content .inner {
          width: 80%; } }
      @media screen and (max-width: 700px) {
        .home-bn-content .inner {
          width: 100%; } }
    .home-bn-content .title-second {
      width: 44rem;
      max-width: 100%;
      margin: auto;
      margin-bottom: 1.2rem;
      transform: translateY(2rem);
      opacity: 0;
      transition: all .7s ease;
      overflow-wrap: break-word;
      font-size: 5.2rem; }
      @media only screen and (max-width: 1200px) {
        .home-bn-content .title-second {
          font-size: 4.5rem; } }
      @media only screen and (max-width: 800px) {
        .home-bn-content .title-second {
          font-size: 3.4rem; } }
    .home-bn-content .txt {
      font-family: var(--font-pri);
      color: #ffffff;
      font-weight: normal;
      margin-bottom: 3.2rem;
      transform: translateY(2rem);
      opacity: 0;
      transition: all .7s ease; }
      @media screen and (max-width: 700px) {
        .home-bn-content .txt {
          font-size: 1.4rem;
          margin-bottom: 1.4rem; } }
    .home-bn-content .btn-sec {
      font-family: var(--font-pri);
      font-size: 1.6rem;
      transform: translateY(2rem);
      opacity: 0;
      transition: all .7s ease; }

.home-wrk-wr {
  margin: 2rem 0;
  padding: 2rem;
  border-radius: 1rem;
  background-color: #ffffff; }
  @media screen and (max-width: 1000px) {
    .home-wrk-wr {
      padding: 1rem; } }
  .home-wrk-wr .post-pri-list {
    padding: 2rem 0; }
    @media screen and (max-width: 1000px) {
      .home-wrk-wr .post-pri-list {
        padding: 1rem 0; } }

@media screen and (max-width: 1000px) {
  .home-wrk-col {
    width: 100%; } }

.home-wrk-col .list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); }
  @media screen and (max-width: 450px) {
    .home-wrk-col .list {
      display: flex; } }

.home-wrk-col .item .pst {
  flex-direction: row; }
  @media screen and (max-width: 660px) {
    .home-wrk-col .item .pst {
      flex-direction: column; } }

.home-wrk-col .item:not(:nth-child(1)):not(:nth-child(2)) {
  grid-row: span 1;
  grid-column: span 1; }
  .home-wrk-col .item:not(:nth-child(1)):not(:nth-child(2)) .pst-img {
    width: 12rem;
    flex-shrink: 0; }
    @media screen and (max-width: 660px) {
      .home-wrk-col .item:not(:nth-child(1)):not(:nth-child(2)) .pst-img {
        width: 100%; } }
  .home-wrk-col .item:not(:nth-child(1)):not(:nth-child(2)) .pst-txt {
    display: none; }
  .home-wrk-col .item:not(:nth-child(1)):not(:nth-child(2)) .pst-des {
    flex-direction: column-reverse; }

.home-wrk-col .item:nth-child(1), .home-wrk-col .item:nth-child(2) {
  grid-column: span 2;
  grid-row: span 2; }
  .home-wrk-col .item:nth-child(1) .pst-img, .home-wrk-col .item:nth-child(2) .pst-img {
    width: 38rem;
    flex-shrink: 0; }
    @media screen and (max-width: 1200px) {
      .home-wrk-col .item:nth-child(1) .pst-img, .home-wrk-col .item:nth-child(2) .pst-img {
        width: 32rem; } }
    @media screen and (max-width: 1000px) {
      .home-wrk-col .item:nth-child(1) .pst-img, .home-wrk-col .item:nth-child(2) .pst-img {
        width: 28rem; } }
    @media screen and (max-width: 660px) {
      .home-wrk-col .item:nth-child(1) .pst-img, .home-wrk-col .item:nth-child(2) .pst-img {
        width: 100%; } }
  .home-wrk-col .item:nth-child(1) .pst-des, .home-wrk-col .item:nth-child(2) .pst-des {
    flex-direction: column; }

.home-wrk .work-detail-post {
  margin-bottom: 2rem;
  padding: 2rem;
  border-radius: 1rem;
  background-color: #ffffff; }
  @media screen and (max-width: 1000px) {
    .home-wrk .work-detail-post {
      padding: 1rem; } }

@media screen and (max-width: 1000px) {
  .home-post-col {
    width: 100%; } }

.home-post-head {
  padding: 2rem;
  border-radius: 1rem;
  background-color: #ffffff; }
  @media screen and (max-width: 1000px) {
    .home-post-head {
      padding: 1rem; } }
  .home-post-head .head {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 4rem; }
    @media screen and (max-width: 800px) {
      .home-post-head .head {
        margin-bottom: 2rem; } }
    .home-post-head .head .title-second {
      margin-bottom: 0; }
    .home-post-head .head .btn-more .link {
      position: relative;
      color: #2E3192;
      font-size: 1.6rem;
      font-weight: 600; }
      .home-post-head .head .btn-more .link::before {
        position: absolute;
        content: '';
        left: 0;
        bottom: -.2rem;
        width: 100%;
        right: 0;
        height: .1rem;
        background-color: #2E3192; }
  .home-post-head .list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr); }
    @media screen and (max-width: 700px) {
      .home-post-head .list {
        display: flex; } }
  @media screen and (max-width: 700px) {
    .home-post-head .item {
      width: 50%; } }
  @media screen and (max-width: 430px) {
    .home-post-head .item {
      width: 100%; } }
  .home-post-head .item:not(:nth-child(1)) {
    grid-row: span 1;
    grid-column: span 2; }
    .home-post-head .item:not(:nth-child(1)) .pst {
      flex-direction: row; }
      @media screen and (max-width: 700px) {
        .home-post-head .item:not(:nth-child(1)) .pst {
          flex-direction: column; } }
      .home-post-head .item:not(:nth-child(1)) .pst-img {
        width: 12rem;
        flex-shrink: 0; }
        @media screen and (max-width: 700px) {
          .home-post-head .item:not(:nth-child(1)) .pst-img {
            width: 100%; } }
      .home-post-head .item:not(:nth-child(1)) .pst-txt {
        display: none; }
      .home-post-head .item:not(:nth-child(1)) .pst-des {
        flex-direction: column-reverse; }
        @media screen and (max-width: 700px) {
          .home-post-head .item:not(:nth-child(1)) .pst-des {
            flex-direction: column; } }
  .home-post-head .item:nth-child(1) {
    grid-column: span 2;
    grid-row: span 4; }
    .home-post-head .item:nth-child(1) .pst-des {
      flex-direction: column; }

.home-slider {
  overflow: hidden; }
  .home-slider-wr {
    position: relative; }
  .home-slider .title-second span:nth-child(1) {
    color: #2E3192; }
  .home-slider .title-second span:nth-child(2) {
    color: #F1B650; }
  .home-slider .title-second span:nth-child(3) {
    color: #2E3192; }
  @media screen and (max-width: 1340px) {
    .home-slider .swiper-next {
      right: calc(-1 * var(--wh)/2); } }
  @media screen and (max-width: 1340px) {
    .home-slider .swiper-prev {
      left: calc(-1 * var(--wh)/2); } }
  @media screen and (max-width: 1350px) {
    .home-slider .swiper-next,
    .home-slider .swiper-prev {
      --wh: 3.8rem; } }
  @media screen and (max-width: 600px) {
    .home-slider .swiper-next,
    .home-slider .swiper-prev {
      display: none; } }
  @media screen and (min-width: 601px) {
    .home-slider .swiper-pagination {
      display: none; } }
  .home-slider-item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: column; }
    .home-slider-item .img {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      width: 100%;
      border-radius: 1rem;
      background-color: #ffffff; }
      .home-slider-item .img .inner {
        width: 70%;
        display: block;
        padding-top: calc((110 / 180) * 100%);
        position: relative;
        overflow: hidden; }
        .home-slider-item .img .inner img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          object-fit: contain; }
    .home-slider-item .des {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      gap: 1rem; }
      .home-slider-item .des .txt {
        font-size: 1.4rem;
        color: #191919; }
    .home-slider-item .icon {
      width: 2rem;
      height: 2rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      flex-shrink: 0;
      border-radius: 100rem;
      background-color: #2E3192;
      color: #ffffff;
      font-size: 1.2rem; }

.job-cv {
  padding: 4rem 0;
  position: relative; }
  .job-cv .wpcf7-form {
    position: relative; }
    .job-cv .wpcf7-form .wpcf7-response-output {
      font-size: 1.6rem !important; }
  @media screen and (max-width: 900px) {
    .job-cv-col {
      width: 100%; } }
  .job-cv-frm {
    background-color: #ffffff;
    width: 100%;
    border-radius: 0.6rem; }
  .job-cv-top {
    padding: 2rem; }
  .job-cv-main {
    background-color: #ffffff; }
    .job-cv-main .title {
      padding: 1.2rem 2rem;
      background-color: #FEEEEE; }
      .job-cv-main .title-second {
        font-size: 2.4rem; }
        @media only screen and (max-width: 1200px) {
          .job-cv-main .title-second {
            font-size: 2rem; } }
        @media only screen and (max-width: 800px) {
          .job-cv-main .title-second {
            font-size: 1.7rem; } }
    .job-cv-main .wpcf7-not-valid-tip {
      position: absolute; }
    .job-cv-main .wpcf7-field-group-remove,
    .job-cv-main .wpcf7-field-group-add {
      border: 0.1rem solid #2E3192;
      width: 100%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      background-color: #EEEDED;
      padding: 1.2rem 2.4rem;
      margin-bottom: 2rem;
      border-radius: 0.4rem;
      cursor: pointer; }
      .job-cv-main .wpcf7-field-group-remove .txt,
      .job-cv-main .wpcf7-field-group-add .txt {
        font-size: 1.4rem;
        color: #2E3192;
        font-weight: 500; }
  .job-cv-lb {
    margin-bottom: .6rem;
    font-family: var(--font-pri);
    font-weight: bold;
    text-transform: capitalize; }
    .job-cv-lb.speci {
      position: relative; }
      .job-cv-lb.speci::after {
        content: '*';
        color: #2E3192; }
  .job-cv-input {
    border: 0.1rem solid #E5E7EB;
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    border-radius: 0.6rem; }
    .job-cv-input.input-sec .txt {
      width: 6.8rem;
      border-left: 0.1rem solid #E5E7EB;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      flex-shrink: 0;
      background-color: #FEEEEE; }
    .job-cv-input .ip {
      border-radius: 0.6rem;
      padding: 1rem 1.4rem;
      font-family: var(--font-pri);
      font-size: 1.6rem;
      font-weight: 500;
      width: 100%;
      border: none;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      outline: none; }
      .job-cv-input .ip::placeholder {
        font-size: 1.6rem;
        color: #191919;
        font-family: var(--font-pri); }
  .job-cv-check {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    cursor: pointer; }
    .job-cv-check.active {
      border-color: #2E3192; }
    .job-cv-check input:checked ~ .ic-check img {
      opacity: 1; }
    .job-cv-check input:checked ~ .ic-check {
      border-color: #2E3192; }
    .job-cv-check .ic-check {
      position: relative;
      --wh: 2rem;
      width: var(--wh);
      height: var(--wh);
      flex-shrink: 0;
      border-radius: 0.6rem;
      border: 0.1rem solid #E5E7EB;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center; }
      .job-cv-check .ic-check img {
        width: 80%;
        object-fit: contain;
        display: block;
        opacity: 0;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out; }
    .job-cv-check .check-txt {
      font-size: 1.6rem;
      font-family: var(--font-sec);
      color: #191919;
      font-weight: 500;
      line-height: 100%; }
    .job-cv-check .ip {
      align-items: center;
      gap: 1rem; }
    .job-cv-check input {
      display: none; }
  .job-cv-inner {
    padding: 2rem; }
    @media screen and (max-width: 900px) {
      .job-cv-inner {
        padding: 2rem 1rem; } }
    .job-cv-inner .lbel {
      margin-bottom: .6rem; }
      .job-cv-inner .lbel .lb-sup {
        font-style: italic; }
    .job-cv-inner .block {
      background-color: #FEEEEE;
      border-radius: 0.6rem;
      padding: 1.2rem .8rem; }
      .job-cv-inner .block:not(:last-child) {
        margin-bottom: 2rem; }
      .job-cv-inner .block.more-exe {
        position: relative; }
      .job-cv-inner .block .close-exe {
        --wh: 2.4rem;
        position: absolute;
        top: 0;
        right: 0;
        width: var(--wh);
        height: var(--wh);
        color: #2E3192;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        -ms-align-items: center;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        font-size: 1.8rem;
        color: #2E3192;
        font-family: var(--font-sec); }
        @media only screen and (max-width: 1200px) {
          .job-cv-inner .block .close-exe {
            font-size: 1.6rem; } }
        @media only screen and (max-width: 800px) {
          .job-cv-inner .block .close-exe {
            font-size: 1.5rem; } }
        .job-cv-inner .block .close-exe:hover i {
          transform: rotate(180deg); }
        .job-cv-inner .block .close-exe i {
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out; }
    .job-cv-inner .gr-btn {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      gap: 1rem; }
    .job-cv-inner .btn-frm {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      width: 100%;
      border: 0.1rem solid #2E3192;
      color: #2E3192;
      background-color: #EEEDED;
      padding: 1.2rem 2.4rem;
      font-size: 1.4rem;
      font-family: var(--font-pri);
      font-weight: 600;
      border-radius: 0.6rem; }
  .job-cv-list .cv-btrn {
    width: 100%; }
  .job-cv-item .wpcf7-form-control-wrap {
    width: 100%;
    display: block; }
  @media screen and (max-width: 600px) {
    .job-cv-item {
      width: 100%; } }
  @media screen and (max-width: 1230px) {
    .job-cv-item.sec {
      width: 100%; } }
  @media screen and (max-width: 600px) {
    .job-cv-item .check-item {
      width: 100%; } }
  .job-cv-item .job-cvfile {
    position: relative;
    border: 0.1rem dashed #191919;
    border-radius: 0.6rem; }
    .job-cv-item .job-cvfile .file-lb {
      cursor: pointer; }
    .job-cv-item .job-cvfile .inner {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1.2rem;
      padding: 1.2rem; }
    .job-cv-item .job-cvfile input {
      display: none; }
    .job-cv-item .job-cvfile .txt {
      font-weight: 500;
      font-family: var(--font-pri);
      text-align: center; }
      @media screen and (max-width: 600px) {
        .job-cv-item .job-cvfile .txt {
          font-size: 1.2rem; } }
      .job-cv-item .job-cvfile .txt.bold {
        font-weight: 600; }
  .job-cv-item .dateTimeItem {
    position: relative; }
  .job-cv-item .icon {
    display: none;
    position: absolute;
    right: 1.4rem;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 2rem;
    height: 2rem;
    cursor: pointer; }
    .job-cv-item .icon img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block; }
  .job-cv-item .dateTimeText {
    position: absolute;
    left: 1.4rem;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 1.6rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    color: #191919;
    font-family: var(--font-pri); }
    .job-cv-item .dateTimeText.disable {
      opacity: 0;
      visibility: hidden; }
  .job-cv-it .wpcf7-list-item {
    position: relative;
    width: 50%;
    cursor: pointer;
    padding: 0 calc(var(--cg) / 2);
    margin: 0 0 var(--rg) !important; }
    @media screen and (max-width: 700px) {
      .job-cv-it .wpcf7-list-item {
        width: 100%; } }
    .job-cv-it .wpcf7-list-item input {
      display: none; }
    .job-cv-it .wpcf7-list-item > label {
      border-radius: 0.6rem;
      border: 0.1rem solid #E5E7EB;
      padding: 1rem 1.4rem;
      display: block;
      width: 100%;
      cursor: pointer; }
    .job-cv-it .wpcf7-list-item input:checked ~ .wpcf7-list-item-label::before {
      border-color: #2E3192; }
    .job-cv-it .wpcf7-list-item input:checked ~ .wpcf7-list-item-label::after {
      opacity: 1; }
    .job-cv-it .wpcf7-list-item .wpcf7-list-item-label {
      position: relative;
      padding-left: 2.4rem; }
      .job-cv-it .wpcf7-list-item .wpcf7-list-item-label::before {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border: 0.1rem solid #E5E7EB;
        border-radius: 0.4rem;
        width: 1.8rem;
        height: 1.8rem; }
      .job-cv-it .wpcf7-list-item .wpcf7-list-item-label::after {
        position: absolute;
        content: '';
        display: block;
        top: 4px;
        left: 8px;
        width: 4px;
        height: 10px;
        border: solid #2E3192;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out; }
  .job-cv-it .check-item {
    width: 100%; }
  .job-cv-it .check-item .wpcf7-form-control {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    --cg: 2.4rem;
    --rg: 2.4rem;
    flex-wrap: wrap;
    margin: 0 calc(var(--cg) / 2 * -1) calc(var(--rg) * -1); }
  .job-cv-btn {
    padding: 1.2rem 0 2.8rem;
    margin: 0 2rem;
    border-top: 0.1rem solid #E5E7EB;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    @media screen and (max-width: 900px) {
      .job-cv-btn {
        margin: 0 1rem; } }
    .job-cv-btn .btn-pri {
      width: 100%; }
    .job-cv-btn input {
      display: none; }
    .job-cv-btn .wpcf7-spinner {
      position: absolute; }

.job-if {
  background-color: #ffffff;
  width: 100%;
  border-radius: 0.6rem; }
  .job-if-inner {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    padding: 2.4rem;
    margin-bottom: 1.2rem; }
    .job-if-inner .title-second {
      margin-bottom: 2.8rem; }
    .job-if-inner .des {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      gap: .4rem; }
  .job-if-bdy .item {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    gap: .8rem; }
  .job-if-bdy .icon {
    --wh: 1.6rem;
    width: var(--wh);
    height: var(--wh);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-shrink: 0; }
    .job-if-bdy .icon img {
      width: 100%;
      height: 100%; }
  .job-if-bdy .txt {
    font-family: var(--font-pri); }
    .job-if-bdy .txt .bold {
      font-weight: 600; }
  .job-if-bdy .des {
    padding: 2rem 0;
    border-top: 0.1rem dashed #191919; }
  .job-if-bdy .btn-pri {
    width: 100%; }

.job-post-top .title-second {
  margin-bottom: 2.4rem; }

.job-post-inner {
  background-color: #ffffff;
  padding: 2rem 1.6rem; }
  .job-post-inner .btn-view {
    width: fit-content;
    margin: 2.2rem auto 0;
    padding: .8rem 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    gap: .8rem;
    color: #293477;
    font-family: var(--font-sec);
    font-weight: 700;
    position: relative; }
    .job-post-inner .btn-view:hover::before {
      width: 100%; }
    .job-post-inner .btn-view::before {
      position: absolute;
      content: '';
      background-color: #293477;
      height: .1rem;
      width: 2em;
      left: 0;
      bottom: .4rem;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }

.job-post-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  gap: 2.4rem; }
  @media screen and (max-width: 900px) {
    .job-post-list {
      --cg: 2.4rem;
      --rg: 2.4rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-wrap: wrap;
      margin: 0 calc(var(--cg) / 2 * -1) calc(var(--rg) * -1);
      gap: 0;
      flex-direction: row; } }

@media screen and (max-width: 900px) {
  .job-post-item {
    width: 50%;
    padding: 0 calc(var(--cg) / 2);
    margin-bottom: var(--rg); } }

@media screen and (max-width: 600px) {
  .job-post-item {
    width: 100%; } }

.job-post-item:hover .inner {
  border-color: #E5E7EB;
  box-shadow: 0 0.4rem 3rem rgba(0, 0, 0, 0.1); }

.job-post-item .inner {
  position: relative;
  padding: 2rem;
  border-radius: 0.6rem;
  background-color: #ffffff;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  height: 100%; }
  @media screen and (max-width: 800px) {
    .job-post-item .inner {
      padding: 1rem; } }

.job-post-item .top {
  margin-bottom: 1.6rem;
  position: relative; }
  .job-post-item .top .tt {
    color: #191919;
    font-weight: bold;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    font-family: var(--font-pri);
    margin-right: 4rem; }
    .job-post-item .top .tt:hover {
      color: #2E3192; }
  .job-post-item .top .tag {
    position: absolute;
    right: -2.7rem;
    top: 0;
    padding: .6rem 1.6rem;
    line-height: 100%;
    color: #ffffff;
    font-family: var(--font-pri);
    border-radius: .2rem;
    border-bottom-right-radius: unset;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: capitalize;
    border-radius: 2px 4px 0px 2px;
    z-index: 1; }
    @media screen and (max-width: 800px) {
      .job-post-item .top .tag {
        right: -1.7rem; } }
    .job-post-item .top .tag::after {
      position: absolute;
      content: '';
      top: .3rem;
      right: .3rem;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-color: #BB1313;
      border-radius: 2px 4px 0px 2px; }
    .job-post-item .top .tag::before {
      position: absolute;
      content: '';
      top: 100%;
      right: 0;
      border-top: 0rem solid transparent;
      border-left: .7rem solid #293477;
      border-right: 0rem solid transparent;
      border-bottom: .7rem solid transparent; }
    .job-post-item .top .tag.sec {
      background-color: #2E3192; }
      .job-post-item .top .tag.sec::after {
        background-color: #2E3192; }
    .job-post-item .top .tag.pri {
      background-color: #BB1313; }
      .job-post-item .top .tag.pri::after {
        background-color: #BB1313; }

.job-post-item .bdy {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  gap: .6rem; }

.job-post-item .more {
  display: flex;
  justify-content: flex-end;
  margin-top: -2.2rem; }
  @media screen and (max-width: 900px) {
    .job-post-item .more {
      margin-top: 1rem; } }
  .job-post-item .more .btn-more {
    color: #2E3192;
    font-family: var(--font-pri);
    font-weight: 400;
    font-size: 1.4rem;
    width: fit-content;
    position: relative;
    flex-shrink: 0; }
    .job-post-item .more .btn-more:hover::before {
      width: 100%;
      left: 0; }
    .job-post-item .more .btn-more::before {
      position: absolute;
      content: '';
      right: 0;
      bottom: 0;
      height: .1rem;
      width: 0;
      background-color: #2E3192;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }

.job-post-item .des {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  gap: .6rem; }

.job-post-item .item {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1rem; }
  .job-post-item .item:last-child {
    padding-right: 7.5rem; }
    @media screen and (max-width: 900px) {
      .job-post-item .item:last-child {
        padding-right: 0; } }

.job-post-item .in {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  gap: .8rem; }

.job-post-item .txt {
  font-size: 1.5rem;
  font-weight: 500; }

.job-post-item .icon {
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-top: .1rem; }
  .job-post-item .icon img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: contain; }

.job-thumb {
  margin-top: 2.4rem;
  width: 100%; }
  @media screen and (max-width: 900px) {
    .job-thumb {
      width: 70%;
      margin: 2.4rem auto 0; } }
  .job-thumb .inner {
    display: block;
    padding-top: calc((616 / 405) * 100%);
    position: relative;
    overflow: hidden;
    border-radius: 0.6rem;
    width: 100%; }
    .job-thumb .inner img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }

.job-category {
  padding: 2rem 3.2rem;
  background-color: #ffffff;
  border-radius: 0.4rem; }
  .job-category .title-second {
    font-size: 3.2rem;
    font-family: var(--font-pri);
    font-weight: bold;
    color: #191919; }
    @media only screen and (max-width: 1200px) {
      .job-category .title-second {
        font-size: 2.7rem; } }
    @media only screen and (max-width: 800px) {
      .job-category .title-second {
        font-size: 2.2rem; } }
  .job-category .item {
    --wh: 1.6rem;
    padding-right: 2rem;
    padding-top: 1.2rem;
    position: relative; }
    .job-category .item:not(:last-child) {
      padding: 1.2rem 0;
      border-bottom: 0.1rem solid #D0D5DD; }
    .job-category .item.active, .job-category .item:hover {
      color: #2E3192;
      text-decoration: underline; }
      .job-category .item.active .icon, .job-category .item:hover .icon {
        opacity: 1;
        transform: translate(0, -50%); }
  .job-category .icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-0.5rem, -50%);
    width: var(--wh);
    height: var(--wh);
    opacity: 0;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
    .job-category .icon img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block; }

.avatar-wrapper {
  position: relative;
  height: 8rem;
  width: 8rem;
  border-radius: 100%;
  overflow: hidden;
  border: 0.1rem dashed #E5E7EB;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  margin: .8rem 0; }
  .avatar-wrapper input {
    display: none !important; }
  .avatar-wrapper:hover {
    transform: scale(1.05);
    cursor: pointer; }
  .avatar-wrapper:hover .profile-pic {
    opacity: .5; }
  .avatar-wrapper .profile-pic {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    margin: auto;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    border: none;
    border-radius: 100%;
    outline: none;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
  .avatar-wrapper .icon {
    display: block !important;
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
    .avatar-wrapper .icon img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block; }
  .avatar-wrapper .upload-button {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    z-index: 1; }

.contact-wr {
  background-color: #ffffff;
  position: relative;
  padding: 5.3rem 4rem;
  border-radius: 0.8rem;
  overflow: hidden; }
  @media screen and (max-width: 1200px) {
    .contact-wr {
      padding: 3.3rem 2rem; } }
  @media screen and (max-width: 600px) {
    .contact-wr {
      padding: 2rem 1rem; } }

.contact-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50%;
  min-width: 100%;
  z-index: 1; }
  .contact-bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block; }

.contact-row {
  z-index: 2;
  position: relative; }

.contact-lb {
  margin-bottom: .6rem;
  font-family: var(--font-sec);
  font-weight: bold;
  text-transform: capitalize; }
  .contact-lb.speci {
    position: relative; }
    .contact-lb.speci::after {
      content: '*';
      color: #2E3192; }

.contact-input {
  border: 0.1rem solid #D0D5DD;
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  border-radius: 0.6rem; }
  .contact-input.input-sec .txt {
    width: 6.8rem;
    border-left: 0.1rem solid #D0D5DD;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-shrink: 0;
    background-color: #FEEEEE; }
  .contact-input .ip {
    border-radius: 0.6rem;
    padding: 1rem 1.4rem;
    font-family: var(--font-pri);
    font-size: 1.6rem;
    font-weight: 500;
    width: 100%;
    border: none;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    outline: none; }
    .contact-input .ip::placeholder {
      font-size: 1.6rem;
      color: #94A3B8;
      font-family: var(--font-pri); }

.contact-item .wpcf7-form-control-wrap {
  width: 100%;
  display: block; }

@media screen and (max-width: 600px) {
  .contact-item {
    width: 100%; } }

@media screen and (max-width: 1230px) {
  .contact-item.sec {
    width: 100%; } }

@media screen and (max-width: 1100px) {
  .contact-col {
    width: 100%; } }

.contact-des {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  padding-left: 4rem; }
  @media screen and (max-width: 1200px) {
    .contact-des {
      padding: 0;
      gap: 1.6rem; } }
  .contact-des-item .title-third {
    margin-bottom: 1rem; }
  .contact-des-item .title-sub,
  .contact-des-item .txt {
    font-size: 1.6rem;
    font-family: var(--font-thr); }
  .contact-des-item .title-second {
    text-transform: unset; }
  .contact-des-item p {
    text-align: left !important; }

.contact form {
  height: 100%;
  width: 100%;
  position: relative; }
  .contact form .wpcf7-response-output {
    right: auto !important;
    left: 2rem;
    bottom: 0 !important; }

.contact-frm {
  height: 100%;
  width: 100%;
  border: 0.1rem solid #E2E8F0;
  background-color: #ffffff;
  box-shadow: 0 0.4rem 0.6rem rgba(16, 24, 40, 0.1);
  border-radius: 0.8rem;
  padding: 4rem; }
  @media screen and (max-width: 1000px) {
    .contact-frm {
      padding: 3rem 2rem; } }
  @media screen and (max-width: 600px) {
    .contact-frm {
      padding: 2rem 1rem; } }
  .contact-frm .gr-btn {
    width: 100%; }
    .contact-frm .gr-btn .btn-sec {
      width: 100%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      margin-top: 2rem; }
  .contact-frm .lbel {
    margin-bottom: 1rem; }
  @media screen and (max-width: 1200px) {
    .contact-frm .ip::placeholder {
      font-size: 1.4rem; } }

.contact-loca-wr {
  background-color: #ffffff;
  padding: 4rem;
  border-radius: 2.8rem; }
  @media screen and (max-width: 1200px) {
    .contact-loca-wr {
      padding: 2rem;
      border-radius: 1.6rem; } }
  @media screen and (max-width: 600px) {
    .contact-loca-wr {
      padding: 1rem; } }

.contact-loca-head {
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid #E2E8F0; }

.work-detail-post {
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 0.6rem; }
  @media screen and (max-width: 800px) {
    .work-detail-post {
      padding: 1rem 0; } }
  .work-detail-post .title-second {
    margin-bottom: 2rem; }
  @media screen and (max-width: 1350px) {
    .work-detail-post .swiper-next {
      right: calc(-1 * var(--wh)/2); } }
  @media screen and (max-width: 1350px) {
    .work-detail-post .swiper-prev {
      left: calc(-1 * var(--wh)/2); } }
  @media screen and (max-width: 1350px) {
    .work-detail-post .swiper-next,
    .work-detail-post .swiper-prev {
      --wh: 3.8rem; } }
  @media screen and (max-width: 600px) {
    .work-detail-post .swiper-next,
    .work-detail-post .swiper-prev {
      display: none; } }

.work-detail-inner .gr-btn .btn-sec, .work-detail-inner .gr-btn .btn-pri {
  width: fit-content; }

.work-detail-wr {
  margin: 2rem 0; }

.work-detail-thum {
  position: relative;
  background-color: #2E3192;
  padding: 3.2rem 2.8rem;
  border-radius: 0.8rem;
  overflow: hidden;
  margin-bottom: 2rem; }
  .work-detail-thum .decor {
    position: absolute;
    z-index: 1;
    right: -2rem;
    bottom: -5rem;
    width: 25vw;
    min-width: 25rem; }
    .work-detail-thum .decor img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block; }
  .work-detail-thum-wr {
    position: relative;
    z-index: 2;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem; }
  .work-detail-thum .inner,
  .work-detail-thum .des {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    gap: .8rem; }
  .work-detail-thum .title-second {
    font-family: var(--font-pri); }
  .work-detail-thum .txt {
    font-family: var(--font-pri); }
  .work-detail-thum .btn-pri .txt {
    font-family: var(--font-pri); }

.work-detail-inner {
  background-color: #ffffff;
  border-radius: 0.8rem;
  padding: 2rem 2.8rem;
  margin-bottom: 2rem; }
  @media screen and (max-width: 600px) {
    .work-detail-inner {
      padding: 1.4rem; } }
  .work-detail-inner .title-second {
    margin-bottom: 2rem; }
  .work-detail-inner .inner {
    background-color: #F5F6F7;
    border-radius: 0.6rem;
    padding: 4.6rem 2.8rem; }
    @media screen and (max-width: 600px) {
      .work-detail-inner .inner {
        padding: 2.6rem 1.4rem; } }
    .work-detail-inner .inner .list {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-wrap: wrap;
      gap: var(--gap);
      --gap: 2rem; }
      @media screen and (max-width: 550px) {
        .work-detail-inner .inner .list {
          --gap: 1rem; } }
    .work-detail-inner .inner .item {
      width: calc(50% - var(--gap));
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      gap: 1.8rem; }
      @media screen and (max-width: 550px) {
        .work-detail-inner .inner .item {
          width: 100%;
          gap: 1rem; } }
    .work-detail-inner .inner .icon {
      --wh: 3.2rem;
      width: var(--wh);
      height: var(--wh);
      flex-shrink: 0;
      background-color: #A9ABD2;
      border-radius: 100rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center; }
      .work-detail-inner .inner .icon img {
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        object-fit: contain; }
    .work-detail-inner .inner .txt {
      font-family: var(--font-pri); }
  .work-detail-inner .gr-btn {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.4rem; }
    .work-detail-inner .gr-btn .btn-pri,
    .work-detail-inner .gr-btn .btn-sec {
      min-width: 13.9rem; }

.work-detail-des ul {
  margin-bottom: 2.2rem;
  font-family: var(--font-pri); }

.work-detail-des .tt {
  font-family: var(--font-pri);
  font-weight: bold;
  font-size: 1.8rem; }
  @media only screen and (max-width: 1200px) {
    .work-detail-des .tt {
      font-size: 1.6rem; } }
  @media only screen and (max-width: 800px) {
    .work-detail-des .tt {
      font-size: 1.5rem; } }

.work-detail-col:nth-child(1) {
  width: calc((8/12 * 100%) - 2rem); }
  @media screen and (max-width: 900px) {
    .work-detail-col:nth-child(1) {
      width: 100%; } }

.work-detail-col:nth-child(2) {
  width: calc((4/12 * 100%) + 2rem); }
  @media screen and (max-width: 900px) {
    .work-detail-col:nth-child(2) {
      width: 100%; } }

.work-detail-bdy .title-second {
  font-family: var(--font-pri);
  font-weight: bold; }

.work-detail-post .title-second {
  font-family: var(--font-pri);
  font-weight: bold;
  margin-bottom: 2rem; }

.post-sw-inner {
  position: relative; }

.wrks-wr {
  margin-top: 4rem; }

.wrks-bar {
  width: 100%;
  margin-bottom: 2.4rem;
  padding-bottom: .5rem;
  overflow: auto hidden; }
  .wrks-bar::-webkit-scrollbar {
    height: .3rem; }
  .wrks-bar::-webkit-scrollbar-thumb {
    background-image: linear-gradient(#2E3192, #2E3192);
    border-radius: 50px; }
  .wrks-bar .list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    width: fit-content;
    gap: 1.6rem;
    margin: auto; }
    @media screen and (max-width: 1200px) {
      .wrks-bar .list {
        gap: 1rem; } }
  .wrks-bar .item {
    width: fit-content;
    flex-shrink: 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    gap: .8rem;
    padding: 1.2rem 3.2rem;
    background-color: #ffffff;
    border: 0.1rem solid #E0E0E0;
    border-radius: 0.4rem;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    cursor: pointer; }
    @media screen and (max-width: 1200px) {
      .wrks-bar .item {
        padding: 1.2rem 2.4rem; } }
    .wrks-bar .item:hover, .wrks-bar .item.active {
      background-color: #2E3192;
      border-color: #2E3192; }
      .wrks-bar .item:hover .txt, .wrks-bar .item.active .txt {
        color: #ffffff; }
      .wrks-bar .item:hover img, .wrks-bar .item.active img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(44deg) brightness(101%) contrast(105%); }
    .wrks-bar .item .icon {
      --wh: 2rem;
      width: var(--wh);
      height: var(--wh);
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      flex-shrink: 0; }
      .wrks-bar .item .icon img {
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block; }
    .wrks-bar .item .txt {
      color: #191919;
      font-family: var(--font-fur);
      font-weight: 500;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }

.wrks-bdy {
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 0.8rem;
  margin-bottom: 2rem; }
  .wrks-bdy .top {
    background-color: #2E3192;
    padding: 2rem;
    margin-bottom: .8rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 2rem; }
    .wrks-bdy .top .inner {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-wrap: wrap;
      gap: 1.2rem; }
      @media screen and (max-width: 970px) {
        .wrks-bdy .top .inner {
          width: 100%;
          justify-content: center; } }
    .wrks-bdy .top .btn-pri {
      font-family: var(--font-pri); }
  .wrks-bdy .bdy {
    padding: 2rem; }
    @media screen and (max-width: 1000px) {
      .wrks-bdy .bdy {
        padding: 2rem 1rem; } }
    @media screen and (max-width: 1000px) {
      .wrks-bdy .bdy .col {
        width: calc(4/12 * 100%); } }
    @media screen and (max-width: 700px) {
      .wrks-bdy .bdy .col {
        width: 50%; } }
    @media screen and (max-width: 450px) {
      .wrks-bdy .bdy .col {
        width: 100%; } }
  .wrks-bdy .gr-btn {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    margin-bottom: 4rem; }
    @media screen and (max-width: 600px) {
      .wrks-bdy .gr-btn {
        margin-bottom: 2rem; } }
  .wrks-bdy .gr-select {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem; }
    @media screen and (max-width: 700px) {
      .wrks-bdy .gr-select {
        width: 100%; } }
  .wrks-bdy .select {
    height: 100%;
    width: 22.4rem;
    position: relative;
    --wh: 2rem; }
    @media screen and (max-width: 700px) {
      .wrks-bdy .select {
        width: calc(50% - .6rem); } }
    @media screen and (max-width: 500px) {
      .wrks-bdy .select {
        width: 100%;
        height: fit-content; } }
    .wrks-bdy .select .icon {
      position: absolute;
      top: 0;
      bottom: 0;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      margin: auto;
      left: 1.4rem;
      z-index: 1;
      width: var(--wh);
      height: var(--wh); }
      .wrks-bdy .select .icon img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block; }
    .wrks-bdy .select .select2-container {
      height: 100%; }
      .wrks-bdy .select .select2-container--default .select2-selection--single {
        background-color: #2E3192;
        padding-left: calc(var(--wh) + 1.4rem);
        border-color: #ffffff !important; }
        .wrks-bdy .select .select2-container--default .select2-selection--single .select2-selection__rendered,
        .wrks-bdy .select .select2-container--default .select2-selection--single .select2-selection__placeholder {
          color: #ffffff !important;
          font-family: var(--font-pri);
          font-weight: 500;
          font-size: 1.6rem;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -o-flex;
          display: flex;
          align-items: center;
          height: 100%; }
        .wrks-bdy .select .select2-container--default .select2-selection--single .select2-selection__arrow {
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -ms-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out; }
          .wrks-bdy .select .select2-container--default .select2-selection--single .select2-selection__arrow b {
            border-color: #fff transparent transparent transparent;
            border-width: 7px 6px 0 6px;
            margin-left: -1.2rem;
            transition: 0.3s ease-in-out;
            -webkit-transition: 0.3s ease-in-out;
            -moz-transition: 0.3s ease-in-out;
            -ms-transition: 0.3s ease-in-out;
            -o-transition: 0.3s ease-in-out; }

.pagination-mona {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  width: 100%; }

.mona-content {
  font-family: var(--font-pri); }

.post-wr {
  margin: 4rem 0; }

@media screen and (max-width: 900px) {
  .post-col {
    width: 100%; } }

.post-content {
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 1rem; }
  @media screen and (max-width: 600px) {
    .post-content {
      padding: 1rem 0; } }
  @media screen and (max-width: 700px) {
    .post-content .col {
      width: 50%; } }
  @media screen and (max-width: 430px) {
    .post-content .col {
      width: 100%; } }
  @media screen and (min-width: 701px) {
    .post-content .pst {
      flex-direction: row; } }
  .post-content .pst-tt {
    font-size: 2.4rem; }
    @media only screen and (max-width: 1200px) {
      .post-content .pst-tt {
        font-size: 2rem; } }
    @media only screen and (max-width: 800px) {
      .post-content .pst-tt {
        font-size: 1.7rem; } }
    @media screen and (max-width: 600px) {
      .post-content .pst-tt {
        font-size: 1.6rem; } }
  @media screen and (min-width: 701px) {
    .post-content .pst-img {
      width: 28rem;
      flex-shrink: 0; } }

.post-detail-wr {
  margin: 4rem 0; }

@media screen and (max-width: 900px) {
  .post-detail-col {
    width: 100%; } }

.post-detail-content {
  padding: 2rem;
  border-radius: 0.4rem;
  background-color: #ffffff;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  gap: 2rem; }
  .post-detail-content .content-list {
    list-style-type: decimal; }
  .post-detail-content .title-second {
    margin-bottom: 0; }
  @media screen and (max-width: 800px) {
    .post-detail-content .mona-content ul {
      padding-left: 2rem; } }
  .post-detail-content .content-item {
    font-family: var(--font-pri);
    font-size: 2rem;
    font-weight: 600; }
    @media only screen and (max-width: 1200px) {
      .post-detail-content .content-item {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .post-detail-content .content-item {
        font-size: 1.6rem; } }
    .post-detail-content .content-item img {
      margin: .5rem 0; }
    .post-detail-content .content-item .p,
    .post-detail-content .content-item .txt {
      margin: .5rem 0;
      font-weight: 500;
      font-size: 1.6rem; }
  .post-detail-content .social {
    border-top: 0.1rem solid #E5E7EB;
    padding-top: 2rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem; }
    .post-detail-content .social-list {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      gap: 1.6rem;
      border: none; }
    .post-detail-content .social-item {
      width: 3rem;
      height: 3rem;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }
      .post-detail-content .social-item img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block; }
      .post-detail-content .social-item:hover {
        transform: translateY(-5px); }

.post-detail-contact .wpcf7-form {
  position: relative; }
  .post-detail-contact .wpcf7-form .wpcf7-response-output {
    position: absolute;
    bottom: 0;
    left: 3.2rem;
    font-size: 1.2rem; }
    @media screen and (max-width: 600px) {
      .post-detail-contact .wpcf7-form .wpcf7-response-output {
        left: 1.2rem;
        bottom: -.5rem;
        font-size: 1rem;
        right: 1.2rem; } }

.post-detail-contact .inner {
  background-color: #ffffff;
  padding: 3.2rem;
  border-radius: 0.4rem; }
  @media screen and (max-width: 600px) {
    .post-detail-contact .inner {
      padding: 2rem 1.2rem; } }
  .post-detail-contact .inner .btn-pri {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }

.post-detail-contact .list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-bottom: 3.2rem; }

.post-detail-contact .item {
  width: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  gap: .6rem; }
  .post-detail-contact .item .ip {
    width: 100%;
    border: none;
    outline: none;
    padding: 1.2rem 1.4rem;
    font-family: var(--font-pri);
    font-size: 1.6rem;
    font-weight: 500;
    border-radius: 0.8rem;
    overflow: hidden; }
    .post-detail-contact .item .ip::placeholder {
      font-family: var(--font-pri);
      font-size: 1.6rem;
      font-weight: 500;
      color: #191919; }
  .post-detail-contact .item .lb {
    font-family: var(--font-pri);
    font-weight: bold;
    font-size: 1.4rem; }
  .post-detail-contact .item .in {
    position: relative;
    border: 0.1rem solid #191919;
    border-radius: 0.8rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center; }
    .post-detail-contact .item .in .wpcf7-form-control-wrap {
      width: 100%; }
  .post-detail-contact .item .select2-container {
    width: 7rem !important; }
    .post-detail-contact .item .select2-container .select2-selection--single {
      border: none !important; }
      .post-detail-contact .item .select2-container .select2-selection--single .select2-selection__placeholder {
        color: #191919 !important; }
      .post-detail-contact .item .select2-container .select2-selection--single .select2-selection__rendered {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: center; }

.post-detail-contact .wpcf7-not-valid-tip {
  position: absolute; }

.post-detail-contact .title-second {
  margin-bottom: 2rem; }

.post-detail-contact .gr-btn {
  width: 100%; }
  .post-detail-contact .gr-btn .btn-sec {
    width: 100%; }

.post-detail-intro {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  --pad: 1rem;
  margin: 0 calc(var(--pad) * -1) calc(var(--pad) * -2); }
  @media screen and (max-width: 900px) {
    .post-detail-intro {
      flex-direction: row; } }
  .post-detail-intro-inner {
    padding: 0 var(--pad);
    margin-bottom: calc(var(--pad) * 2);
    overflow: hidden; }
    @media screen and (max-width: 900px) {
      .post-detail-intro-inner {
        width: 50%; } }
    @media screen and (max-width: 500px) {
      .post-detail-intro-inner {
        width: 100%; } }
    .post-detail-intro-inner .block {
      height: 100%;
      background-color: #ffffff;
      box-shadow: 0 0.4rem 0.6rem rgba(16, 24, 40, 0.1); }
    .post-detail-intro-inner .img {
      display: block;
      padding-top: calc((265 / 384) * 100%);
      position: relative;
      overflow: hidden; }
      .post-detail-intro-inner .img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .post-detail-intro-inner .btn-pri {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      width: 100%; }
    .post-detail-intro-inner .des {
      padding: 2rem 2.4rem; }
    .post-detail-intro-inner .title-second {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: .8rem; }
    .post-detail-intro-inner .title-sub {
      text-align: left;
      margin-bottom: 2.4rem;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; }
  .post-detail-intro .job-thumb {
    margin: 0;
    padding: 0 var(--pad);
    margin-bottom: calc(var(--pad) * 2); }
    @media screen and (max-width: 900px) {
      .post-detail-intro .job-thumb {
        width: 50%;
        margin: 0 auto calc(var(--pad) * 2); } }
    @media screen and (max-width: 500px) {
      .post-detail-intro .job-thumb {
        width: 100%; } }
    @media screen and (max-width: 900px) {
      .post-detail-intro .job-thumb .inner {
        width: 100%;
        height: 100%; } }

.post-detail-relate {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 2rem 1.2rem;
  border: .1rem solid #E2E8F0; }
  .post-detail-relate .title-second {
    margin-bottom: 1.6rem; }
  .post-detail-relate .list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    --pad: 1rem;
    margin: 0 calc(var(--pad) * -1) calc(var(--pad) * -2); }
    @media screen and (max-width: 900px) {
      .post-detail-relate .list {
        flex-direction: row; } }
  .post-detail-relate .item {
    padding: 0 var(--pad);
    margin-bottom: calc(var(--pad) * 2); }
    @media screen and (max-width: 900px) {
      .post-detail-relate .item {
        width: calc(4/12 * 100%); } }
    @media screen and (max-width: 560px) {
      .post-detail-relate .item {
        width: calc(6/12 * 100%); } }
    @media screen and (max-width: 400px) {
      .post-detail-relate .item {
        width: 100%; } }

.post-detail-bottom {
  margin: 4rem 0;
  padding: 2rem;
  border-radius: 1.2rem;
  background-color: #ffffff; }
  @media screen and (max-width: 900px) {
    .post-detail-bottom {
      padding: 1rem 0; } }
  @media screen and (max-width: 900px) {
    .post-detail-bottom .col {
      width: calc(4/12 * 100%); } }
  @media screen and (max-width: 660px) {
    .post-detail-bottom .col {
      width: 50%; } }
  @media screen and (max-width: 420px) {
    .post-detail-bottom .col {
      width: 100%; } }
  .post-detail-bottom .gr-btn {
    margin-bottom: 2rem;
    padding: 2rem 0;
    width: 100%; }

.post-pri {
  width: 100%;
  border-radius: 1.2rem;
  background-color: #ffffff;
  overflow: hidden; }
  .post-pri-head {
    padding: 1.2rem;
    background-color: #2E3192; }
  .post-pri-video {
    width: 100%;
    display: block;
    padding-top: calc((180 / 384) * 100%);
    position: relative;
    overflow: hidden; }
    .post-pri-video img,
    .post-pri-video video,
    .post-pri-video iframe {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .post-pri-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem; }
    @media screen and (max-width: 600px) {
      .post-pri-list {
        padding: 1rem; } }
    .post-pri-list .pst {
      flex-direction: row; }
      @media screen and (max-width: 400px) {
        .post-pri-list .pst {
          flex-direction: column; } }
      .post-pri-list .pst-img {
        width: 10.4rem;
        flex-shrink: 0; }
        @media screen and (max-width: 400px) {
          .post-pri-list .pst-img {
            width: 100%; } }
      .post-pri-list .pst-des {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column-reverse;
        gap: .4rem; }
      .post-pri-list .pst .tag {
        font-size: 1.3rem; }

.post-tab {
  padding: 2rem 0;
  background-color: #2E3192; }
  @media screen and (max-width: 800px) {
    .post-tab {
      padding: 1rem 0; } }
  .post-tab .swiper-slide {
    width: calc(4/12 * 100%); }
  .post-tab-list {
    width: 80%;
    margin: auto; }
    @media screen and (max-width: 800px) {
      .post-tab-list {
        width: 100%; } }
  .post-tab-item {
    border-radius: 100rem;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
    .post-tab-item .link {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      color: #ffffff;
      font-size: 2rem;
      font-weight: 700;
      padding: 1rem;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }
      @media only screen and (max-width: 1200px) {
        .post-tab-item .link {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 800px) {
        .post-tab-item .link {
          font-size: 1.6rem; } }
      @media screen and (max-width: 800px) {
        .post-tab-item .link {
          padding: 1rem; } }
    .post-tab-item:hover, .post-tab-item.active {
      background-color: rgba(255, 255, 255, 0.1); }
      .post-tab-item:hover .link, .post-tab-item.active .link {
        color: #F1B650; }

.swiper-slide {
  height: initial;
  overflow: hidden; }

.cataloge {
  padding: 2.4rem;
  background-color: #FEF8EE;
  border-radius: 1rem;
  margin: 2rem 0; }
  @media screen and (max-width: 900px) {
    .cataloge {
      width: 80%;
      margin: 2rem auto; } }
  @media screen and (max-width: 500px) {
    .cataloge {
      width: 100%;
      margin: 2rem auto; } }
  .cataloge-head {
    margin-bottom: 2.4rem; }
  .cataloge .head {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    gap: .8rem; }
    .cataloge .head .icon {
      --wh: 1.6rem;
      width: var(--wh);
      height: var(--wh);
      flex-shrink: 0;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -ms-align-items: center;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center; }
      .cataloge .head .icon img {
        width: 80%;
        height: 80%;
        display: block;
        object-fit: contain; }
  .cataloge-list .head .icon img {
    width: 60%;
    height: 60%; }
  .cataloge-list .list {
    list-style: none;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    gap: 1.2rem; }
  .cataloge .pdl {
    padding-left: 2.4rem; }
  .cataloge-t {
    font-family: var(--font-pri);
    font-weight: 600;
    font-size: 1.4rem;
    margin: 0 !important; }
  .cataloge-tt {
    font-family: var(--font-pri);
    font-weight: 600;
    font-size: 1.6rem;
    color: #000000; }

.select2-search--dropdown {
  display: none; }

.pst {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  height: 100%;
  background-color: #ffffff;
  position: relative; }
  @media screen and (max-width: 600px) {
    .pst {
      gap: 1rem; } }
  .pst.sec {
    gap: 0;
    border-radius: 0.8rem;
    border: 0.1rem solid #E5E7EB;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
    .pst.sec:hover {
      box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.1), 0px 10px 15px -3px rgba(16, 24, 40, 0.1); }
    .pst.sec .pst-tt {
      font-size: 2rem; }
      @media only screen and (max-width: 1200px) {
        .pst.sec .pst-tt {
          font-size: 1.8rem; } }
      @media only screen and (max-width: 800px) {
        .pst.sec .pst-tt {
          font-size: 1.6rem; } }
    .pst.sec .pst-inner {
      flex: 1; }
    .pst.sec .btn-sec {
      margin-top: auto; }
    .pst.sec .pst-img {
      border-radius: 0; }
      .pst.sec .pst-img-in {
        display: block;
        padding-top: calc((205 / 280) * 100%);
        position: relative;
        overflow: hidden; }
  .pst-inner {
    padding: 2rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    gap: 2rem; }
    @media screen and (max-width: 800px) {
      .pst-inner {
        padding: 1rem;
        gap: 1rem; } }
    .pst-inner .inner {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column;
      gap: .6rem; }
    .pst-inner .txt {
      font-size: 1.5rem;
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; }
    .pst-inner .icon {
      width: 2rem;
      height: 2rem;
      flex-shrink: 0;
      margin-top: .1rem; }
      .pst-inner .icon img {
        height: 100%;
        width: 100%;
        display: block;
        object-fit: contain; }
    .pst-inner .in {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      gap: .8rem; }
    .pst-inner .item {
      width: 100%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 1rem; }
  .pst-des {
    flex: 1;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    gap: .8rem; }
  .pst-txt {
    font-size: 1.6rem;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #6F6D6D;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media screen and (max-width: 500px) {
      .pst-txt {
        font-size: 1.4rem; } }
  .pst-tag {
    position: absolute;
    left: 1rem;
    top: 1rem;
    padding: .6rem 1.2rem;
    border-radius: 0.4rem;
    color: #F15050;
    background-color: #FEEEEE;
    z-index: 1; }
    @media screen and (max-width: 900px) {
      .pst-tag {
        padding: .5rem .8rem;
        font-size: 1.2rem;
        left: .5rem;
        top: .5rem; } }
  .pst-img {
    width: 100%;
    border-radius: 0.8rem;
    overflow: hidden; }
    .pst-img:hover img {
      transform: translate(-50%, -50%) scale(1.1); }
    .pst-img-in {
      display: block;
      padding-top: calc((161 / 280) * 100%);
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%; }
      .pst-img-in img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s ease-in-out;
        -webkit-transition: 0.3s ease-in-out;
        -moz-transition: 0.3s ease-in-out;
        -ms-transition: 0.3s ease-in-out;
        -o-transition: 0.3s ease-in-out;
        transform: translate(-50%, -50%) scale(1); }
  .pst-tt {
    color: #191919;
    font-size: 1.6rem;
    font-family: var(--font-pri);
    font-weight: 700;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
    .pst-tt:hover {
      color: #2E3192; }
  .pst .tag {
    color: #6F6D6D;
    font-family: var(--font-pri);
    font-weight: 400;
    line-height: 130%; }

.range {
  position: relative;
  padding-top: 1.5rem;
  width: 100%; }

.range-input {
  position: relative; }

.range-input-item[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  outline: none;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  background-color: transparent;
  pointer-events: none; }

.slider-track {
  width: 100%;
  height: .5rem;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: .5rem;
  background-color: #ffffff; }

.range-input-item[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: .3rem; }

.range-input-item[type="range"]::-moz-range-track {
  -moz-appearance: none;
  height: .5rem; }

.range-input-item[type="range"]::-ms-track {
  appearance: none;
  height: .5rem; }

.range-input-item[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1.6rem;
  width: 1.6rem;
  background-color: #F1B650;
  cursor: pointer;
  margin-top: -.7rem;
  pointer-events: auto;
  border-radius: 50%; }

.range-input-item[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: #F1B650;
  pointer-events: auto;
  border: none; }

.range-input-item[type="range"]::-ms-thumb {
  appearance: none;
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: #F1B650;
  pointer-events: auto; }

.range-input-item[type="range"]:active::-webkit-slider-thumb {
  background-color: #ffffff;
  border: 0.1rem solid #F1B650; }

.values {
  position: relative;
  margin: auto;
  padding-top: 1.5rem;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between; }

.btrn-cate {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; }

#ez-toc-container {
  margin: 2rem 0; }

.about-wr {
  margin: 2rem 0;
  position: relative;
  overflow: hidden; }

@media screen and (max-width: 1200px) {
  .about-col:nth-child(1) {
    margin-bottom: 0; } }

@media screen and (max-width: 1200px) {
  .about-col {
    width: 100%; } }

.about-bdy {
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 0.6rem; }
  @media screen and (max-width: 800px) {
    .about-bdy {
      padding: 1rem 0; } }

.about-tapnel {
  min-height: 30vh;
  padding: 2rem; }
  @media screen and (max-width: 800px) {
    .about-tapnel {
      padding: 1rem 0; } }
  .about-tapnel-item {
    display: none; }
  .about-tapnel .title-second {
    font-size: 3.2rem;
    font-weight: 600; }
    @media only screen and (max-width: 1200px) {
      .about-tapnel .title-second {
        font-size: 2.7rem; } }
    @media only screen and (max-width: 800px) {
      .about-tapnel .title-second {
        font-size: 2.2rem; } }
  .about-tapnel-des-list {
    list-style: decimal; }
  .about-tapnel-des-item {
    font-size: 2rem;
    color: #383838;
    font-weight: 600; }
    @media only screen and (max-width: 1200px) {
      .about-tapnel-des-item {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 800px) {
      .about-tapnel-des-item {
        font-size: 1.6rem; } }
    .about-tapnel-des-item:not(:last-child) {
      margin-bottom: 1.2rem; }
    .about-tapnel-des-item .txt {
      font-size: 1.6rem;
      font-weight: normal; }
      .about-tapnel-des-item .txt.italic {
        font-style: italic; }
    .about-tapnel-des-item > img {
      width: 100%;
      margin-bottom: 1.2rem; }
    .about-tapnel-des-item .link {
      font-size: 1.6rem;
      font-weight: 500;
      color: #2E3192;
      text-decoration: underline; }
    .about-tapnel-des-item .list {
      list-style: disc;
      margin-bottom: 1.2rem;
      padding-left: 2rem; }
  .about-tapnel .hisThumSwiper {
    padding: 4rem 0; }
    @media screen and (max-width: 800px) {
      .about-tapnel .hisThumSwiper {
        padding: 2rem 0; } }
    .about-tapnel .hisThumSwiper .swiper-slide {
      position: relative;
      overflow: visible;
      --wh: 3.2rem; }
      .about-tapnel .hisThumSwiper .swiper-slide.active .his-icon img, .about-tapnel .hisThumSwiper .swiper-slide.swiper-slide-thumb-active .his-icon img {
        filter: brightness(0) saturate(100%) invert(17%) sepia(38%) saturate(4093%) hue-rotate(228deg) brightness(92%) contrast(94%); }
      .about-tapnel .hisThumSwiper .swiper-slide.active .his-icon::before, .about-tapnel .hisThumSwiper .swiper-slide.swiper-slide-thumb-active .his-icon::before {
        background-color: #2E3192; }
      .about-tapnel .hisThumSwiper .swiper-slide.active .his-num, .about-tapnel .hisThumSwiper .swiper-slide.swiper-slide-thumb-active .his-num {
        color: #2E3192; }
      .about-tapnel .hisThumSwiper .swiper-slide:last-child::before {
        display: none; }
      @media screen and (max-width: 600px) {
        .about-tapnel .hisThumSwiper .swiper-slide {
          --wh: 2.8rem; } }
      .about-tapnel .hisThumSwiper .swiper-slide::before {
        position: absolute;
        content: '';
        top: calc(var(--wh)/2);
        left: calc(100% - var(--wh)/2);
        height: .2rem;
        width: 5rem;
        background-color: #CBD5E1;
        pointer-events: none; }
  @media screen and (min-width: 601px) {
    .about-tapnel .hisSwiper .swiper-pagination {
      display: none; } }
  .about-tapnel .hisSwiper .swiper-next {
    right: 1rem; }
  .about-tapnel .hisSwiper .swiper-prev {
    left: auto;
    right: calc(2rem + var(--wh)); }
  .about-tapnel .hisSwiper .swiper-next,
  .about-tapnel .hisSwiper .swiper-prev {
    border: 0.1rem solid #F1B650;
    --wh: 4rem;
    width: var(--wh);
    height: var(--wh);
    background-color: #ffffff;
    top: 0;
    border-radius: 0.4rem;
    transform: translate(0, 0);
    z-index: 1; }
    @media screen and (max-width: 800px) {
      .about-tapnel .hisSwiper .swiper-next,
      .about-tapnel .hisSwiper .swiper-prev {
        --wh: 3.5rem; } }
    @media screen and (max-width: 600px) {
      .about-tapnel .hisSwiper .swiper-next,
      .about-tapnel .hisSwiper .swiper-prev {
        display: none; } }
    .about-tapnel .hisSwiper .swiper-next.swiper-button-disabled,
    .about-tapnel .hisSwiper .swiper-prev.swiper-button-disabled {
      pointer-events: none;
      opacity: .4; }
    .about-tapnel .hisSwiper .swiper-next i,
    .about-tapnel .hisSwiper .swiper-prev i {
      color: #F1B650;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }
    .about-tapnel .hisSwiper .swiper-next::after,
    .about-tapnel .hisSwiper .swiper-prev::after {
      position: absolute;
      right: 0;
      left: auto;
      top: 0;
      bottom: 0;
      width: 0;
      height: 100%;
      background-color: #F1B650;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      border: none;
      border-radius: 0; }
    .about-tapnel .hisSwiper .swiper-next::before,
    .about-tapnel .hisSwiper .swiper-prev::before {
      display: none; }
    .about-tapnel .hisSwiper .swiper-next:hover i,
    .about-tapnel .hisSwiper .swiper-prev:hover i {
      color: #ffffff; }
    .about-tapnel .hisSwiper .swiper-next:hover::after,
    .about-tapnel .hisSwiper .swiper-prev:hover::after {
      left: 0;
      width: 100%;
      transform: translate(0, 0); }
  .about-tapnel .his-gr {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem; }
  .about-tapnel .his-icon {
    --wh: 3.2rem;
    width: var(--wh);
    height: var(--wh);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    position: relative; }
    @media screen and (max-width: 600px) {
      .about-tapnel .his-icon {
        --wh: 2.8rem; } }
    .about-tapnel .his-icon img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }
  .about-tapnel .his-num {
    position: relative;
    color: #9A9999;
    font-size: 1.8rem;
    font-weight: 500;
    text-align: center;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    pointer-events: none; }
    @media only screen and (max-width: 1200px) {
      .about-tapnel .his-num {
        font-size: 1.6rem; } }
    @media only screen and (max-width: 800px) {
      .about-tapnel .his-num {
        font-size: 1.5rem; } }
  .about-tapnel .his-inner {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    gap: 2rem; }
    @media screen and (max-width: 800px) {
      .about-tapnel .his-inner {
        gap: 1rem; } }
    @media screen and (max-width: 600px) {
      .about-tapnel .his-inner {
        flex-direction: column; } }
  .about-tapnel .his-img {
    width: 36.4rem; }
    @media screen and (max-width: 800px) {
      .about-tapnel .his-img {
        width: 26.4rem; } }
    @media screen and (max-width: 600px) {
      .about-tapnel .his-img {
        width: 100%; } }
    .about-tapnel .his-img-inner {
      width: 100%;
      border-radius: 1rem;
      display: block;
      padding-top: calc((292 / 364) * 100%);
      position: relative;
      overflow: hidden; }
      @media screen and (max-width: 600px) {
        .about-tapnel .his-img-inner {
          display: block;
          padding-top: calc((240 / 364) * 100%);
          position: relative;
          overflow: hidden; } }
    .about-tapnel .his-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .about-tapnel .his-des {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 1; }
    @media screen and (max-width: 800px) {
      .about-tapnel .his-des {
        gap: 1rem; } }
  .about-tapnel .his-year {
    font-size: 4.2rem;
    color: #2E3192;
    font-weight: 600;
    line-height: 120%;
    text-transform: uppercase;
    font-family: var(--font-pri); }
    @media only screen and (max-width: 1200px) {
      .about-tapnel .his-year {
        font-size: 3.5rem; } }
    @media only screen and (max-width: 800px) {
      .about-tapnel .his-year {
        font-size: 2.7rem; } }
  .about-tapnel .his-tt {
    font-size: 2.4rem;
    font-weight: 600;
    color: #383838;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media only screen and (max-width: 1200px) {
      .about-tapnel .his-tt {
        font-size: 2rem; } }
    @media only screen and (max-width: 800px) {
      .about-tapnel .his-tt {
        font-size: 1.7rem; } }
  .about-tapnel .his-txt {
    color: #6F6D6D;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media screen and (max-width: 1000px) {
      .about-tapnel .his-txt {
        font-size: 1.4rem; } }
    @media screen and (max-width: 400px) {
      .about-tapnel .his-txt {
        font-size: 1.2rem; } }

.about-file .title-second {
  font-size: 3.2rem;
  font-weight: 600; }
  @media only screen and (max-width: 1200px) {
    .about-file .title-second {
      font-size: 2.7rem; } }
  @media only screen and (max-width: 800px) {
    .about-file .title-second {
      font-size: 2.2rem; } }

.about-file-list {
  list-style: decimal; }

.about-file-item {
  font-size: 2rem;
  color: #383838;
  font-weight: 600; }
  @media only screen and (max-width: 1200px) {
    .about-file-item {
      font-size: 1.8rem; } }
  @media only screen and (max-width: 800px) {
    .about-file-item {
      font-size: 1.6rem; } }

.about-file-img {
  display: block;
  padding-top: calc((572 / 426) * 100%);
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 1rem 0;
  cursor: pointer; }
  .about-file-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain; }

.about-file .list {
  justify-content: center; }

.about-file .item .inner {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2.4rem;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  border-radius: 0.4rem;
  border: 0.1rem solid #EDF0F2; }
  .about-file .item .inner:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; }
  .about-file .item .inner .img {
    --wh: 8rem;
    width: var(--wh);
    height: var(--wh);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center; }
    @media screen and (max-width: 800px) {
      .about-file .item .inner .img {
        --wh: 6rem; } }
    .about-file .item .inner .img img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain; }
  .about-file .item .inner .tt {
    font-size: 1.6rem;
    font-family: var(--font-sec);
    font-weight: 700;
    color: #2E3192;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase; }

.about-service {
  position: relative;
  background-color: #ffffff; }
  .about-service .inner {
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column; }
  .about-service-img {
    width: 100%;
    display: block;
    padding-top: calc((254 / 384) * 100%);
    position: relative;
    overflow: hidden;
    border-radius: 0.4rem; }
    @media screen and (max-width: 1000px) {
      .about-service-img {
        margin: 0 auto 0;
        width: 100%;
        box-shadow: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0; } }
    .about-service-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .about-service-row {
    justify-content: center; }
  .about-service-des {
    position: relative;
    z-index: 1;
    padding: 1.6rem;
    box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
    background-color: #F5F6F7;
    border-radius: 0.4rem;
    border: 0.1rem solid #EDF0F2;
    width: 85%;
    margin: -7rem auto 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: .8rem;
    height: 100%; }
    @media screen and (max-width: 1000px) {
      .about-service-des {
        margin: 0 auto 0;
        width: 100%;
        box-shadow: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0; } }
    @media screen and (max-width: 800px) {
      .about-service-des {
        padding: 1rem; } }
    .about-service-des .title-second {
      text-transform: unset;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #2E3192;
      font-family: var(--font-pri);
      font-weight: 700; }
    .about-service-des .txt {
      font-family: var(--font-pri);
      font-weight: 400;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; }
  @media screen and (max-width: 800px) {
    .about-service-col {
      width: 50%; } }
  @media screen and (max-width: 450px) {
    .about-service-col {
      width: 100%; } }

@media screen and (max-width: 600px) {
  .about-intro-col {
    width: 100%; } }

.about-intro-col .inner {
  background-color: #ffffff;
  height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.2rem;
  border-radius: 0.8rem;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1), 0px 4px 6px -1px rgba(16, 24, 40, 0.1); }
  @media screen and (max-width: 1200px) {
    .about-intro-col .inner {
      padding: 0; } }

.about-intro-col .icon {
  --wh: 8rem;
  width: var(--wh);
  height: var(--wh);
  flex-shrink: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin-bottom: 2.2rem;
  border: 0.2rem solid #2E3192;
  border-radius: 100rem;
  background-color: #ffffff; }
  @media screen and (max-width: 800px) {
    .about-intro-col .icon {
      --wh: 6rem;
      margin-bottom: 1.2rem; } }
  .about-intro-col .icon img {
    --wh: 3.2rem;
    width: var(--wh);
    height: var(--wh);
    flex-shrink: 0;
    object-fit: contain;
    display: block; }

.about-intro-col .tt {
  font-weight: bold;
  font-size: 2.8rem;
  line-height: 120%;
  color: #2E3192;
  margin-bottom: .8rem; }
  @media only screen and (max-width: 1200px) {
    .about-intro-col .tt {
      font-size: 2.4rem; } }
  @media only screen and (max-width: 800px) {
    .about-intro-col .tt {
      font-size: 2.1rem; } }

.about-intro-col .mona-content p, .about-intro-col .mona-content span {
  font-size: 1.6rem;
  font-weight: normal;
  color: #514F4F; }

.pros-side-open {
  position: absolute;
  --wh: 2rem;
  left: calc(-1 * var(--wh) - .2rem);
  top: 20%;
  background-color: #ffffff;
  border-bottom-left-radius: 0.6rem;
  border-top-left-radius: 0.6rem;
  border: 0.2rem solid #2E3192;
  border-right: none;
  z-index: 1; }
  .pros-side-open.active .icon i {
    transform: rotate(180deg); }
  .pros-side-open .icon {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    width: var(--wh);
    padding: 1rem 0;
    cursor: pointer; }
    .pros-side-open .icon i {
      font-size: 1.4rem;
      color: #2E3192;
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out; }
  @media screen and (min-width: 1201px) {
    .pros-side-open {
      display: none; } }

@media screen and (max-width: 1200px) {
  .side {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    bottom: 0;
    z-index: 99;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
    .side.active {
      width: 100%;
      left: 0; } }

.side-overlay {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out; }
  .side-overlay.active {
    width: 100%; }

.side-wr {
  height: 100%;
  max-width: 100%;
  --w: 26rem;
  min-width: var(--w);
  width: 100%;
  background-color: #ffffff;
  padding: 2rem; }
  @media screen and (max-width: 1200px) {
    .side-wr {
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      bottom: 0;
      width: var(--w);
      z-index: 99;
      transform: translateX(100%);
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      border-left: 0.2rem solid #2E3192;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-direction: column; }
      .side-wr.active {
        transform: translateX(0); } }

.side-inner {
  height: 100%; }

.side-head {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 0.2rem solid #2E3192; }
  .side-head .txt {
    color: #2E3192;
    font-weight: 700; }
  .side-head .icon {
    cursor: pointer;
    color: #2E3192; }
  @media screen and (min-width: 1201px) {
    .side-head {
      display: none; } }

@media screen and (max-width: 1200px) {
  .side-bdy {
    flex: 1;
    height: 100%;
    max-height: 100%;
    overflow: hidden auto; }
    .side-bdy::-webkit-scrollbar {
      width: .5rem; }
    .side-bdy::-webkit-scrollbar-thumb {
      background-image: linear-gradient(#2E3192, #2E3192);
      border-radius: 50px; } }

.side-item.active .head {
  pointer-events: none; }
  .side-item.active .head .icon i {
    transform: rotate(0); }

.side-item .head {
  padding: .8rem 0 .8rem 1.6rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; }
  @media screen and (max-width: 1200px) {
    .side-item .head {
      padding: .8rem 0 .8rem 1rem; } }
  .side-item .head .tt {
    color: #1E1E1E;
    font-weight: 600; }
  .side-item .head .icon {
    font-size: 1.4rem; }
    .side-item .head .icon i {
      transition: 0.3s ease-in-out;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      -ms-transition: 0.3s ease-in-out;
      -o-transition: 0.3s ease-in-out;
      transform: rotate(180deg); }

.recheck-block {
  padding: 0 1.6rem; }
  @media screen and (max-width: 1200px) {
    .recheck-block {
      padding: 0; } }

.recheck-item {
  padding: .8rem 0 .8rem 2rem;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  gap: 1rem;
  cursor: pointer; }
  .recheck-item:hover .txt,
  .recheck-item:hover .icon, .recheck-item.active .txt,
  .recheck-item.active .icon {
    color: #F1B650; }
  .recheck-item .txt {
    font-size: 1.6rem;
    font-family: var(--font-sec);
    font-weight: 500;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out; }
  .recheck-item .icon {
    margin-top: .1rem;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -ms-align-items: center;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    font-size: 1rem;
    transition: 0.3s ease-in-out;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    color: #4B4B4B; }
