.footer{
    position: relative;
    overflow: hidden;
    padding: 3.2rem 0;
    @include max(1200px){
        padding: 1.6rem 0;
        margin-top: 1.6rem;
    }
    &-col{
        width: 20%;
        @include max(1000px){
            width: calc(4/12 * 100%);
        }
        @include max(700px){
            width: calc(6/12 * 100%);
        }
        @include max(400px){
            width: 100%;
        }
        .item{
            @include flex;
            align-items: flex-start;
            gap: .4rem;
            @include fz-16;
            font-weight: 400;
            color: $color-text-1;
            @include max(800px){
                @include fz-14;
            }
            .icon{
                width: 2rem;
                height: 2rem;
                flex-shrink: 0;
                @include mid-flex;
                margin-top: .2rem;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    display: block;
                }
            }
        }
    }
    &-top{
        @include mid-flex;
      margin-bottom: 6.4rem;
      @include max(1200px){
        margin-bottom: 3.2rem;
      }
    }
    &-main{
        border-bottom: .1rem solid $color-border;
        padding-bottom: 6.4rem;
        @include max(1200px){
            padding-bottom: 3.2rem;
        }
    }
    &-author{
        .list{
            @include mid-flex;
        }
        .item{
            @include mid-flex;
            flex-wrap: wrap;
            text-align: center;
            gap: 1rem;
            @include fz-14;
            font-family: $font-pri;
            font-weight: normal;
            color: $color-text-1;
            img{
                height: 1.1rem;
            }
        }
   
    }
    &-logo{
        width: 15rem;
        &-link{
            @include flex;
            width: 100%;
            img{
                width: 100%;
                object-fit: contain;
                display: block;
            }
        }
    }
    &-title{
        @include fz-16;
    color: $color-pri;
    text-wrap: pretty;
        @include max(500px){
            margin-bottom: 1rem !important;
            @include fz-14;
        }
    }

    &-social{
        @include mid-flex;
        margin-bottom: 3.2rem;
        @include max(1200px){
            margin-bottom: 1.6rem
        }
        &-list{
            @include flex;
            align-items: center;
            gap: 1.6rem;
        }
        &-item{
            @include mid-flex;
            flex-shrink: 0;
            @include transitionRe;
            &:hover{
                transform: translateY(-.3rem);
            }
        }
        &-link{
            width: 3.2rem;
            height: 3.2rem;
            border-radius: $radius-1;
            overflow: hidden;
            @include mid-flex;
            @include max(600px){
                width: 2.8rem;
                height: 2.8rem;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                display: block;
            }
        }
    }
    
}