.back-to-top {
    --wh: 5rem;
    width: var(--wh);
    height: var(--wh);
    border-radius: $radius-9;
    background-color: $color-white;
    border: .1rem solid $color-pri;
    position: fixed;
    bottom: 6rem;
    right: 3rem;
    z-index: 7;
    transform: translateX(3rem);
    opacity: 0;
    transition: 0.5s linear;
    cursor: pointer;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    @include max(1200px){
        --wh: 4rem;
    }
    &:hover {
        background-color: $color-pri;
        color: $color-white;
    }

    i {
        @include fz-20;

        @include max(600px) {}
    }

    @include max(768px) {
        right: 1.5rem;
    }

    &.active {
        visibility: visible;
        transform: translateX(0);
        opacity: 1;
    }

    .triangle {
        display: block;
        width: 0;
        height: 0;
        z-index: 2;
        border: 8px solid transparent;
        border-bottom: 6px solid #fff;
        margin-top: -6px;
        border-radius: 5px;

        &:nth-child(1) {
            animation: BTT 1.5s linear 0.6s infinite;
        }

        &:nth-child(2) {
            animation: BTT 1.5s linear 0.3s infinite;
        }

        &:nth-child(3) {
            animation: BTT 1.5s linear 0s infinite;
        }
    }

    &:hover {
        @media only screen and (min-width: 901px) {
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
            // background-color: $c-yel;
        }
    }
}

.btn-fct {
    position: fixed;
    bottom: 13rem;
    right: 3rem;
    @include flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    z-index: 10;
    width: 5rem;
    @include max(1200px){
        width: 4rem;
        gap: 2.5rem;
        bottom: 12rem;
    }
    @include max(768px) {
        right: 1.5rem;
    }
    &-icon {
        position: relative;
        @include mid-flex;
        .icon{
            border-radius: 100%;
            width: 3rem;
            height: 3rem;
            @include mid-flex;
            animation: phone-fn 2s infinite ease-in-out;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
        }
        &:nth-child(1){
            .icon{
                background-color: $color-second;
            }
            &::before{
                background-color: $color-second;
            }
            &::after{
                background-color: $color-second;
            }
        }
        &:nth-child(2),
        &:nth-child(3){
            .icon{
                background-color: $color-white;
            }
            &::before{
                background-color: $color-pri;
                opacity: .1;
            }
            &::after{
                background-color: $color-pri;
                opacity: .2;
            }
        }
        &::before{
            content: '';
            position: absolute;
            border-radius: 100rem;
            position: absolute;
            left: -.5rem;
            bottom: -.5rem;
            right: -.5rem;
            top: -.5rem;
            animation: pin 4s linear 0.4s infinite normal;
            z-index: -1;
        }
        &::after{
            content: '';
            position: absolute;
            border-radius: 100rem;
            position: absolute;
            left: -1rem;
            bottom: -1rem;
            right: -1rem;
            top: -1rem;
            animation: pin 4s linear 0.4s infinite normal;
            z-index: -1;
        }
    }
}