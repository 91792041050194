.btn {
    &-pri {
        position: relative;
        background-color: $color-pri;
        border: .1rem solid $color-pri;
        color: $color-white;
        border-radius: $radius-1;
        overflow: hidden;
        transition: all .3s ease;
        z-index: 1;
        @include mid-flex;
        align-items: center;
        gap: 1.2rem;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        font-family: var(--font-pri);
        font-weight: 600;
        @include fz-16;
        @include max(1000px){
            gap: .6rem
        }
        @include max(600px) {
            @include fz-14;
        }


        &::before {
            position: absolute;
            content: '';
            right: 0;
            width: 0;
            top: 0;
            bottom: 0;
            background-color: $color-white;
            transition: all .3s ease;
            z-index: -1;
        }
        .icon {
            width: 1.8rem;
            height: 1.8rem;
            border-radius: $radius-9;
            @include mid-flex;
            position: relative;
            i{
                transform: translateX(-.5rem);
            @include transitionRe;

            }
            &::before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                border-width: .3rem;
                border-style: solid;
                border-left: $color-white;
                border-top: $color-white;
                border-bottom: $color-white;
                border-radius: $radius-9;
            @include transitionRe;

            }
        }
        img {
            @include transitionRe;
        }

        &:hover {
            color: $color-pri;
            border: .1rem solid $color-pri;
            .icon{
                i{
                    transform: translate(.5rem);
                }
                &::before{
                    transform: rotate(180deg);
                }
            }
            img {
                filter: brightness(0) saturate(100%) invert(22%) sepia(40%) saturate(3648%) hue-rotate(339deg) brightness(87%) contrast(114%);
            }

            &::before {
                left: 0;
                width: 100%;
                background-color: $color-white;
            }
        }
    }

    &-sec {
        position: relative;
        background-color: $color-second;
        border: .1rem solid $color-second;
        color: $color-text;
        border-radius: $radius-1;
        overflow: hidden;
        transition: all .3s ease;
        z-index: 1;
        @include mid-flex;
        align-items: center;
        gap: 1.2rem;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        font-family: var(--font-pri);
        font-weight: 600;
        @include fz-16;
        @include max(1000px){
            gap: .6rem
        }
        @include max(600px) {
            @include fz-14;
        }
        &::before {
            position: absolute;
            content: '';
            right: 0;
            width: 0;
            top: 0;
            bottom: 0;
            background-color: $color-white;
            transition: all .3s ease;
            z-index: -1;
        }

        .icon {
            width: 1.8rem;
            height: 1.8rem;
            border-radius: $radius-9;
            @include mid-flex;
            position: relative;
            i{
                transform: translateX(-.5rem);
            @include transitionRe;

            }
            &::before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                border-width: .3rem;
                border-style: solid;
                border-left: $color-white;
                border-top: $color-white;
                border-bottom: $color-white;
                border-radius: $radius-9;
            @include transitionRe;

            }
        }
        img {
            @include transitionRe;
        }

        &:hover {
            color: $color-second;
            border: .1rem solid $color-second;
            .icon{
                i{
                    transform: translate(.5rem);
                }
                &::before{
                    transform: rotate(180deg);
                }
            }
            img {
                filter: brightness(0) saturate(100%) invert(25%) sepia(80%) saturate(1381%) hue-rotate(190deg) brightness(97%) contrast(92%);
            }

            &::before {
                left: 0;
                width: 100%;
                background-color: $color-white;
            }
        }
    }
    &-white {
        position: relative;
        background-color: $color-white;
        border: .1rem solid $color-pri;
        color: $color-pri;
        border-radius: $radius-1;
        overflow: hidden;
        transition: all .3s ease;
        z-index: 1;
        @include mid-flex;
        align-items: center;
        gap: 1.2rem;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        font-family: var(--font-pri);
        font-weight: 600;
        @include fz-16;
        @include max(1000px){
            gap: .6rem
        }
        @include max(600px) {
            @include fz-14;
        }
        &::before {
            position: absolute;
            content: '';
            right: 0;
            width: 0;
            top: 0;
            bottom: 0;
            background-color: $color-pri;
            transition: all .3s ease;
            z-index: -1;
        }
        .icon{
            width: 1.8rem;
            height: 1.8rem;
            border-radius: $radius-9;
            @include mid-flex;
            position: relative;
            i{
                transform: translateX(-.5rem);
            @include transitionRe;
    
            }
            &::before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                border-width: .3rem;
                border-style: solid;
                border-left: $color-white;
                border-top: $color-white;
                border-bottom: $color-white;
                border-radius: $radius-9;
            @include transitionRe;
    
            }
        }

        img {
            @include transitionRe;
        }

        &:hover {
            color: $color-white;
            .icon{
                i{
                    transform: translate(.5rem);
                }
                &::before{
                    transform: rotate(180deg);
                }
            }
            img {
                filter: brightness(0) saturate(100%) invert(25%) sepia(80%) saturate(1381%) hue-rotate(190deg) brightness(97%) contrast(92%);
            }

            &::before {
                left: 0;
                width: 100%;
            }
        }
    }
    &-pad {
        &-pri {
            padding: 1.2rem 2.4rem;

            @include max(800px) {
                padding: .9rem 1.8rem;
            }
        }
    }

    &-rad {
        &-1 {
            border-radius: $radius-1;
        }

        &-2 {
            border-radius: $radius-2;
        }

        &-3 {
            border-radius: $radius-3;
        }

        &-4 {
            border-radius: $radius-4;
        }

        &-5 {
            border-radius: $radius-5;
        }

        &-6 {
            border-radius: $radius-6;
        }

        &-7 {
            border-radius: $radius-7;
        }

        &-8 {
            border-radius: $radius-8;
        }
        &-9 {
            border-radius: $radius-9;
        }
    }
    &-rowR{
        flex-direction: row-reverse;
        .icon{
            i{
                transform: translateX(0);
                transition: all 0s ease;
            }
        }
        &:hover{
            .icon{
                i{
                    transform: translateX(0);
                }
            }
        }
    }
    &-nonBf{
        .icon{
            &::before{
                display: none;
            }
        }
    }
    &-center {
        @include mid-flex;
    }


}