.mb-0 {
    margin-bottom: 0 !important;
}

.mb-2 {
    margin-bottom: 2px;
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-6 {
    margin-bottom: 6px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-10s {
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-12s {
    &:not(:last-child) {
        margin-bottom: 12px;
    }
}

.mb-14 {
    margin-bottom: 14px;
}

.mb-14s {
    &:not(:last-child) {
        margin-bottom: 14px;
    }
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-15s {
    &:not(:last-child) {
        margin-bottom: 15px;
    }
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-16s {
    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.mb-18 {
    margin-bottom: 18px;
}

.mb-18s {
    &:not(:last-child) {
        margin-bottom: 18px;
    }
}

.mb-20 {
    .mb-30 {
        margin-bottom: 30px;
    }
    .mb-30s {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    margin-bottom: 20px;
}

.mb-20s {
    &:not(:last-child) {
        margin-bottom: 20px;
    }
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-24s {
    &:not(:last-child) {
        margin-bottom: 24px;
    }
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-25s {
    &:not(:last-child) {
        margin-bottom: 25px;
    }
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-30s {
    &:not(:last-child) {
        margin-bottom: 30px;
        
    }
}

.mb-32 {
    margin-bottom: 32px;
    @include max($res-mobile){
        margin-bottom: 1.6rem;
    }
}

.mb-32s {
    &:not(:last-child) {
        margin-bottom: 32px;
        @include max($res-mobile){
            margin-bottom: 1.6rem;
        }
    }
}

.mb-40 {
    margin-bottom: 40px;
    @include max($res-mobile){
        margin-bottom: 2rem;
    }
}

.mb-40s {
    &:not(:last-child) {
        margin-bottom: 40px;
        @include max($res-mobile){
            margin-bottom: 2rem;
        }
    }
}

.mb-48 {
    margin-bottom: 48px;
    @include max($res-mobile){
        margin-bottom: 2.4rem;
    }
}

.mb-48s {
    &:not(:last-child) {
        margin-bottom: 48px;
    }
}

.mb-56 {
    margin-bottom: 56px;
    @include max($res-mobile){
        margin-bottom: 2.8rem;
    }
}

.mb-56s {
    &:not(:last-child) {
        margin-bottom: 56px;
        @include max($res-mobile){
            margin-bottom: 2.8rem;
        }
    }
}
.mb-60 {
    margin-bottom: 6rem;
    @include max($res-mobile){
        margin-bottom: 3rem;
    }
}
.mb-64 {
    margin-bottom: 64px;
    @include max($res-mobile){
        margin-bottom: 3.2rem;
    }
}

.mb-64s {
    &:not(:last-child) {
        margin-bottom: 64px;
        @include max($res-mobile){
            margin-bottom: 3.2rem;
        }
    }
}

.mb-72 {
    margin-bottom: 72px;
    @include max($res-mobile){
        margin-bottom: 3.6rem;
    }
}

.mb-72s {
    &:not(:last-child) {
        margin-bottom: 72px;
        @include max($res-mobile){
            margin-bottom: 3.6rem;
        }
    }
}

.mb-80 {
    margin-bottom: 80px;
    @include max($res-mobile){
        margin-bottom: 4rem;
    }
}

.mb-80s {
    &:not(:last-child) {
        margin-bottom: 80px;
        @include max($res-mobile){
            margin-bottom: 4rem;
        }
    }
}

.ss-mg {
    margin: 80px 0;
    @include max($res-mobile) {
        margin: 40px 0;
    }
}

.ss-mg-t {
    margin-top: 80px;
    @include max($res-mobile) {
        margin-top: 40px;
    }
}
.ss-mg-t-40 {
    margin-top: 40px;
    @include max($res-mobile) {
        margin-top: 20px;
    }
}
.ss-mg-b {
    margin-bottom: 80px;
    @include max($res-mobile) {
        margin-bottom: 40px;
    }
}

.pb-20{
    padding-bottom: 2rem;
}
.mg-40{
    margin: 4rem 0;
}
.mg-20{
    margin: 2rem 0;
}
