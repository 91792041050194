.about {
    &-wr {
        margin: 2rem 0;
        position: relative;
        overflow: hidden;
    }

    &-col {
        &:nth-child(1) {
            @include max(1200px) {
                margin-bottom: 0;

            }
        }

        @include max(1200px) {
            width: 100%;
        }
    }

    &-bdy {
        margin-bottom: 2rem;
        padding: 2rem;
        background-color: $color-white;
        border-radius: $radius-2;

        @include max(800px) {
            padding: 1rem 0;
        }
    }

    &-tapnel {
        min-height: 30vh;
        padding: 2rem;

        @include max(800px) {
            padding: 1rem 0;
        }

        &-item {
            display: none;
        }
        .title-second{
            @include fz-32;
            font-weight: 600;
        }
        &-des {
            &-list {
                list-style: decimal;
            }

            &-item {
                @include fz-20;
                color: $color-text-4;
                font-weight: 600;

                &:not(:last-child) {
                    margin-bottom: 1.2rem;
                }

                .txt {
                    @include fz-16;
                    font-weight: normal;

                    &.italic {
                        font-style: italic;
                    }
                }

                >img {
                    width: 100%;
                    margin-bottom: 1.2rem
                }

                .link {
                    @include fz-16;
                    font-weight: 500;
                    color: $color-pri;
                    text-decoration: underline;
                }

                .list {
                    list-style: disc;
                    margin-bottom: 1.2rem;
                    padding-left: 2rem;
                }
            }
        }
        .hisThumSwiper{
            padding: 4rem 0;
            @include max(800px){
                padding: 2rem 0;
            }
            // .swiper-slide.swiper-slide-thumb-active ~ .swiper-slide-visible{
            //     .his-icon{
            //         img{
            //             filter: brightness(0) saturate(100%) invert(17%) sepia(38%) saturate(4093%) hue-rotate(228deg) brightness(92%) contrast(94%);
            //         }
            //         &::before{
            //             background-color: $color-pri;
            //         }
            //     }
            //     .his-num{
            //         color: $color-pri;
            //     }
            // }
            .swiper-slide{
                position: relative;
                overflow: visible;
                --wh: 3.2rem;
                &.active,
                &.swiper-slide-thumb-active{
                    .his-icon{
                        img{
                            filter: brightness(0) saturate(100%) invert(17%) sepia(38%) saturate(4093%) hue-rotate(228deg) brightness(92%) contrast(94%);
                        }
                        &::before{
                            background-color: $color-pri;
                        }
                    }
                    .his-num{
                        color: $color-pri;
                    }
                }
                &:last-child{
                    &::before{
                        display: none;
                    }
                }
                @include max(600px){
                    --wh: 2.8rem;
                }
                &::before{
                    position: absolute;
                    content: '';
                    top: calc(var(--wh)/2);
                    left: calc(100% - var(--wh)/2);
                    height: .2rem;
                    width: 5rem;
                    background-color: $color-bg-4;
                    pointer-events: none;
                }
            }
        }
        .hisSwiper{
            .swiper-pagination{
                @include min(601px){
                    display: none;
                }
            }
            .swiper-next{
                right: 1rem;
          
            }
            .swiper-prev{
                left: auto;
                right: calc(2rem + var(--wh));
            
            }
            .swiper-next,
            .swiper-prev{
                border: .1rem solid $color-second;
                --wh: 4rem;
                width: var(--wh);
                height: var(--wh);
                background-color: $color-white;
                top: 0;
                border-radius: $radius-1;
                transform: translate(0,0);
                z-index: 1;
                @include max(800px){
                    --wh: 3.5rem;
                }
                @include max(600px){
                   display: none;
                }
                &.swiper-button-disabled{
                    pointer-events: none;
                    opacity: .4;
                }
                i{
                    color: $color-second;
                    @include transitionRe;
                }
                &::after{
                    position: absolute;
                    right: 0;
                    left: auto;
                    top: 0;
                    bottom: 0;
                    width: 0;
                    height: 100%;
                    background-color: $color-second;
                    @include transitionRe;
                    border: none;
                    border-radius: 0;
                }
                &::before{
                    display: none;
                }
                &:hover{
                    i{
                        color: $color-white;
                    }
                    &::after{
                        left: 0;
                        width: 100%;
                        transform: translate(0,0)
                    }
                }
            }
        }
        .his{
            &-gr{
                @include flex;
                flex-direction: column;
                align-items: center;
                gap: 1rem;
            }
            &-icon{
                --wh: 3.2rem;
                width: var(--wh);
                height: var(--wh);
                @include mid-flex;
                cursor: pointer;
                position: relative;
                @include max(600px){
                    --wh: 2.8rem;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    display: block;
                    @include transitionRe;
                    // filter: brightness(0) saturate(100%) invert(17%) sepia(38%) saturate(4093%) hue-rotate(228deg) brightness(92%) contrast(94%);
                }
            }
            &-num{
                position: relative;
                color: $color-text-7;
                @include fz-18;
                font-weight: 500;
                text-align: center;
                @include transitionRe;
                pointer-events: none;
            }
            &-inner{
                @include flex;
                gap: 2rem;
                @include max(800px){
                    gap: 1rem;
                }
                @include max(600px){
                    flex-direction: column;
                }  
            }
            &-img{
                width: 36.4rem;
                @include max(800px){
                    width: 26.4rem;
                }
                @include max(600px){
                    width: 100%;
                }  
                &-inner{
                    width: 100%;
                    border-radius: $radius-4;
                    @include imgPd(292,364);
                    @include max(600px){
                    @include imgPd(240,364);
                    }
                }
                img{
                    @include imgCover;
                }
            }
            &-des{
                @include flex;
                flex-direction: column;
                gap: 2rem;
                flex: 1;
                @include max(800px){
                    gap: 1rem;
                }
            }
            &-year{
                @include fz-42;
                color: $color-pri;
                font-weight: 600;
                line-height: 120%;
                text-transform: uppercase;
                font-family: var(--font-pri);
            }
            &-tt{
                @include fz-24;
                font-weight: 600;
                color: $color-text-4;
                @include elp-2;
            }
            &-txt{
                color: $color-text-1;
                font-weight: 400;   
                @include elp-7;  
                @include max(1000px){
                    @include fz-14;
                }        
                @include max(400px){
                    @include fz-12;
                }       
            }
        }
      
    }
    &-file{
        .title-second{
            @include fz-32;
            font-weight: 600;
        }
        &-list{
            list-style: decimal;
        }
        &-item{
            @include fz-20;
            color: $color-text-4;
            font-weight: 600;
        }
        &-img{
            @include imgPd(572,426);
            width: 100%;
            margin: 1rem 0;
            cursor: pointer;
            img{
                @include imgContain;
            }
        }
        .list{
            justify-content: center;
        }
        .item{
            .inner{
                @include flex;
                flex-direction: column;
                align-items: center;
                gap: 2rem;
                padding: 2.4rem;
                @include transitionRe;
                border-radius: $radius-1;
                border: .1rem solid $color-border-3;
                &:hover{
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                }
                .img{
                    --wh: 8rem;
                    width: var(--wh);
                    height: var(--wh);
                    @include mid-flex;
                    @include max(800px){
                        --wh: 6rem;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        display: block;
                        object-fit: contain;
                    }
                }
                .tt{
                    @include fz-16;
                    font-family: var(--font-sec);
                    font-weight: 700;
                    color: $color-pri;
                    text-align: center;
                    @include elp-2;
                    text-transform: uppercase;
                }
            }
        }
    }
    &-service {
        position: relative;
        background-color: $color-white;

        .inner {
            height: 100%;
            @include flex;
            flex-direction: column;

        }

        &-img {
            width: 100%;
            @include imgPd(254, 384);
            border-radius: $radius-1;

            @include max(1000px) {
                margin: 0 auto 0;
                width: 100%;
                box-shadow: none;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            img {
                @include imgCover;
            }
        }

        &-row {
            justify-content: center;
        }

        &-des {
            position: relative;
            z-index: 1;
            padding: 1.6rem;
            box-shadow: 0px 6px 15px 0px rgba(64, 79, 104, 0.05);
            background-color: $color-bg-1;
            border-radius: $radius-1;
            border: .1rem solid $color-border-3;
            width: 85%;
            margin: -7rem auto 0;
            @include flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            gap: .8rem;
            height: 100%;

            @include max(1000px) {
                margin: 0 auto 0;
                width: 100%;
                box-shadow: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            @include max(800px) {
                padding: 1rem;
            }

            .title-second {
                text-transform: unset;
                @include elp-2;
                color: $color-pri;
                font-family: var(--font-pri);
                font-weight: 700;
            }

            .txt {
                font-family: var(--font-pri);
                font-weight: 400;
                @include elp-3;
            }
        }

        &-col {
            @include max(800px) {
                width: 50%;
            }

            @include max(450px) {
                width: 100%;
            }
        }
    }

    &-intro {
        &-col {
            @include max(600px) {
                width: 100%;
            }

            .inner {
                background-color: $color-white;
                height: 100%;
                @include flex;
                flex-direction: column;
                align-items: center;
                padding: 1.2rem;
                border-radius: $radius-3;
box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.10), 0px 4px 6px -1px rgba(16, 24, 40, 0.10);
                @include max(1200px) {
                    padding: 0;
                }
            }

            .icon {
                --wh: 8rem;
                width: var(--wh);
                height: var(--wh);
                flex-shrink: 0;
                @include mid-flex;
                margin-bottom: 2.2rem;
                border: .2rem solid $color-pri;
                border-radius: $radius-9;
                background-color: $color-white;

                @include max(800px) {
                    --wh: 6rem;
                    margin-bottom: 1.2rem;
                }

                img {
                    --wh: 3.2rem;
                    width: var(--wh);
                    height: var(--wh);
                    flex-shrink: 0;
                    object-fit: contain;
                    display: block;
                }
            }

            .tt {
                font-weight: bold;
                @include fz-28;
                line-height: 120%;
                color: $color-pri;
                margin-bottom: .8rem;
            }

            .mona-content {
                p,span{
                    @include fz-16;
                font-weight: normal;
                color: $color-text-11;
                }
            }
        }
    }
}

.pros-side-open {
    position: absolute;
    --wh: 2rem;
    left: calc(-1 * var(--wh) - .2rem);
    top: 20%;
    background-color: $color-white;
    border-bottom-left-radius: $radius-2;
    border-top-left-radius: $radius-2;
    border: .2rem solid $color-pri;
    border-right: none;
    z-index: 1;

    &.active {
        .icon {
            i {
                transform: rotate(180deg);
            }
        }
    }

    .icon {
        @include mid-flex;
        width: var(--wh);
        padding: 1rem 0;
        cursor: pointer;

        i {
            @include fz-14;
            color: $color-pri;
            @include transitionRe;
        }
    }

    @include min(1201px) {
        display: none;
    }

}

.side {
    @include max(1200px) {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        bottom: 0;
        z-index: 99;
        @include transitionRe;
        &.active{
            width: 100%;
            left: 0;
        }
    }

    &-overlay {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        width: 0;
        height: 100%;
        background-color: $color-border-06;
        z-index: 99;
        @include transitionRe;

        &.active {
            width: 100%;
        }
    }

    &-wr {
        height: 100%;
        max-width: 100%;
        --w: 26rem;
        min-width: var(--w);
        width: 100%;
        background-color: $color-white;
        padding: 2rem;

        @include max(1200px) {
            position: fixed;
            top: 0;
            right: 0;
            height: 100vh;
            bottom: 0;
            width: var(--w);
            z-index: 99;
            transform: translateX(100%);
            @include transitionRe;
            border-left: .2rem solid $color-pri;
            @include flex;
            flex-direction: column;

            &.active {
                transform: translateX(0);
            }
        }
    }

    &-inner {
        height: 100%;

    }

    &-head {
        @include flex;
        justify-content: space-between;
        padding: 1rem 0;
        border-bottom: .2rem solid $color-pri;

        .txt {
            color: $color-pri;
            font-weight: 700;

        }

        .icon {
            cursor: pointer;
            color: $color-pri;
        }

        @include min(1201px) {
            display: none;
        }
    }

    &-bdy {
        @include max(1200px) {
            flex: 1;
            height: 100%;
            max-height: 100%;
            overflow: hidden auto;

            &::-webkit-scrollbar {
                width: .5rem;
            }

            &::-webkit-scrollbar-thumb {
                background-image: linear-gradient($color-pri, $color-pri);
                border-radius: 50px;
            }
        }
    }

    &-item {

        &.active {
            .head {
                pointer-events: none;

                .icon {
                    i {
                        transform: rotate(0);
                    }
                }
            }
        }

        .head {
            padding: .8rem 0 .8rem 1.6rem;
            @include flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            @include max(1200px) {
                padding: .8rem 0 .8rem 1rem;
            }

            .tt {
                color: $color-text-9;
                font-weight: 600;
            }

            .icon {
                @include fz-14;

                i {
                    @include transitionRe;
                    transform: rotate(180deg);
                }
            }
        }
    }

}

.recheck {
    &-block {
        padding: 0 1.6rem;

        @include max(1200px) {
            padding: 0;
        }

    }

    &-item {
        padding: .8rem 0 .8rem 2rem;
        @include flex;
        gap: 1rem;
        cursor: pointer;

        &:hover,
        &.active {

            .txt,
            .icon {
                color: $color-second;
            }
        }

        .txt {
            @include fz-16;
            font-family: var(--font-sec);
            font-weight: 500;
            @include transitionRe;
        }

        .icon {
            margin-top: .1rem;
            @include mid-flex;
            @include fz-10;
            @include transitionRe;
            color: $color-text-10;
        }
    }
}