// Library
@import url("../assets/library/fontawesome/css/all.css");
@import url("../assets/library/fontawesome/fontawesome/fontawesome.css");
@import url("../assets/library/aos/aos.css");
@import url("../assets/library/swiper/swiper-bundle.min.css");
@import url("../assets/library/select2/select2.min.css");
// @import url("../assets/library/gallery/lightgallery.min.css");
@import url("../assets/library/fancybox/fancybox.css");
// @import url("../assets/library/magnific/magnific-popup.css");
@import url("../assets/library/jquery/jquery.js");
// @import url("../assets/library/datepicker/jquery-ui.css");
// @import url("../assets/library/datetime/daterangepicker.css");
// Core
@import "./core/reset";
@import "./core/mona";
@import "./core/variable";
@import "./core/font";
@import "./core/base";
@import "./core/mixin";
@import "./core/flex";
@import "./core/grid";
@import "./core/animation";
@import "./core/mobile";
@import "./core/smenu";
@import "./core/title";
// Component
@import "./components/scroll";
@import "./components/header";
@import "./components/footer";
@import "./components/burger";
@import "./components/select2";
@import "./components/date";
@import "./components/thumb";
// @import "./components/datetime"; 
@import "./components/effect";
@import "./components/popup";
@import "./components/banner";
@import "./components/backtotop";
// Layout
@import "./layouts/breadcrumb";
@import "./layouts/btn";
@import "./layouts/pagination";
@import "./layouts/distance";
@import "./layouts/global";
@import "./layouts/swiper";
@import "./layouts/input";
// Pages
@import "./pages/home";
@import "./pages/job";
@import "./pages/contact";
@import "./pages/work";
@import "./pages/post";
@import "./pages/about";