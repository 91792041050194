:root {
  margin-top: 0 !important;
  --size-hd: 13rem;
  --size-sc: 7rem;
  --hd-top: 4rem;
  --hd-bot: 9rem;
  --remainder: calc(100vw - 123rem);
  --halfremainder: calc(var(--remainder)/2);
  --seremainder: calc(var(--halfremainder)/3);
  --halfseremainder: calc(var(--halfremainder)/2);
  --font-pri: 'Mulish';
  --font-sec: 'Open Sans';
  --pad-pri: 6rem;
  --pad-sec: 4rem;
  @media (max-width: 1200px) {
      --size-hd: 7rem;
  --size-sc: 7rem;
  }
  @media (max-width: 600px) {
      --size-hd: 6rem;
      --size-sc: 6rem;
  }
}
$font-pri: 'Mulish';
$font-sec: 'Open Sans';
$color-pri-in: #2E3192;
$color-pri: #2E3192;
$color-second: #F1B650;
$color-third: #F15050;
$color-four: #BB1313;
$color-text: #191919;
$color-text-1: #6F6D6D;
$color-text-2: #4D4D4D;
$color-text-3: #858585;
$color-text-4: #383838;
$color-text-5: #FEEEEE;
$color-text-6: #94A3B8;
$color-text-7: #9A9999;
$color-text-8: #293477;
$color-text-9: #1E1E1E;
$color-text-10: #4B4B4B;
$color-text-11: #514F4F;
$color-bg: #FEEEEE;
$color-bg-1: #F5F6F7;
$color-bg-2: #A9ABD2;
$color-bg-3: #FEF8EE;
$color-bg-4: #CBD5E1;
$color-bg-pri: #EEEDED;
$color-btn: #E8EFF6;
$color-tag: #E0E0E0;
$color-tag-1: #DEDEDE;
$color-tag-2: #EAEAF4;

$color-black: #000000;
$color-white: #ffffff;
$color-border: #E5E7EB;
$color-border-1: #E2E8F0;
$color-border-2: #D0D5DD;
$color-border-3: #EDF0F2;

$color-border-01: rgba(0, 0, 0, 0.1);
$color-border-02: rgba(0, 0, 0, 0.2);
$color-border-03: rgba(0, 0, 0, 0.3);
$color-border-04: rgba(0, 0, 0, 0.4);
$color-border-05: rgba(0, 0, 0, 0.5);
$color-border-06: rgba(0, 0, 0, 0.6);
$color-border-07: rgba(0, 0, 0, 0.7);
$color-border-08: rgba(0, 0, 0, 0.8);
$color-border-09: rgba(0, 0, 0, 0.9);
$color-border-10: rgba(255, 144, 10, 0.10);
$color-opa-white-01: rgba(255, 255, 255, 0.1);
$color-opa-white-02: rgba(255, 255, 255, 0.2);
$color-opa-white-03: rgba(255, 255, 255, 0.3);
$color-opa-white-04: rgba(255, 255, 255, 0.4);
$color-opa-white-05: rgba(255, 255, 255, 0.5);
$color-opa-white-06: rgba(255, 255, 255, 0.6);
$color-opa-white-07: rgba(255, 255, 255, 0.7);
$color-opa-white-08: rgba(255, 255, 255, 0.8);
$color-opa-white-09: rgba(255, 255, 255, 0.9);

$remainder: calc(100vw - 123rem);
$halfremainder: calc($remainder/2);
$seremainder: calc($halfremainder/3);
$halfseremainder: calc($halfremainder/2);


// shadow
$shadow: 0 0.4rem 0.6rem rgba(16,24,40,0.1);
$shadow-1: 0 0.4rem 0.6rem rgba(16,24,40,0.1);
$shadow-2: 0 1rem 1.5rem rgba(16,24,40,0.1);
$shadow-3: 0 .6rem 1.5rem rgba(16,24,40,0.1);
$shadow-4: 0 4.2rem 4.2rem rgba(16,24,40,0.1);

//Responsive
$res-mobile: 1200px;
$res-mobile-min: 1201px;
$mobile: 500px;

$h-top: 6rem;
$h-bot: 2.5rem;
//Distance
$distance-header: 136px;
$distance-header-mobile: 72px;
$distance-header-sticky: 60px;
$distance-header-mobile-sticky: 56px;
$border-line: 1px solid rgba(0, 0, 0, 0.1);
$radius-1: .4rem;
$radius-2: .6rem;
$radius-3: .8rem;
$radius-4: 1rem;
$radius-5: 1.2rem;
$radius-6: 1.6rem;
$radius-7: 2.4rem;
$radius-8: 3.2rem;
$radius-9: 100rem;

//Grid 
$grid-gutter-width: 2.4rem;
$grid-columns: 12;
$gutters: (
  1: 2.4rem,
);

$xxs-min: 376px;
$xs-min: 576px; // CÓ THỂ TÙY CHỈNH
$sm-min: 768px; // KHÔNG THAY ĐỔI
$md-min: 992px; // KHÔNG THAY ĐỔI
$lg-min: 1200px; // KHÔNG THAY ĐỔI
$xl-min: 1400px; // CÓ THỂ TÙY CHỈNH

$xxs-max: (
  $xxs-min - 0.02
); // Smallest Mobile
$xs-max: (
  $xs-min - 0.02
); // Small Mobile
$sm-max: (
  $sm-min - 0.02
); // Mobile
$md-max: (
  $md-min - 0.02
); // Tablet
$lg-max: (
  $lg-min - 0.02
); // Desktop
$xl-max: (
  $xl-min - 0.02
); // Large Desktop

$grid-breakpoints: (
  "xxs": $xxs-min,
  "xs": $xs-min,
  "sm": $sm-min,
  "md": $md-min,
  "lg": $lg-min,
  "xl": $xl-min,
);