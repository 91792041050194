.work {
    &-detail{
        &-post{
            padding: 2rem;
            background-color: $color-white;
            border-radius: $radius-2;
            @include max(800px){
                padding: 1rem 0;
            }
            .title-second{
                margin-bottom: 2rem;
            }
            .swiper-next{
                @include max(1350px){
                    right: calc(-1 * var(--wh)/2);
                }
            }
            .swiper-prev{
                @include max(1350px){
                left: calc(-1 * var(--wh)/2);
            }
        }
            .swiper-next,
            .swiper-prev{
                @include max(1350px){
                    --wh: 3.8rem;
                }
                @include max(600px){
                    display: none;
                }
            }
        }
        &-inner{
            .gr-btn{
                .btn-sec,.btn-pri{
                    width: fit-content;
                }
            }
        }
        &-wr {
            margin: 2rem 0;
        }

        &-thum {
            position: relative;
            background-color: $color-pri;
            padding: 3.2rem 2.8rem;
            border-radius: $radius-3;
            overflow: hidden;
            margin-bottom: 2rem;

            .decor {
                position: absolute;
                z-index: 1;
                right: -2rem;
                bottom: -5rem;
                width: 25vw;
                min-width: 25rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }
            }

            &-wr {
                position: relative;
                z-index: 2;
                @include flex-wr;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;
            }

            .inner,
            .des {
                @include flex;
                flex-direction: column;
                gap: .8rem;
            }

            .title-second {
                font-family: var(--font-pri);
            }

            .txt {
                font-family: var(--font-pri);
            }

            .btn-pri {
                .txt {
                    font-family: var(--font-pri);
                }
            }

        }

        &-inner {
            background-color: $color-white;
            border-radius: $radius-3;
            padding: 2rem 2.8rem;
            margin-bottom: 2rem;

            @include max(600px) {
                padding: 1.4rem;
            }
            .title-second{
                margin-bottom: 2rem;
            }
            .inner {
                background-color: $color-bg-1;
                border-radius: $radius-2;
                padding: 4.6rem 2.8rem;

                @include max(600px) {
                    padding: 2.6rem 1.4rem;
                }

                .list {
                    @include flex-wr;
                    gap: var(--gap);
                    --gap: 2rem;

                    @include max(550px) {
                        --gap: 1rem;
                    }
                }

                .item {
                    width: calc(50% - var(--gap));
                    @include flex;
                    align-items: center;
                    gap: 1.8rem;

                    @include max(550px) {
                        width: 100%;
                        gap: 1rem;
                    }
                }

                .icon {
                    --wh: 3.2rem;
                    width: var(--wh);
                    height: var(--wh);
                    flex-shrink: 0;
                background-color: $color-bg-2;
                border-radius: $radius-9;
                @include mid-flex;
                    img {
                        width: 1.6rem;
                        height: 1.6rem;
                        display: block;
                        object-fit: contain;
                    }
                }

                .txt {
                    font-family: var(--font-pri);
                }
            }

            .gr-btn {
                @include flex-wr;
                align-items: center;
                gap: 1.4rem;

                .btn-pri,
                .btn-sec {
                    min-width: 13.9rem;
                }
            }
        }

        &-des {
            ul {
                margin-bottom: 2.2rem;
                font-family: var(--font-pri);
            }

            .tt {
                font-family: var(--font-pri);
                font-weight: bold;
                @include fz-18;
            }
        }

        &-col {
            &:nth-child(1) {
                width: calc((8/12 * 100%) - 2rem);

                @include max(900px) {
                    width: 100%;
                }
            }

            &:nth-child(2) {
                width: calc((4/12 * 100%) + 2rem);

                @include max(900px) {
                    width: 100%;
                }
            }
        }

        &-bdy {
            .title-second {
                font-family: var(--font-pri);
                font-weight: bold;
            }
        }

        &-post {
            .title-second {
                font-family: var(--font-pri);
                font-weight: bold;
                margin-bottom: 2rem;
            }
        }
    }
}
.post-sw-inner{
    position: relative;
}
.wrks {
    &-wr {
       margin-top: 4rem;
    }

    &-bar {
        width: 100%;
        margin-bottom: 2.4rem;
        padding-bottom: .5rem;
        overflow: auto hidden;

        &::-webkit-scrollbar {
            height: .3rem;
        }

        &::-webkit-scrollbar-thumb {
            background-image: linear-gradient($color-pri, $color-pri);
            border-radius: 50px;
        }

        .list {
            @include flex;
            // justify-content: center;
            width: fit-content;
            gap: 1.6rem;
            margin: auto;

            @include max(1200px) {
                gap: 1rem;
            }
        }

        .item {
            width: fit-content;
            flex-shrink: 0;
            @include mid-flex;
            gap: .8rem;
            padding: 1.2rem 3.2rem;
            background-color: $color-white;
            border: .1rem solid $color-tag;
            border-radius: $radius-1;
            @include transitionRe;
            cursor: pointer;

            @include max(1200px) {
                padding: 1.2rem 2.4rem;
            }

            &:hover,
            &.active {
                background-color: $color-pri;
                border-color: $color-pri;

                .txt {
                    color: $color-white;
                }

                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(44deg) brightness(101%) contrast(105%);
                }
            }

            .icon {
                --wh: 2rem;
                width: var(--wh);
                height: var(--wh);
                @include mid-flex;
                flex-shrink: 0;

                img {
                    @include transitionRe;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    display: block;
                }
            }

            .txt {
                color: $color-text;
                font-family: var(--font-fur);
                font-weight: 500;
                @include transitionRe;
            }
        }
    }
    &-bdy{
        background-color: $color-white;
        overflow: hidden;
        border-radius: $radius-3;
        margin-bottom: 2rem;
        .top{
            background-color: $color-pri;
            padding: 2rem;
            margin-bottom: .8rem;
            @include flex-wr;
            align-items: center;
            justify-content: space-between;
            gap: 2rem;
            .inner{
                @include flex-wr;
                gap: 1.2rem;
                @include max(970px){
                    width: 100%;
                    justify-content: center;
                }
            }
            .btn-pri{
                font-family: var(--font-pri);
            }
           
        }
        .bdy{
            padding: 2rem;
            @include max(1000px){
                padding: 2rem 1rem;
            }
            .col{
                @include max(1000px) {
                    width: calc(4/12 * 100%);
                }
                @include max(700px) {
                    width: 50%;
                }
                   @include max(450px) {
                    width: 100%;
                }
            }
        }
        .gr-btn{
            @include mid-flex;
            margin-bottom: 4rem;
            @include max(600px){
                margin-bottom: 2rem;
            }
        }
        .gr-select{
            @include flex-wr;
            gap: 1.2rem;
            @include max(700px){
                width: 100%;
            }
        }
        .select{
            height: 100%;
            width: 22.4rem;
            position: relative;
            --wh: 2rem;
            @include max(700px){
                width: calc(50% - .6rem);
            }
            @include max(500px){
                width: 100%;
                height: fit-content;
            }
            .icon{
                position: absolute;
                top: 0;
                bottom: 0;
                @include mid-flex;
                margin: auto;
                left: 1.4rem;
                z-index: 1;
                width: var(--wh);
                height: var(--wh);
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    display: block;
                }
            }
            .select2-container{
                height: 100%;
                &--default .select2-selection--single{
                    background-color: $color-pri;
                    padding-left: calc(var(--wh) + 1.4rem);
                    border-color: $color-white !important;
                    .select2-selection__rendered,
                     .select2-selection__placeholder{
                        color: $color-white !important;
                        font-family: var(--font-pri);
                        font-weight: 500;
                        @include fz-16;
                        @include flex;
                        align-items: center;
                        height: 100%;
                     }
                     .select2-selection__arrow{
                        @include transitionRe;
                        b {
                            border-color: #fff transparent transparent transparent;
                            border-width: 7px 6px 0 6px;
                            margin-left: -1.2rem;
                            @include transitionRe;
                        }
                     }
               
                }
             
            }
        }
       
    }
}
.pagination-mona{
    @include mid-flex;
    width: 100%;
}
.mona-content {
    font-family: var(--font-pri);
}