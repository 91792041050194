.home {
    &-bn {
        position: relative;
        overflow: hidden;

        &-slider {
            position: relative;
        }

        .swiper {
            &-pagination {
                position: absolute;

                @include max(1300px) {
                    bottom: 8rem;
                }

                @include max(1000px) {
                    bottom: 5rem;
                }

                @include max(600px) {
                    bottom: 3rem;
                }
            }

            &-container {
                overflow: hidden;
            }

            &-slide {
                .btn-sec {
                        transition-delay: .1s;

                }

                &-active {
                    .home-bn-content {
                        .title-second {
                            transition-delay: .3s;
                            transform: translateY(0);
                            opacity: 1;
                        }

                        .txt {
                            transition-delay: .6s;
                            transform: translateY(0);
                            opacity: 1;
                        }

                        .btn-sec {
                            transition-delay: 1s;
                            transform: translateY(0);
                            opacity: 1;

                        }
                    }
                }
            }
        }

        &-it {
            height: 100%;
            position: relative;
            min-height: calc(814/1728 * 100vw);
            // padding: 20rem 0;

            // @include max(1000px) {
            //     padding: 15rem 0;
            // }

            // @include max(700px) {
            //     padding: 10rem 0;
            // }

            .bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                &::before {
                        position: absolute;
                        content: '';
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        z-index: 1;
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: block;
                }
            }
        }

        &-content {
            position: relative;
            z-index: 2;
            @include mid-flex;
            height: 100%;
            .inner {
                width: 55rem;
                margin: auto;
                max-width: 100%;
                @include max(1000px) {
                    width: 80%;
                }

                @include max(700px) {
                    width: 100%;
                }
            }

            .title-second {
                width: 44rem;
                max-width: 100%;
                margin: auto;
                margin-bottom: 1.2rem;
                transform: translateY(2rem);
                opacity: 0;
                transition: all .7s ease;
                overflow-wrap: break-word;
                @include fz-52;
            }

            .txt {
                font-family: var(--font-pri);
                color: $color-white;
                font-weight: normal;
                margin-bottom: 3.2rem;
                transform: translateY(2rem);
                opacity: 0;
                transition: all .7s ease;

                @include max(700px) {
                    font-size: 1.4rem;
                    margin-bottom: 1.4rem;
                }
            }
            .btn-sec {
                font-family: var(--font-pri);
                @include fz-16;
                transform: translateY(2rem);
                opacity: 0;
                transition: all .7s ease;
            }
        }
    }
    &-wrk{
        &-wr{
            margin: 2rem 0;
            padding: 2rem;
            border-radius: $radius-4;
            background-color: $color-white;
            @include max(1000px){
                padding: 1rem;
            }
            .post-pri-list{
                padding: 2rem 0;
                @include max(1000px){
                    padding: 1rem 0;
                }
            }
        }
        &-col{
            @include max(1000px){
                width: 100%;
            }
            .list{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                @include max(450px){
                    display: flex;
                }
            }
            .item{
                .pst{
                    flex-direction: row;
                    @include max(660px){
                        flex-direction: column;
                    }
                }
                &:not(:nth-child(1)):not(:nth-child(2)) {
                    .pst{
                 
                        &-img{
                            width: 12rem;
                            flex-shrink: 0;
                            @include max(660px){
                                width: 100%;
                            }
                        }
                        &-txt{
                            display: none;
                        }
                        &-des{
                            flex-direction: column-reverse;
                         
                        }
                    }
                    grid-row: span 1;
                    grid-column: span 1;
                }
                &:nth-child(1),
                &:nth-child(2){
                    grid-column: span 2;
                    grid-row: span 2;
                    .pst{
                        &-img{
                            width: 38rem;
                            flex-shrink: 0;
                            @include max(1200px){
                                width: 32rem;
                            }
                            @include max(1000px){
                                width: 28rem;
                            }
                            @include max(660px){
                                width: 100%;
                            }
                        }
                        &-des{
                            flex-direction: column;
                        }
                    }
                }
         
            }
        }
        .work-detail-post{
            margin-bottom: 2rem;
            padding: 2rem;
            border-radius: $radius-4;
            background-color: $color-white;
            @include max(1000px){
                padding: 1rem;
            }
        }
    }
    &-post{
        &-col{
            @include max(1000px){
                width: 100%;
            }
        }
        &-head{
            padding: 2rem;
            border-radius: $radius-4;
            background-color: $color-white;
            @include max(1000px){
                padding: 1rem;
            }
            .head{
                @include flex-wr;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;
                margin-bottom: 4rem;
                @include max(800px){
                    margin-bottom: 2rem;
                }
                .title-second{
                    margin-bottom: 0;
                }
                .btn-more{
                    .link{
                        position: relative;
                        color: $color-pri;
                        @include fz-16;
                        font-weight: 600;
                        &::before{
                            position: absolute;
                            content: '';
                            left: 0;
                            bottom: -.2rem;
                            width: 100%;
                            right: 0;
                            height: .1rem;
                            background-color: $color-pri;
                        }
                    }
                }
            }
            .list{
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: repeat(4, 1fr);
                @include max(700px){
                    display: flex;
                }
            }
            .item{
                @include max(700px){
                   width: 50%;
                }
                @include max(430px){
                    width: 100%;
                 }
                &:not(:nth-child(1)) {
          
                    .pst{
                        flex-direction: row;
                        @include max(700px){
                            flex-direction: column;
                        }
                        &-img{
                            width: 12rem;
                            flex-shrink: 0;
                            @include max(700px){
                                width: 100%;
                            }
                        }
                        &-txt{
                            display: none;
                        }
                        &-des{
                            flex-direction: column-reverse;
                            @include max(700px){
                                flex-direction: column;
                            }
                        }
                    }
                    grid-row: span 1;
                    grid-column: span 2;
                }
                &:nth-child(1){
                    grid-column: span 2;
                    grid-row: span 4;
                    .pst{
                        &-des{
                            flex-direction: column;
                        }
                    }
                }
         
            }
        }
    }
    &-slider{
        overflow: hidden;
        &-wr{
            position: relative;
        }
        .title-second{
            span{
                &:nth-child(1){
                    color: $color-pri;
                }
                &:nth-child(2){
                    color: $color-second;
                }
                &:nth-child(3){
                    color: $color-pri;
                }
            }
        }
        .swiper-next{
            @include max(1340px){
                right: calc(-1 * var(--wh)/2);
            }
        }
        .swiper-prev{
            @include max(1340px){
                left: calc(-1 * var(--wh)/2);
            }

        }
        .swiper-next,
        .swiper-prev{
            @include max(1350px){
                --wh: 3.8rem;
            }
            @include max(600px){
                display: none;
            }
        }
        .swiper-pagination{
            @include min(601px){
                display: none;
            }
        }
        &-item{
            @include flex;
            align-items: center;
            gap: 1rem;
            flex-direction: column;
            .img{
            @include mid-flex;
            width: 100%;
            border-radius: $radius-4;
            background-color: $color-white;
                .inner{
                    width: 70%;
                    @include imgPd(110,180);
                    img{
                        @include imgContain;
                    }
                }
            }  
            .des{
                @include mid-flex;
                gap: 1rem;
                .txt{
                    @include fz-14;
                    color: $color-text;
                }
            }
            .icon{
                width: 2rem;
                height: 2rem;
                @include mid-flex;
                flex-shrink: 0;
                border-radius: $radius-9;
                background-color: $color-pri;
                color: $color-white;
                @include fz-12;
            }          
        }
    }
  }