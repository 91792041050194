.popup {
    position: fixed;
    @include full;
    z-index: 999;
    visibility: hidden;
    pointer-events: none;

    &.open {
        visibility: visible;
        pointer-events: visible;

        .popup-overlay {
            opacity: 1;
            visibility: visible;
            height: 100%;
        }

        .popup-main {
            opacity: 1;
            visibility: visible;
            @include transition(.3s, ease-in-out, .1s);
            @include clip-path(polygon(0 0, 100% 0, 100% 100%, 0% 100%))
        }
    }

    &-overlay {
        position: absolute;
        background-color: $color-border-07;
        @include full;
        z-index: 1;
        @include transitionRe;
        // opacity: 0;
        // visibility: hidden;
    }

    &-main {
        @include mid;
        z-index: 2;
        width: 70rem;
        max-width: 95%;
        @include transitionRe;
        // opacity: 0;
        // visibility: hidden;
        @include clip-path(polygon(0 0, 100% 0, 100% 0, 0 0));

        &-wrapper {
            overflow: hidden;
            border-radius: 6px;
        }
    }

    &-over {
          overflow: hidden auto;
        min-height: 50vh;
        max-height: 80vh;
    }

    &-wrapper {
        background-color: white;
        width: 100%;
        min-height: 60vh;
        // position: relative;
    }

    &-title {
        @include fz-24;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 1.6rem;
    }

    &-close {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        cursor: pointer;
        transition: 0.6s ease-in-out;
        width: 3rem;
        height: 3rem;
        //   border: 1px solid rgba(0, 0, 0, 0.3);
        @include mid-flex;
        //   background-color: white;
        z-index: 3;

        &:hover {
            .icon {
                transform: rotate(360deg);
            }
        }

        .icon {
            color: $color-text;
            font-weight: 300;
            font-size: 2.4rem;
            @include transition(0.6s, ease-in-out);
        }
    }
}

.Pd{
    &-body{
        border-radius: $radius-3;
    border: .1rem solid $color-border-1;
    background: $color-white;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.10), 0px 4px 6px -1px rgba(16, 24, 40, 0.10);
    
    }
    &-inner{
        padding: 2.8rem;
        @include flex;
        flex-direction: column;
        gap: 2rem;
        @include max(800px){
            gap: 1rem;
        }
    .gr-btn{
        width: 100%;
        .btn-sec{
            width: 100% !important;
            @include mid-flex;
        }
    }

    }
    &-list{
        @include flex;
        flex-direction: column;
        gap: 2rem;
        @include max(800px){
            gap: 1rem;
        }
    }
}