@import url("../assets/font/Mulish/stylesheet.css");
@import url("../assets/font/Open_Sans/stylesheet.css");

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;

    @media screen and (min-width:2000px) {
        font-size: 100%;
    }
}
// #wpadminbar{
//     top: -32px !important;
// }
body {
    font-size: 16px;
    line-height: 1.55;
    font-weight: normal;
    font-family: var(--font-pri);
    color: $color-text;
    background-color: $color-bg-1;
    overflow: hidden auto;
    // &.admin-bar{
    //     transform: translateY(0);
    // }
    &::-webkit-scrollbar {
        width: .5rem;
    }

    &::-webkit-scrollbar-thumb {
        background-image: linear-gradient($color-pri, $color-pri);
        border-radius: 50px;
    }

    @media screen and (min-width:2000px) {
        font-size: 20px;
    }
    &.home{
        @media screen and (min-width:1201px) {
            .header{
                background-color: transparent;
                .header-logo-link{
                    img{
                        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(140deg) brightness(101%) contrast(101%);
                    }
                }
        
                // .header-lg{
                //     > .header-lg-it{
                //         color: #ffffff;
                //         .text{
                //             color: #ffffff;
                //         }
                //     }
                // }
                .header-user-link,
                .header-phone-link{
                    color: #ffffff;
                    img{
                        filter: brightness(100%) invert(1)
                    }
                }
                .header-nav {
                    .menu-nav {
                        > .menu-list {
                            > .menu-item {
                                > .menu-link{
                                    color: $color-white;
                                    &::after{
                                        background-color: $color-white;
                                    }
                                    i{
                                        color: $color-white;
                                    }
                                }
                            }
                        }
                    }
                }
                &.sticky{
                    background-color: $color-white;
                    .header-logo-link{
                        img{
                            filter: none;
                        }
                    }
                    .header-nav {
                        .menu-nav {
                            > .menu-list {
                                > .menu-item {
                                    > .menu-link{
                                        color: #101112;
                                        &::after{
                                            background-color: #101112;
                                        }
                                        i{
                                            color: #101112;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .header-search-icon{
                        img{
                            filter: unset
                        }
                    }
                    .header-lg{
                        > .header-lg-it{
                            color: #101112;
                            .text{
                                color: #101112;
                            }
                        }
                    }
                    .header-user-link,
                    .header-phone-link{
                        color: #101112;
                        img{
                            filter: unset;
                        }
                    }
                }
            }
        
            .main{
                padding-top: 0;
            }
        }


    }
}
.daterangepicker td.disabled,
.daterangepicker option.disabled {
    text-decoration: none !important;
}
img{
    max-width: 100%;
    display: block;
}
.h1hidden{
    height: 0;
    width: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 0;
}
a {
    text-decoration: none;
    color: inherit;
}

textarea,
input {
    resize: none;
    outline: none;
    font-family: "SF Pro Display", sans-serif;
    font-size: 14px;
}

button,
select {
    border: none;
    outline: none;
    background-color: transparent;
}

.main {
    min-height: 40vh;
        padding-top: var(--size-hd);
    @media screen and (max-width:1200px) {
        min-height: 20vh;
    }
}

.container {
    width: 100%;
    max-width: 123rem;
    height: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.over {
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
        display: none;
    }
}

.no-scroll {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
}

iframe {
    vertical-align: middle;
}

.effectShine {
    position: relative;
    overflow: hidden;
}

.effectShine::before {
    content: "";
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.wow {
    animation-duration: 1s;
}

.load-item {
    display: none;
}

.show-hide-toggle {
    cursor: pointer;
}