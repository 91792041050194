.breadcrumb {
    // padding: 1.2rem 0;

    &-wrapper {
        @include min($res-mobile) {
            width: fit-content;
        }
    }

    &-list {
        @include flex;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;
        gap: .8rem;
    }

    &-item {
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        gap: .8rem;
        font-weight: 400;
        font-family: var(--font-pri);
        color: $color-white;
        text-transform: capitalize;
        &:not(:first-child) {
            &::before {
                display: inline-block;
                content: '/';
                vertical-align: middle;
        font-weight: 400;
            }
        }

        &:last-child {
            
            pointer-events: none;
        }

        @include hover {
            // text-decoration: underline;
            color: $color-white;
        }
    }

    &-link {
        color: inherit;
        transition: .2s ease-in-out;
    }
}
