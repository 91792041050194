.select2-container{
    width: 100% !important;
    &--default{
        .select2-results__option--selected{
            background-color: $color-white;
            color: $color-white;
        }
    .select2-results__option--highlighted.select2-results__option--selectable{
        background-color: $color-pri;
        color: $color-white;
    }
    } 
}
.select2-selection{
    height: 100% !important;
    &--single{
        border: .1rem solid $color-border !important;
     .select2-selection__rendered{
        line-height: 100% !important;
        padding: 1rem 1.4rem;
     }
     .select2-selection__placeholder{
        color: $color-text !important;
        font-family: var(--font-fiv);
    @include fz-16;
    font-weight: 500;
     }
    }
    .select2-selection__arrow{
        height: 100% !important;
    }
    
}