.dnor {
    @include flex-st;
    margin: 0 -1.5rem;
    flex-wrap: wrap;
    margin-bottom: -3rem;
    &-item {
        padding: 0 1.5rem;
        margin-bottom: 3rem
    }
}

.dsmall {
    @include flex-st;
    flex-wrap: wrap;
    margin: 0 -1.2rem;
    margin-bottom: -2.4rem;
    &-item {
        padding: 0 1.2rem;
        margin-bottom: 2.4rem
    }
}

.dmini {
    @include flex-st;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
    margin-bottom: -1rem;
    &-item {
        padding: 0 0.5rem;
        margin-bottom: 1rem
    }
}

.dside {
    @include flex-st;
    flex-wrap: wrap;
}

