.gl {
    &-tag {
        @include flex-wr;
        gap: 1rem;
        .txt {
            width: fit-content;
            padding: .4rem .8rem;
            @include fz-14;
            font-family: var(--font-pri);
            font-weight: normal;
            background-color: $color-tag-2;
        }
        &.pri{
            .txt{
                color: $color-pri;
            }
        }
        &.sec{
            .txt{
                color: $color-text-2;
            }
        }
    }

    &-btn {
        position: relative;
        cursor: pointer;
        background: transparent;
        width: max-content;
        height: auto;
        @include mid-flex;
        z-index: 1;
        &::before{
            position: absolute;
            content: '';
            background: $color-pri;
            left: 0;
            top: 0;
            bottom: 0;
            width: 2.4rem;
            height: 100%;
            flex-shrink: 0;
            border-radius: $radius-9;
            transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
            z-index: -1;
        }
        &:hover {
            &::before{
            width: 100%;
            }
            .circle {
                .arrow {
                    background: $color-white;
                    transform: translate(1rem, 0);
                }
            }

            .text {
                color: $color-white;
            }
        }

        .circle {
            transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
            position: relative;
            @include mid-flex;
            flex-shrink: 0;
            margin: 0;
            width: 2.4rem;
            height: 2.4rem;
            border-radius: $radius-9;
          
        }

        .icon {
            transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            background: $color-white;

            &.arrow {
                transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
                left: .4rem;
                width: 1.125rem;
                height: 0.2rem;
                background: none;

                &::before {
                    position: absolute;
                    content: "";
                    top: -0.29rem;
                    right: 0.0625rem;
                    width: 0.625rem;
                    height: 0.625rem;
                    border-top: 0.2rem solid $color-white;
                    border-right: 0.2rem solid $color-white;
                    transform: rotate(45deg);
                }
            }
        }

        .text {
            transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
            color: $color-text;
            font-weight: 500;
            font-family: var(--font-pri);
            @include fz-14;
            line-height: 100%;
            text-align: center;
            text-transform: uppercase;
            padding: 0 .8rem;
        }
    }
}
.gr-tag {
    @include flex;
    flex-direction: column;
    gap: 1.6rem;
    .text{
        @include fz-16;
        color: $color-text;
        font-weight: 400;
    }
    .tag{

    &-list{
        @include flex;
        gap: 1.2rem;
    }
}
}
.gr-btn{
    width: 100%;
    &.center{
        @include mid-flex;
        width: fit-content;
        margin: auto;
    }
    .btn-sec{
        width: 100%;
    }
}