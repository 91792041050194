@mixin fz-200 {
  font-size: 20rem;

  @media only screen and (max-width: 1200px) {
    font-size: 15rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 10rem;
  }
}
@mixin fz-186 {
  font-size: 18.6rem;

  @media only screen and (max-width: 1200px) {
    font-size: 13rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 10rem;
  }
}
@mixin fz-120 {
  font-size: 12rem;

  @media only screen and (max-width: 1200px) {
    font-size: 7rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 5.5rem;
  }
}
@mixin fz-90 {
  font-size: 9rem;

  @media only screen and (max-width: 1200px) {
    font-size: 6rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 4.5rem;
  }
}
@mixin fz-72 {
  font-size: 7.2rem;

  @media only screen and (max-width: 1200px) {
    font-size: 5rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 4rem;
  }
}

@mixin fz-65 {
  font-size: 6.5rem;

  @media only screen and (max-width: 1200px) {
    font-size: 4rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 3rem;
  }
}

@mixin fz-64 {
  font-size: 6.4rem;

  @media only screen and (max-width: 1200px) {
    font-size: 3.9rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2.9rem;
  }
}

@mixin fz-60 {
  font-size: 6rem;

  @media only screen and (max-width: 1200px) {
    font-size: 3.5rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2.5rem;
  }
}

@mixin fz-56 {
  font-size: 5.6rem;

  @media only screen and (max-width: 1200px) {
    font-size: 5.1rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 4rem;
  }
}

@mixin fz-55 {
  font-size: 5.5rem;

  @media only screen and (max-width: 1200px) {
    font-size: 5rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 3.9rem;
  }
}
@mixin fz-52 {
  font-size: 5.2rem;

  @media only screen and (max-width: 1200px) {
    font-size: 4.5rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 3.4rem;
  }
}
@mixin fz-50 {
  font-size: 5rem;

  @media only screen and (max-width: 1200px) {
    font-size: 4.5rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 3.4rem;
  }
}

@mixin fz-48 {
  font-size: 4.8rem;

  @media only screen and (max-width: 1200px) {
    font-size: 4.3rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 3.3rem;
  }
}

@mixin fz-45 {
  font-size: 4.5rem;

  @media only screen and (max-width: 1200px) {
    font-size: 4rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 3rem;
  }
}

@mixin fz-42 {
  font-size: 4.2rem;

  @media only screen and (max-width: 1200px) {
    font-size: 3.5rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2.7rem;
  }
}

@mixin fz-40 {
  font-size: 4rem;

  @media only screen and (max-width: 1200px) {
    font-size: 3.5rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2.7rem;
  }
}

@mixin fz-38 {
  font-size: 3.8rem;

  @media only screen and (max-width: 1200px) {
    font-size: 3.4rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2.6rem;
  }
}

@mixin fz-36 {
  font-size: 3.6rem;

  @media only screen and (max-width: 1200px) {
    font-size: 3.1rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2.6rem;
  }
}

@mixin fz-35 {
  font-size: 3.5rem;

  @media only screen and (max-width: 1200px) {
    font-size: 3rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2.5rem;
  }
}

@mixin fz-34 {
  font-size: 3.4rem;

  @media only screen and (max-width: 1200px) {
    font-size: 2.9rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2.4rem;
  }
}

@mixin fz-32 {
  font-size: 3.2rem;

  @media only screen and (max-width: 1200px) {
    font-size: 2.7rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2.2rem;
  }
}

@mixin fz-30 {
  font-size: 3rem;

  @media only screen and (max-width: 1200px) {
    font-size: 2.7rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2.4rem;
  }
}

@mixin fz-29 {
  font-size: 2.9rem;

  @media only screen and (max-width: 1200px) {
    font-size: 2.6rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2.3rem;
  }
}

@mixin fz-28 {
  font-size: 2.8rem;

  @media only screen and (max-width: 1200px) {
    font-size: 2.4rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 2.1rem;
  }
}

@mixin fz-26 {
  font-size: 2.6rem;

  @media only screen and (max-width: 1200px) {
    font-size: 2.2rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 1.9rem;
  }
}

@mixin fz-25 {
  font-size: 2.5rem;

  @media only screen and (max-width: 1200px) {
    font-size: 2.1rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 1.8rem;
  }
}

@mixin fz-24 {
  font-size: 2.4rem;

  @media only screen and (max-width: 1200px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 1.7rem;
  }
}

@mixin fz-22 {
  font-size: 2.2rem;

  @media only screen and (max-width: 1200px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 1.9rem;
  }
}


@mixin fz-20 {
  font-size: 2rem;

  @media only screen and (max-width: 1200px) {
    font-size: 1.8rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 1.6rem;
  }
}

@mixin fz-19 {
  font-size: 1.9rem;

  @media only screen and (max-width: 1200px) {
    font-size: 1.7rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 1.6rem;
  }
}

@mixin fz-18 {
  font-size: 1.8rem;

  @media only screen and (max-width: 1200px) {
    font-size: 1.6rem;
  }

  @media only screen and (max-width: 800px) {
    font-size: 1.5rem;
  }
}

@mixin fz-17 {
  font-size: 1.7rem;
}

@mixin fz-16 {
  font-size: 1.6rem;
}

@mixin fz-15 {
  font-size: 1.5rem;
}

@mixin fz-14 {
  font-size: 1.4rem;
}

@mixin fz-13 {
  font-size: 1.3rem;
}

@mixin fz-12 {
  font-size: 1.2rem;
}

@mixin fz-11 {
  font-size: 1.1rem;
}

@mixin fz-10 {
  font-size: 1rem;
}

@mixin fz-9 {
  font-size: 0.9rem;
}