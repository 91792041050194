.page-numbers {
    @include flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5;
    margin: 1.6rem 0;
    >li {
        &:not(:first-child) {
            margin-left: .2rem;
            // @include max(1200px){
            //   margin-left: .6rem;
            // }
        }
        &>.page-numbers {
          --wh: 4rem;
            width: var(--wh);
            height: var(--wh);
            @include mid-flex;
            background-color: transparent;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            color: $color-text;
            @include fz-14;
            font-weight: 500;
            font-family: var(--font-sec);
            transition:all 0.4s ease;
            @include affter($color-pri);
            border-radius: $radius-2;
            &:after {
                z-index: -1;
            }
            @include max(1200px) {
              --wh: 3rem;
              @include fz-14;
            }
            @include hover {
                &:not(.dot) {
                  // border: 0.5px solid $color-text;
                    color: $color-white;
                    background-color: $color-pri;
                    &::after {
                        left: 0;
                        width: 100%;
                    }
                }
            }
            &.current {
              // border: 0.5px solid  $color-text;
                color: $color-white;
                background-color: $color-pri;
                &::after {
                    left: 0;
                    width: 100%;
                }
            }
            &.dot {
                background-color: transparent;
                border:transparent !important;
            }
            &.next{
              margin-left: 1rem;
              @include max(800px){
                margin-left: .5rem;
              }
            }
            &.prev{
              margin-right: 1rem;
              @include max(800px){
                margin-right: .5rem;
              }
            }
            &.next,
            &.prev {
              border: none;
              border-radius: $radius-1;
              @include mid-flex;
              position: relative;
              color: $color-text;
            }
            i {
                font-weight: 400;
            }
        }
    }
  }