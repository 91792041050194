.post {

    &-wr {
        margin: 4rem 0;

    }

    &-col {
        @include max(900px) {
            width: 100%;
        }
    }

    &-content {
        padding: 2rem;
        background-color: $color-white;
        border-radius: $radius-4;

        @include max(600px) {
            padding: 1rem 0;
        }

        .col {
            @include max(700px) {
                width: 50%;
            }

            @include max(430px) {
                width: 100%;
            }
        }

        .pst {
            @include min(701px) {
                flex-direction: row;
            }

            &-tt {
                @include fz-24;

                @include max(600px) {
                    @include fz-16;
                }
            }

            &-img {
                @include min(701px) {
                    width: 28rem;
                    flex-shrink: 0;
                }

            }
        }

    }

    &-detail {

        &-wr {
            margin: 4rem 0;
        }

        &-col {
            @include max(900px) {
                width: 100%;
            }
        }

        &-content {
            padding: 2rem;
            border-radius: $radius-1;
            background-color: $color-white;
            @include flex;
            flex-direction: column;
            gap: 2rem;

            .content-list {
                list-style-type: decimal;
            }

            .title-second {
                margin-bottom: 0;
            }

            .gl-tag {}

            .mona-content {
                ul {
                    @include max(800px) {
                        padding-left: 2rem;
                    }
                }
            }

            .content-item {
                font-family: var(--font-pri);
                @include fz-20;
                font-weight: 600;

                img {
                    margin: .5rem 0;
                }

                .p,
                .txt {
                    margin: .5rem 0;
                    font-weight: 500;
                    @include fz-16;
                }

            }

            .inner {}

            .social {
                border-top: .1rem solid $color-border;
                padding-top: 2rem;
                @include flex;
                flex-direction: column;
                gap: 1rem;
                margin-bottom: 2rem;

                &-list {
                    @include flex;
                    gap: 1.6rem;
                    border: none;
                }

                &-item {
                    width: 3rem;
                    height: 3rem;
                    @include mid-flex;
                    @include transitionRe;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        display: block;
                    }

                    &:hover {
                        transform: translateY(-5px);
                    }
                }
            }
        }

        &-contact {
            .wpcf7-form {
                position: relative;

                .wpcf7-response-output {
                    position: absolute;
                    bottom: 0;
                    left: 3.2rem;
                    @include fz-12;

                    @include max(600px) {
                        left: 1.2rem;
                        bottom: -.5rem;
                        @include fz-10;
                        right: 1.2rem;
                    }

                }
            }

            .inner {
                background-color: $color-white;
                padding: 3.2rem;
                border-radius: $radius-1;

                @include max(600px) {
                    padding: 2rem 1.2rem;
                }

                .btn-pri {
                    width: 100%;
                    @include mid-flex;
                }
            }

            .list {
                @include flex;
                flex-direction: column;
                gap: 2.4rem;
                margin-bottom: 3.2rem;
            }

            .item {
                width: 100%;
                @include flex;
                flex-direction: column;
                gap: .6rem;

                .ip {
                    width: 100%;
                    border: none;
                    outline: none;
                    padding: 1.2rem 1.4rem;
                    font-family: var(--font-pri);
                    @include fz-16;
                    font-weight: 500;
                    border-radius: $radius-3;
                    overflow: hidden;

                    &::placeholder {
                        font-family: var(--font-pri);
                        @include fz-16;
                        font-weight: 500;
                        color: $color-text;
                    }
                }

                .lb {
                    font-family: var(--font-pri);
                    font-weight: bold;
                    @include fz-14;
                }

                .in {
                    position: relative;
                    border: .1rem solid $color-text;
                    border-radius: $radius-3;
                    // overflow: hidden;
                    @include flex;
                    align-items: center;

                    .wpcf7-form-control-wrap {
                        width: 100%;

                    }

                }

                .select2-container {
                    width: 7rem !important;

                    .select2-selection--single {
                        border: none !important;

                        .select2-selection__placeholder {
                            color: $color-text !important;
                        }

                        .select2-selection__rendered {
                            @include flex;
                            justify-content: center;
                        }
                    }
                }

            }

            .wpcf7-not-valid-tip {
                position: absolute;
            }

            .title-second {
                margin-bottom: 2rem;
            }

            .gr-btn {
                width: 100%;

                .btn-sec {
                    width: 100%;
                }
            }
        }

        &-intro {
            @include flex-wr;
            flex-direction: column;
            --pad: 1rem;
            margin: 0 calc(var(--pad) * -1) calc(var(--pad) * -2);

            @include max(900px) {
                flex-direction: row;
            }

            &-inner {
                padding: 0 var(--pad);
                margin-bottom: calc(var(--pad) * 2);
                overflow: hidden;

                @include max(900px) {
                    width: 50%;
                }

                @include max(500px) {
                    width: 100%;
                }

                .block {
                    height: 100%;
                    background-color: $color-white;
                    box-shadow: $shadow;
                }

                .img {
                    @include imgPd(265, 384);

                    img {
                        @include imgContain;
                    }
                }

                .btn-pri {
                    @include mid-flex;
                    width: 100%;
                }

                .des {
                    padding: 2rem 2.4rem;
                }

                .title-second {
                    @include elp-2;
                    margin-bottom: .8rem;
                }

                .title-sub {
                    text-align: left;
                    margin-bottom: 2.4rem;
                    @include elp-5;
                }
            }

            .job-thumb {
                margin: 0;
                padding: 0 var(--pad);
                margin-bottom: calc(var(--pad) * 2);

                @include max(900px) {
                    width: 50%;
                    margin: 0 auto calc(var(--pad) * 2);
                }

                @include max(500px) {
                    width: 100%;
                }

                .inner {
                    @include max(900px) {
                        width: 100%;
                        height: 100%;

                    }
                }
            }
        }

        &-relate {
            background-color: $color-white;
            border-radius: $radius-4;
            padding: 2rem 1.2rem;
            border: .1rem solid #E2E8F0;

            .title-second {
                margin-bottom: 1.6rem
            }

            .list {
                @include flex-wr;
                flex-direction: column;
                --pad: 1rem;
                margin: 0 calc(var(--pad) * -1) calc(var(--pad) * -2);

                @include max(900px) {
                    flex-direction: row;
                }
            }

            .item {
                padding: 0 var(--pad);
                margin-bottom: calc(var(--pad) * 2);

                @include max(900px) {
                    width: calc(4/12 * 100%);
                }

                @include max(560px) {
                    width: calc(6/12 * 100%);
                }

                @include max(400px) {
                    width: 100%
                }
            }
        }

        &-bottom {
            margin: 4rem 0;
            padding: 2rem;
            border-radius: $radius-5;
            background-color: $color-white;

            @include max(900px) {
                padding: 1rem 0;
            }

            .col {
                @include max(900px) {
                    width: calc(4/12 * 100%);
                }

                @include max(660px) {
                    width: 50%;
                }

                @include max(420px) {
                    width: 100%;
                }
            }

            .gr-btn {
                margin-bottom: 2rem;
                padding: 2rem 0;
                width: 100%;
            }
        }
    }


    &-pri {
        width: 100%;
        border-radius: $radius-5;
        background-color: $color-white;
        overflow: hidden;

        &-head {
            padding: 1.2rem;
            background-color: $color-pri;
        }

        &-video {
            width: 100%;
            @include imgPd(180, 384);

            img,
            video,
            iframe {
                @include imgCover;
            }
        }

        &-list {
            @include flex;
            flex-direction: column;
            gap: 2rem;
            padding: 2rem;

            @include max(600px) {
                padding: 1rem;
            }

            .pst {
                flex-direction: row;

                @include max(400px) {
                    flex-direction: column;
                }

                &-img {
                    width: 10.4rem;
                    flex-shrink: 0;

                    @include max(400px) {
                        width: 100%;
                    }
                }

                &-des {
                    @include flex;
                    flex-direction: column-reverse;
                    gap: .4rem;
                }

                .tag {
                    font-size: 1.3rem;
                }
            }
        }
    }

    &-tab {
        padding: 2rem 0;
        background-color: $color-pri;

        @include max(800px) {
            padding: 1rem 0;
        }

        .swiper-slide {
            width: calc(4/12 * 100%);
        }

        &-list {
            width: 80%;
            margin: auto;

            @include max(800px) {
                width: 100%;
            }
        }

        &-item {
            border-radius: $radius-9;
            @include transitionRe;

            .link {
                @include mid-flex;
                color: $color-white;
                @include fz-20;
                font-weight: 700;
                padding: 1rem;
                @include transitionRe;

                @include max(800px) {
                    padding: 1rem;
                }
            }

            &:hover,
            &.active {
                background-color: $color-opa-white-01;

                .link {
                    color: $color-second;
                }
            }
        }
    }
}

.swiper-slide {
    height: initial;
    overflow: hidden;
}


.cataloge {
    padding: 2.4rem;
    background-color: $color-bg-3;
    border-radius: $radius-4;
    margin: 2rem 0;

    @include max(900px) {
        width: 80%;
        margin: 2rem auto;
    }

    @include max(500px) {
        width: 100%;
        margin: 2rem auto;
    }

    &-head {
        margin-bottom: 2.4rem;
    }

    .head {
        @include flex;
        align-items: center;
        gap: .8rem;

        .icon {
            --wh: 1.6rem;
            width: var(--wh);
            height: var(--wh);
            flex-shrink: 0;
            @include mid-flex;

            img {
                width: 80%;
                height: 80%;
                display: block;
                object-fit: contain;
            }
        }
    }

    &-list {
        .head {
            .icon {
                img {
                    width: 60%;
                    height: 60%;
                }
            }
        }

        .list {
            list-style: none;
            @include flex;
            flex-direction: column;
            gap: 1.2rem;
        }
    }

    .pdl {
        padding-left: 2.4rem;
    }

    &-t {
        font-family: var(--font-pri);
        font-weight: 600;
        @include fz-14;
        margin: 0 !important;
    }

    &-tt {
        font-family: var(--font-pri);
        font-weight: 600;
        @include fz-16;
        color: $color-black;
    }
}

.select2-search--dropdown {
    display: none;
}

.pst {
    @include flex;
    flex-direction: column;
    gap: 1.6rem;
    height: 100%;
    background-color: $color-white;
    position: relative;

    @include max(600px) {
        gap: 1rem;
    }

    &.sec {
        gap: 0;
        border-radius: $radius-3;
        border: .1rem solid $color-border;
        @include transitionRe;

        &:hover {
            box-shadow: 0px 4px 6px -4px rgba(16, 24, 40, 0.10), 0px 10px 15px -3px rgba(16, 24, 40, 0.10);
        }

        .pst-tt {
            @include fz-20;
        }

        .pst-inner {
            flex: 1;
        }

        .btn-sec {
            margin-top: auto;
        }

        .pst-img {
            border-radius: 0;

            &-in {
                @include imgPd(205, 280);
            }
        }
    }

    &-inner {
        padding: 2rem;
        @include flex;
        flex-direction: column;
        gap: 2rem;

        @include max(800px) {
            padding: 1rem;
            gap: 1rem;
        }

        .inner {
            @include flex;
            flex-direction: column;
            gap: .6rem;
        }

        .txt {
            @include fz-15;
            font-weight: 500;
            @include elp-2;

        }

        .icon {
            width: 2rem;
            height: 2rem;
            flex-shrink: 0;
            margin-top: .1rem;

            img {
                height: 100%;
                width: 100%;
                display: block;
                object-fit: contain;
            }
        }

        .in {
            @include flex;
            // align-items: center;
            gap: .8rem;
        }

        .item {
            width: 100%;
            @include flex-wr;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;

        }
    }

    &-des {
        flex: 1;
        @include flex;
        flex-direction: column;
        gap: .8rem;
    }

    &-txt {
        @include fz-16;
        font-weight: 400;
        @include elp-4;
        color: $color-text-1;
        @include elp-3;

        @include max(500px) {
            @include fz-14;

        }
    }

    &-tag {
        position: absolute;
        left: 1rem;
        top: 1rem;
        padding: .6rem 1.2rem;
        border-radius: $radius-1;
        color: $color-third;
        background-color: $color-bg;
        z-index: 1;

        @include max(900px) {
            padding: .5rem .8rem;
            @include fz-12;
            left: .5rem;
            top: .5rem;
        }
    }

    &-img {
        width: 100%;
        border-radius: $radius-3;
        overflow: hidden;

        &:hover {
            img {
                @include imgHvZoom;
            }
        }

        &-in {
            @include imgPd(161, 280);
            width: 100%;
            height: 100%;

            img {
                @include imgCover;
                @include imgZoom;
            }
        }
    }

    &-tt {
        color: $color-text;
        @include fz-16;
        font-family: var(--font-pri);
        font-weight: 700;
        @include transitionRe;
        @include elp-2;

        &:hover {
            color: $color-pri;
        }
    }

    .tag {
        color: $color-text-1;
        font-family: var(--font-pri);
        font-weight: 400;
        line-height: 130%;
    }
}

.range {
    position: relative;
    padding-top: 1.5rem;
    width: 100%;
}

.range-input {
    position: relative;
}

.range-input-item[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
}

.slider-track {
    width: 100%;
    height: .5rem;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    border-radius: .5rem;
    background-color: $color-white;
}

.range-input-item[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: .3rem;
}

.range-input-item[type="range"]::-moz-range-track {
    -moz-appearance: none;
    height: .5rem;
}

.range-input-item[type="range"]::-ms-track {
    appearance: none;
    height: .5rem;
}

.range-input-item[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1.6rem;
    width: 1.6rem;
    background-color: $color-second;
    cursor: pointer;
    margin-top: -.7rem;
    pointer-events: auto;
    border-radius: 50%;
}

.range-input-item[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 1.6rem;
    width: 1.6rem;
    cursor: pointer;
    border-radius: 50%;
    background-color: $color-second;
    pointer-events: auto;
    border: none;
}

.range-input-item[type="range"]::-ms-thumb {
    appearance: none;
    height: 1.6rem;
    width: 1.6rem;
    cursor: pointer;
    border-radius: 50%;
    background-color: $color-second;
    pointer-events: auto;
}

.range-input-item[type="range"]:active::-webkit-slider-thumb {
    background-color: $color-white;
    border: .1rem solid $color-second;
}

.values {
    position: relative;
    margin: auto;
    padding-top: 1.5rem;
    text-align: center;
    font-weight: 500;
    @include fz-12;
    color: $color-white;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.btrn-cate{
    @include flex-wr;
    gap: 1rem;
}
#ez-toc-container{
    margin: 2rem 0;
}