.hover_xam {
	display: block;
	position: relative;
	overflow: hidden;

	&::before {
		content: "";
		background: rgba(255, 255, 255, .5);
		bottom: 0;
		left: 0;
		position: absolute;
		-webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		transform: rotate(90deg);
		-khtml-opacity: 1;
		-o-opacity: 1;
		-ms-opacity: 1;
		-webkit-opacity: 1;
		-moz-opacity: 1;
		opacity: 1;
		width: 0;
		height: 0;
		z-index: 1;
		pointer-events: none;
	}

	&::after {
		content: "";
		background: rgba(255, 255, 255, .5);
		top: 0;
		right: 0;
		position: absolute;
		-webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		transform: rotate(90deg);
		-khtml-opacity: 1;
		-o-opacity: 1;
		-ms-opacity: 1;
		-webkit-opacity: 1;
		-moz-opacity: 1;
		opacity: 1;
		width: 0;
		height: 0;
		z-index: 2;
		pointer-events: none;
	}

	&:hover {
		&::before {
			-webkit-transform: rotateY(180deg);
			-moz-transform: rotateY(180deg);
			-ms-transform: rotateY(180deg);
			-o-transform: rotateY(180deg);
			transform: rotateY(180deg);
			-webkit-transition-duration: 1.3s;
			-moz-transition-duration: 1.3s;
			-ms-transition-duration: 1.3s;
			-o-transition-duration: 1.3s;
			transition-duration: 1.3s;
			-khtml-opacity: 0;
			-o-opacity: 0;
			-ms-opacity: 0;
			-webkit-opacity: 0;
			-moz-opacity: 0;
			opacity: 0;
			height: 100%;
			width: 100%;
		}

		&::after {
			-webkit-transform: rotateY(180deg);
			-moz-transform: rotateY(180deg);
			-ms-transform: rotateY(180deg);
			-o-transform: rotateY(180deg);
			transform: rotateY(180deg);
			-webkit-transition-duration: 1.3s;
			-moz-transition-duration: 1.3s;
			-ms-transition-duration: 1.3s;
			-o-transition-duration: 1.3s;
			transition-duration: 1.3s;
			-khtml-opacity: 0;
			-o-opacity: 0;
			-ms-opacity: 0;
			-webkit-opacity: 0;
			-moz-opacity: 0;
			opacity: 0;
			height: 100%;
			width: 100%;
		}
	}
}
