.contact{
    &-wr{
        background-color: $color-white;
        position: relative;
        padding: 5.3rem 4rem;
        border-radius: $radius-3;
        overflow: hidden;
        @include max(1200px){
            padding: 3.3rem 2rem;
        }
        @include max(600px){
            padding: 2rem 1rem;
        }
    }
    &-bg{
        position: absolute;
        bottom: 0;
        left: 0;
        // max-height: 34rem;
        height: 50%;
        min-width: 100%;
        z-index: 1;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }
    &-row{
        z-index: 2;
        position: relative;
    }

    &-lb {
        margin-bottom: .6rem;
        font-family: var(--font-sec);
        font-weight: bold;
        text-transform: capitalize;

        &.speci {
            position: relative;

            &::after {
                content: '*';
                color: $color-pri;
            }
        }

    }

    &-input {
        border: .1rem solid $color-border-2;
        width: 100%;
        @include flex;
        border-radius: $radius-2;

        &.input-sec {
            .txt {
                width: 6.8rem;
                border-left: .1rem solid $color-border-2;
                @include mid-flex;
                flex-shrink: 0;
                background-color: $color-bg;
            }
        }

        .ip {
            border-radius: $radius-2;
            padding: 1rem 1.4rem;
            font-family: var(--font-pri);
            @include fz-16;
            font-weight: 500;
            width: 100%;
            border: none;
            @include flex;
            outline: none;

            &::placeholder {
                @include fz-16;
                color: $color-text-6;
                font-family: var(--font-pri);
            }
        }
    }
    &-item {

        .wpcf7-form-control-wrap {
            width: 100%;
            display: block;
        }

        @include max(600px) {
            width: 100%;
        }

        &.sec {
            @include max(1230px) {
                width: 100%;
            }
        }

        .inner {
            // padding: 2rem;
        }
     
    }
    &-col{
        @include max(1100px){
            width: 100%;
        }
    }
    &-des{
        @include flex;
        flex-direction: column;
        gap: 3.2rem;
        padding-left: 4rem;
        @include max(1200px){
            padding: 0;
            gap: 1.6rem;
        }
        &-item{
            .title-third{
                margin-bottom: 1rem;
            }
            .title-sub,
            .txt{
                @include fz-16;
                font-family: var(--font-thr);
            }
            .title-second{
                text-transform: unset;
            }
            p{
                text-align: left !important;
            }
        }
    }
    form{
        height: 100%;
        width: 100%;
        position: relative;
        .wpcf7-response-output{
            right: auto !important;
            left: 2rem;
            bottom: 0 !important;
        }
    }
    &-frm{
        height: 100%;
        width: 100%;
        border: .1rem solid $color-border-1;
        background-color: $color-white;
        box-shadow: $shadow-1;
        border-radius: $radius-3;
        padding: 4rem;
        @include max(1000px){
            padding: 3rem 2rem;
        }
        @include max(600px){
            padding: 2rem 1rem;
        }
        .gr-btn{
            width: 100%;
            .btn-sec{
                width: 100%;
                @include mid-flex;
            margin-top: 2rem;

            }
        }
        .lbel{
            margin-bottom: 1rem;
        }
        .ip::placeholder{
            @include max(1200px){
                @include fz-14;
            }
        }
    }
    &-loca{
        &-wr{
        background-color: $color-white;
        padding: 4rem;
        border-radius: 2.8rem;
        @include max(1200px){
            padding: 2rem;
            border-radius: $radius-6;
        }
        @include max(600px){
            padding: 1rem;
        }
        }
        &-head{
            padding-bottom: 2rem;
            border-bottom: .1rem solid $color-border-1;
        }
    }
}