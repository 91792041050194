.thumb {
    position: relative;
    overflow: hidden;
    &-wr{
        position: relative;
        z-index: 1;
        .gr-btn{
            width: fit-content;
        }
    }
    &-cloud {
        // display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        .cloud{
            position: absolute;
            width: 20%;
            animation-duration: 10s;
            animation-name: cloudMove, cloudFade;
            animation-direction: normal;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-fill-mode: both;
            &:nth-child(1){
                top: 5%;
            }
            &:nth-child(2){
                top: 10%;
                animation-duration: 10.5s;
                animation-delay: 2.5s;
                transform: scaleX(0.5) scaleY(0.5);
            }
            &:nth-child(3){
                top: 7%;
                animation-duration: 8s;
                animation-delay: 3.5s;
                transform: scaleX(0.8) scaleY(0.8);
            }
            &:nth-child(4){
               display: none;
            }
            &:nth-child(5){
               display: none;
            }
        }
    }

    &-bg {
        position: absolute;
        z-index: -2;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        &::before{
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 1;
            background: linear-gradient(0deg, rgba(232, 232, 232, 0.20) 0%, rgba(232, 232, 232, 0.20) 100%), linear-gradient(96deg, rgba(211, 211, 211, 0.20) 5.05%, rgba(255, 255, 255, 0.20) 97.32%);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }

    &-des {
        padding: 20rem 0;
        width: 50%;

        @include max(1200px) {
            padding: 15rem 0;
        }

        @include max(900px) {
            padding: 10rem 0;
            width: 70%;
        }

        @include max(700px) {
            padding: 5rem 0;
        }

        @include max(400px) {
            width: 100%;
        }
       .des{
        padding: 1rem 1rem 1rem 1.6rem;
        border-radius: 0 $radius-2 $radius-2  0;
        backdrop-filter: blur(.2rem);
        background-color: $color-opa-white-01;
       }
        .title-second{
            margin-bottom: 1.2rem;
        }
        .title-sub{
            text-align: left;
            margin-bottom: 3.2rem;
            @include max(1200px){
                margin-bottom: 1rem;
            }
        }
    }

}
