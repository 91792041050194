// --------------------------------------

.mobile {
    position: fixed;
    z-index: 140;
    top: var(--size-hd);
    left: 0;
    bottom: 0;
    min-width: 50rem;
    width: 50rem;
    background-color: $color-white;
    // box-shadow: $boxshadow-1;
    @include transition(0.4s, linear);
    transform: translateX(-110%);
  
    @include max($mobile) {
        width: 90%;
        min-width: initial;
    }
  
  //   &.sticky {
  //       top: var(--pd-sc);
  //   }
  
    &.open {
        transform: translateX(0);
    }
  
    @include min(1201px) {
        display: none;
    }
  
    &-con {
        width: 100%;
        height: 100%;
        @include flex;
        flex-direction: column;
        overflow-y: auto;
        padding-top: 2rem;
    }
  
    &-heading {
        font-size: 1.8rem;
        line-height: 1.5;
        color: $color-text;
        font-weight: 700;
        margin-bottom: 2rem;
        text-transform: uppercase;
    }
  
    &-overlay {
        @include full-fixed;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.8);
        @include transition(0.6s, ease);
        opacity: 0;
        visibility: hidden;
        transform: translateX(-110%);
        border-top: 1px solid $color-border-01;
        top: var(--size-hd);
        &.sticky {
        }
  
        &.open {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
  
        @include min($res-mobile-min) {
            display: none;
        }
    }
  
    &-wr {
        padding-bottom: 6rem;
        position: relative;
    }
  
    &-list {
        margin-bottom: 2rem;
    }
  
    &-content {
        padding: 2.4rem 1.6rem;
        .title {
            font-weight: 600;
            font-size: 1.4rem;
            position: relative;
            padding-left: 2rem;
            color: $color-text;
            &::after {
                @include pseudo;
                width: 1.4rem;
                height: 1.4rem;
                left: 0;
          
                position: absolute;
                font-family: "Font Awesome 6 Pro";
            }
        }
        .mobile-fl {
            &.add {
                .title {
                    &::after {
                        content: '\f3c5';
                    }
                }
            }
            &.ph {
                .title {
                    &::after {
                        content: '\f2a0';
                    }
                }
            }
            &.mail {
                .title {
                    &::after {
                        content: '\f0e0';
                    }
                }
            }
        }
    }
  
    &-title {
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 1.6rem;
    }
  
    &-mxh {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4rem;
        @include flex;
        align-items: center;
        border-top: 1px solid $color-text;
        background-color: $color-white;
  
        &-link {
            @include mid-flex;
            flex: 1;
            height: 100%;
            cursor: pointer;
            transition: .3s ease;
            @include hover{
              i,
              svg,
             img{
              transform: translateY(-.4rem);
              transition: .3s ease;
  
             }
            }
            &:not(:first-child) {
                border-left: 1px solid $color-text;
            }
  
            img {
              --w: 3rem;
                width: var(--w);
                height: var(--w);
                transition: .3s ease;
            }
        }
    }
  
    &-text {
        @include fz-14;
        display: block;
        color: $color-text;
        word-break: break-word;
        font-weight: 500;
  
        &:not(:last-child) {
            margin-bottom: 0.8rem;
        }
    }
  
    &-account {
        position: absolute;
        top: 1rem;
        left: 1rem;
    }
  
    &-re {
        @include flex;
        align-items: center;
  
        &-link {
            @include fz-16;
            color: $color-white;
            text-decoration: underline;
  
            &:not(:first-child) {
                margin-left: 16px;
            }
        }
    }
  
    &-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 3.5rem;
        height: 3.5rem;
        border: 1px solid $color-pri;
        @include mid-flex;
        cursor: pointer;
        background-color: white;
  
        &:hover .icon {
            transform: rotate(360deg);
        }
  
        .icon {
            transition: 0.6s ease-in-out;
            font-weight: 500;
            font-size: 1.8rem;
            color: $color-pri;
        }
    }
  
    &-fl {
        .fw-6 {
            margin-bottom: 0.8rem;
            margin-bottom: 0.8rem;
            display: inline;
            width: fit-content;
            font-size: 1.4rem;
        }
  
        .mobile-text {
            display: inline;
            font-size: 1.4rem;
        }
  
        .dot {
            display: none;
        }
    }
  }