
.banner {
    position: relative;

    .bg {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        // &::after {
        //     @include pseudo;
        //     height: 100%;
        //     width: 100%;
        //     top: 0;
        //     left: 0;
        //     background: linear-gradient(90deg, #1075BA 10.38%, rgba(16, 117, 186, 0.00) 54.1%);
        // }
    }

    &-wr {
        // background: linear-gradient(90deg, rgba(0, 0, 0, 0.40) 29.49%, rgba(0, 0, 0, 0.00) 40.19%);
    }

    &-ctn {
        min-height: calc(100vw*(200/1728));
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        z-index: 2;
        padding: 4rem 0;
    }

    .title-pri {
        line-height: 150%;
        text-transform: capitalize;
    }

    .breadcrumb {
        .container {
            padding: 0;
        }

        &-list {}

        &-item {
            color: white;

            &:last-child {
                color: white;
            }
        }
    }
}