.header-nav {
    .menu {
        &-nav {
            height: 100%;

            >.menu-list {
                height: 100%;
                @include flex;
                gap: 2.4rem;
                >.menu-item {
                    height: 100%;
                    @include flex;
                    align-items: center;
             
                    &:has(.current-menu-item) {

                        >.menu-link {
                            // &::after {
                            //     transform: translateX(-50%);
                            //     width: 100%;
                            // }
                        }
                    }

                    &.dropdown {
                        .menu-item {
                            &.current-menu-item {
                                .menu-link {
                                    color: $color-second;
                                }
                            }
                        }

                        &:hover {
                            // &::before {
                            //     width: 0;
                            // }

                            // &::after {
                            //     transform: translateX(-50%);
                            //     width: 100%;
                            // }
                        }
                    }
                    >.menu-link {
                        width: 100%;
                        @include flex;
                        align-items: center;
                        color: $color-black;
                        font-weight: 600;
                        white-space: nowrap;
                        position: relative;

                        // &::after {
                        //     @include pseudo;
                        //     height: 0.2rem;
                        //     width: 0;
                        //     border-radius: 1rem;
                        //     @include transitionRe;
                        //     left: 50%;
                        //     bottom: -1rem;
                        //     transform: translateX(-50%);
                        //     background: $color-second;
                        // }

                        &:hover {
                            color: $color-second !important;
                            i {
                                color: $color-second;
                                transform: rotate(180deg);
                            }

                            &::after {
                                transform: translateX(-50%);
                                width: 1.5rem;
                            }
                        }
                    }
                    &:hover,
                    &.current-menu-parent,
                    &.current-menu-item {
                        >.menu-link {
                            color: $color-second;

                            // &::after {
                            //     transform: translateX(-50%);
                            //     width: 1.5rem;
                            // }

                            // &::before {
                            //     width: 100%;
                            // }

                            i {
                                color: $color-second;
                            }
                        }
                    }

                    >.menu-list {
                        top: 100%;
                    }
                }
            }
        }

        &-list {
            @include flex;
            align-items: center;
        }

        &-item {
            position: relative;
            @include transition(0.2s, ease-in-out);
            &:hover {
                >.menu-list {
                    opacity: 1;
                    visibility: visible;
                    transform: translate(0, 0);

                    >.menu-item {

                        &.current-menu-item {
                            >a {
                                color: $color-white;
                            }
                        }
                    }
                }

                .menu-link,
                a {
                    @include transition(0.2s, ease-in-out);

                }
            }
            .menu-link,
            a {
                @include transition(0.2s, ease-in-out);
                i {
                    @include transition(0.2s, ease-in-out);
                }
            }
            >.menu-link,
            >a {
                display: block;
                @include fz-16;
                font-weight: 500;

                >i {
                    display: none;
                    font-weight: 500;
                    margin-left: 0.7rem;
                    font-size: 1.2rem;
                    color: $color-text;
                }
            }

            &.dropdown {
                >a {
                    >i {
                        display: inline-block;
                    }
                }
            }

            // sub
            >.menu-list {
                position: absolute;
                top: 0;
                left: 0;
                min-width: 25rem;
                max-width: 28rem;
                @include flex;
                flex-direction: column;
                align-items: flex-start;
                background: $color-white;
                box-shadow: $shadow;
                @include transitionRe;
                opacity: 0;
                visibility: hidden;
                transform: translate(0, 10px);

                >.menu-item {
                    margin-left: 0;
                    width: 100%;
                    &.current-menu-item,
                    &:hover {

                        >.menu-link,
                        >a {
                            color: $color-white;
                            background-color: $color-second;
                            >i {
                                color: $color-white;
                            }
                        }
                    }

                    &.current_page_item,
                    &.current-menu-item {
                        // &::after {
                        //     width: 100%;
                        //     height: 100%;
                        // }
                    }

                    >.menu-link,
                    >a {
                        padding: 1rem 1.4rem;
                        @include flex;
                        align-items: center;
                        justify-content: space-between;
                        position: relative;
                        z-index: 2;

                        >i {
                            transform: rotate(-90deg) translate(5px, 0);
                            margin-top: 0.8rem;
                            font-size: 1rem;
                            color: $color-white;

                        }
                    }

                    >.menu-list {
                        left: 100%;
                    }
                }
            }
        }
    }
}

.mobile-nav {
    .menu {
        &-nav {
            >.menu-list {
                >.menu-item {

                    &:hover,
                    &.current_page_item,
                    &.current-menu-item {
                        >a {
                            color: $color-second;
                            font-weight: 700;
                        }
                    }

                    &.active {
                        >a {
                            color: $color-second;
                            font-weight: 700;
                        }
                    }

                    &:has(.current-menu-item) {
                        >a {
                            color: $color-text;

                        }
                    }

                    >a {
                        @include flex;
                        justify-content: space-between;
                        align-items: center;
                        color: $color-text;
                        @include fz-16;
                        font-weight: 700;
                        border-bottom: 1px solid $color-text;

                        @include max(400px) {
                            @include fz-14;
                        }

                        // &::before {
                        //     display: none;
                        // }
                    }
                }
            }
        }

        &-item {
            &:hover {
                >a {
                    color: $color-second;
                }
            }

            &.dropdown {


                >a {
                    >i {
                        display: flex;
                    }
                }
            }

            &.active {
                >a {
                    >i {
                        transform: rotate(180deg);
                    }
                }
            }

            a {
                position: relative;

                i {
                    color: $color-text;

                    width: 2rem;
                    height: 2rem;
                    @include mid-flex;
                    display: none;
                    @include transitionRe;
                }
            }

            >a {
                @include flex;
                align-items: center;
                justify-content: space-between;
                padding: 1.2rem;
                @include fz-16;
                color: $color-text;

                font-weight: 500;

                @include max(400px) {
                    @include fz-14;
                }
            }

            >.menu-list {
                display: none;
                margin-left: 1.6rem;
                position: relative;

                // &::before {
                //     content: '';
                //     position: absolute;
                //     top: 2rem;
                //     bottom: 2rem;
                //     left: 0;
                //     width: 1px;
                //     background-color: $color-border-01;
                // }

                >.menu-item {

                    &.current_page_item,
                    &.current-menu-item {
                        >a {
                            color: $color-second;
                        }
                    }

                    &:has(.current-menu-item) {
                        >a {
                            color: $color-second;
                        }
                    }
                }
            }
        }
    }
}