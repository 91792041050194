.title-pri{
    @include fz-40;
    text-transform: capitalize;
    font-weight: 600;
}
.title-second{
    @include fz-36;
    text-transform: capitalize;
    font-weight: bold;
    color: $color-text;
    line-height: 130%;
    font-family: var(--font-pri);
    text-wrap: pretty;
    @include max(450px){
        font-size: 2rem;
    }
    .txtpri{
        color: $color-pri;
    }
    .txtsec{
        color: $color-second;
    }
    &.font-sec{
        @include fz-20;
    }
    &.font-pri{
        @include fz-24;
    }
    &.font-thr{
        @include fz-48;
        @include max(450px){
            font-size: 2.4rem
        }
    }
    &.font-fur{
        @include fz-40;
    }
    &.font-fiv{
        @include fz-52;
    }
    &.firwhite{
        .text{
            color: $color-white;
        }
    }
}
.title-sub{
    @include fz-16;
    font-weight: 400;
    font-family: var(--font-pri);
    color: $color-text;
}
.title-third{
    @include fz-20;
    font-family: var(--font-pri);
    font-weight: 600;
    color: $color-black;
}
.t-white{
    color: $color-white;
}
.t-colpri{
    color: $color-pri;
}
.t-colsec{
    color: $color-second;
}
.t-upper{
    text-transform: uppercase;
}
.t-capi{
    text-transform: capitalize;
}
.t-bold{
    font-weight: bold;
}
.t-center{
    text-align: center;
}
.h1-hidden{
    height: 0;
    width: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 0;
}