.itemIp {
    @include flex;
    flex-direction: column;
    gap: .6rem;
    width: 100%;

    &-lb {
        @include fz-16;
        font-weight: bold;
        font-family: var(--font-pri);
        color: $color-text;
    }

    &-input {
        padding: 1.2rem 1.6rem;
        @include fz-16;
        font-weight: 600;
        font-family: var(--font-pri);
        color: $color-text;
        width: 100%;
        border-radius: $radius-3;
        border: .1rem solid $color-border-2;
        background-color: $color-white;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        &::placeholder{
            @include fz-16;
            font-weight: 600;
            font-family: var(--font-pri);
            color: $color-text-6;
        }

    }
}